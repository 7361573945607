class ParseContour {
  constructor(surfaceData) {
    Object.assign(this, surfaceData);
  }

  getData() {
    const canvas = document.createElement('canvas');
    canvas.width = this.width;
    canvas.height = this.height;
    const ctx = canvas.getContext('2d');
    const xishufac = this.xishufac;
    const origionx = this.origionx;
    const origiony = this.origiony;
    const level = this.level;
    const xoffset = 0;
    const yoffset = 0;
    ctx.drawImage(this.image, origionx, origiony, this.width, this.height, xoffset, yoffset, this.width, this.height);
    const imageData = ctx.getImageData(0, 0, this.width, this.height);
    const data = imageData.data;
    const realdataArray = [];
    for (let i = 0; i < data.length; i += 4) {
      const realdata = this.reverse(data[i + level]);
      realdataArray.push(realdata);
    }

    const width = this.width;
    const height = this.height;
    // 已有点初始二维数组
    const matrixData = new Array(width * height);
    // 插值矩阵数据,时间复杂度O(height * width * len)
    for (let k = 0, k1 = 0; k < height; k++) {
      for (let j = 0; j < width; j++, k1++) {
        if (k >= yoffset && k < yoffset + parseInt(height / xishufac - 1) * xishufac && j >= xoffset && j < xoffset + parseInt(width / xishufac - 1) * xishufac) {
          if (k % xishufac === 0 && j % xishufac === 0) {
            matrixData[k1] = realdataArray[k1];
          } else {
            const s = k % xishufac;
            const d = j % xishufac;
            const temx = realdataArray[(k - s) * width + j - d];
            let temy = realdataArray[(k - s) * width + j - d + xishufac];
            let temz = realdataArray[(k - s) * width + width * xishufac + j - d];
            let temk = realdataArray[(k - s) * width + width * xishufac + j - d + xishufac];
            if ((k - s) * width + j - d + xishufac > width * height || (k - s) * width + width * xishufac + j - d > width * height || (k - s) * width + width * xishufac + j - d + xishufac > width * height) {
              temy = temx;
              temz = temx;
              temk = temx;
            }
            matrixData[k1] = this.bilinearInterpolateVector(d / xishufac, s / xishufac, temx, temy, temz, temk);
          }
        } else {
          matrixData[k1] = realdataArray[k1];
        }
      }
    }
    return matrixData;
  }

  bilinearInterpolateVector(x, y, g00, g10, g01, g11) {
    const rx = 1 - x;
    const ry = 1 - y;
    const a = rx * ry;
    const b = x * ry;
    const c = rx * y;
    const d = x * y;
    const u = g00 * a + g10 * b + g01 * c + g11 * d;
    return u;
  }

  reverse(value) {
    return (this.min + value * (this.max - this.min) / 255);
  }
}

export default ParseContour;
