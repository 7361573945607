function isMobileFn() {
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!mobile;
}

isMobileFn();

export default {
  namespaced: true,
  state: () => ({
    screenSize: {
      width: 0,
      height: 0,
    },
    // 云图的所有数据
    satelliteData: [],
    // 当前播放状态
    status: 'pause',
    // 当前起报时间
    currentBaseTime: '',
    // 当前时间表对应的索引
    index: 0,
    // 加载图片的loading
    loading: false,
    // 请求时间的索引
    requestIndex: 0,
  }),
  getters: {
    // 根据选择的起报时间生成对应的hashmap
    satelliteDataMap(state) {
      return state.satelliteData.reduce((target, item) => {
        target[item.baseTime] = item;
        return target;
      }, {});
    },
    // 起报时间列表
    baseTime(state) {
      return state.satelliteData.map((item) => item.baseTime);
    },
    // 当前图层对应的时间列表
    timeList(state, getters) {
      return getters.satelliteDataMap[state.currentBaseTime]?.forecast ?? {};
    },
    // 当前时间对应详情
    current(state, getters) {
      return getters.timeList[state.index] ?? {};
    },

    currentRequest(state, getters) {
      return getters.timeList[state.requestIndex] ?? {};
    },

    // 当前起报时间
    currentSatelliteBaseTime(state) {
      return state.currentBaseTime;
    },

    // 当前选择时间
    currentTime(state, getters) {
      return getters.current.forecastTime;
    },

    // 当前时间
    currentSatelliteTime(state, getters) {
      return getters.current.forecastTime;
    },
  },
  mutations: {
    // 设置云图的所有数据
    setSatelliteData(state, value) {
      state.satelliteData = value.filter((item) => item.forecast.filter((forecastItem) => forecastItem.imagePathForecast));
    },
    // 设置当前播放状态
    setStatus(state, value) {
      state.status = value;
    },
    // 设置当前起报时间
    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;
      sessionStorage.setItem('baseTime', value);
    },
    // 设置当前起报时间对应的预报时间的索引
    setIndex(state, value) {
      state.index = value;
      sessionStorage.setItem('nowTime', this.getters['satellite/current'].forecastTime);
    },
    setLoading(state, value) {
      state.loading = value;
    },

    setRequestIndex(state, value) {
      state.requestIndex = value;
    },

    setScreenSize: (state, screenSize) => {
      state.screenSize = {
        width: screenSize.width,
        height: screenSize.height,
      };
    },
  },
};
