<template>
  <van-action-sheet v-model:show="popupShow" title="菜单" class="menu-popup">
  <div class="menu-wrap">
    <div class="mod3 flex-col">
      <van-grid :border="false" :column-num="2" :gutter="5">
        <van-grid-item @click="handleWebsite">
          <div class="flex-col justify-between align-center">
            <svg-icon class="icon" icon-class="home" />
            <span class="text_">官网</span>
          </div>
        </van-grid-item>
        <van-grid-item @click="feedShow = true">
          <div class="flex-col justify-between align-center">
            <svg-icon icon-class="consult" class="icon"/>
            <span class="text_">反馈</span>
          </div>
        </van-grid-item>
      </van-grid>
    </div>

    <MenuFactorPopup ref="factorPopup" :factor="factor" :model-type="modelType" :metrics="metrics"
                     :is-satel="isSatel" :is-typ="isTyp" :t2-mode="t2Mode"
                     @change="factorChange" @changeFactorTip="changeFactorTip"
                     @changeModeName="changeModeName" @changeModeItem="changeModeItem"
                     @initProductData="initProductData" @closePop="closePop"></MenuFactorPopup>

    <MenuProductPopup v-if="product_type.length > 0" ref="productPopup" factor="" :model-type="modelType" :product-type="product_type"
                      @changeProductClick="changeProductClick"/>

    <MenuLayerSelect factor="" :model-type="modelType" :is-satel="isSatel" :is-typ="isTyp"/>

    <MenuMapPopup model-type="" v-if="!isSatel" @closePop="closePop"/>

    <!-- 反馈 -->
    <FeedBackPopup :show.sync="feedShow"/>

  </div>
  </van-action-sheet>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from "@/config";
import { modeFactorMap, factorMap, mapForCn,appFactorMap } from "@/config/MapConfigT4";
import MenuLayerSelect from './MenuLayerSelect.vue'
import MenuFactorPopup from './MenuFactorPopup.vue'
import MenuProductPopup from './MenuProductPopup.vue'
import MenuMapPopup from './MenuMapPopup.vue'
import FeedBackPopup from './FeedBackPopup.vue'

export default {
  components: {
    MenuLayerSelect,
    MenuFactorPopup,
    MenuProductPopup,
    MenuMapPopup,
    FeedBackPopup
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    modelType: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false
    },
    factor: {
      type: String,
      required: true,
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "t2",
    },
  },
  data() {
    return {
      input: '',
      currentData: [],
      feedShow: false,
      activefactorIndex : 0,
      activeMapIndex : 0,
      activeProductIndex : 0,
      appFactorMap: appFactorMap,
      product_type: [],
      map_type: [
        {
          text: "简洁",
          name: "default",
          icon: "default",
        },
        {
          text: "地形",
          name: "graph",
          icon: "terrain",
        },
        {
          text: "影像",
          name: "shadow",
          icon: "shadow",
        },
      ],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState("map", ["layerType", "t2Mode"]),
    popupShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    getLayerType() {
      return this.layerType;
    },
  },
  created() {
    console.log('111111')
  },
  mounted() {

  },
  methods: {
    ...mapMutations("map", [
      "setMode",
      "setFactor",
      "setLayerType",
      "setWindy",
      "setCity",
      "setHD",
      "setWindData",
      "setIsobar",
    ]),
    ...mapMutations([
      "setHistory",
      "setStatus",
      "setCurrentData",
      "setT1Windy",
      "setT1LayerType",
      "setT1City",
    ]),
    factorChange(metric) {
      this.$emit("change", metric);
    },
    closePop() {
      this.popupShow = false;
    },
    changeFactorTip(name) {
      this.$emit("changeFactorTip", name);
    },
    changeModeName(info) {
      this.$emit("changeModeName", info);
    },
    changeModeItem(name) {
      this.$emit("changeModeItem", name);
    },
    initProductData(data) {
      this.product_type = data;
    },
    // changeMapClick(name) {
    //   this.setT1LayerType(name);
    //   this.setLayerType(name);
    //   this.$sensors.track("CurrentUnderlay", {
    //     under_layer_name: name,
    //     space_type: this.$route.name === "earth" ? "三维" : "二维",
    //     mode:
    //       this.$route.name === "weather_hd" || this.$route.name === "earth"
    //         ? this.t2Mode
    //         : this.t1Mode,
    //     page_type:
    //       this.$route.name === "weather_hd"
    //         ? "天机2"
    //         : this.$route.name === "earth"
    //           ? "三维"
    //           : "天机1",
    //   });
    // },
    changeProductClick(item) {
      this.$refs.factorPopup.changeFactorClick(item);
    },
    handleWebsite() {
      window.open('https://www.tjweather.com/info/')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.menu-popup {
  background: $bg-popup;
  color: #FFFFFF;
  max-height: 90%;
  .menu-wrap {
    height: calc(90vh - 100px);
    overflow-y: auto;
    padding-bottom: 20px;
  }
}

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.mod3 {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
}


.factor-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .checked_class {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 18px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}

.layer-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}

::v-deep {
  .van-grid-item__content {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 4px;
  }
  .van-badge--fixed {
    top: 5px;
    right: 5px;
  }
  .van-badge {
    border: 0;
  }
}
::-webkit-scrollbar {
  display: none;/*隐藏滚轮*/
}
</style>
