<template>
  <div
    class="tile"
  >
    {{ date }}
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'MapTitle',
  computed: {
    ...mapState('typhoon', ['timeLineClickTime']),
    date: {
      get() {
        if (this.timeLineClickTime) {
          const click_time = moment(this.timeLineClickTime, 'YYYYMMDDHH');
          const duration = moment.duration(click_time.diff(moment.now()));
          const { _data } = duration;
          const { days, hours, minutes } = _data;
          let text = '';
          let desc = `${click_time.format('YYYY年MM月DD日 HH时')}，距现在`;
          if (days < 0 || hours < 0 || minutes < 0) {
            text = '以前';
          } else if(days ===0 && hours === 0 && minutes === 0) {
            return "${click_time.format('YYYY年MM月DD日 HH时')}，现在"
          }else {
            text = '以后';
          }
          if (days !== 0) {
            desc += `${Math.abs(days)}天`;
          }
          if (hours !== 0) {
            desc += `${Math.abs(hours)}时`;
          }
          if (minutes !== 0) {
            desc += `${Math.abs(minutes)}分`;
          }
          return desc + text;
        }
        return '';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .tile {
    position: absolute;
    z-index: 100000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
    left: 50%;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(1px);
    border-radius: 0 0 6px 6px;
    font-size: 12px;
    color: #333;
  }
</style>
