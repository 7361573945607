<template>
  <div
    class="icon_wrap"
    :title="desc"
    :class="{
      spec: spec,
      mobile_spec: isMobile,
      c_icon: cIcon,
      center_icon: center,
    }"
  >
    <svg-icon
      class="icon"
      :icon-class="icon"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: '',
    },
    spec: {
      type: Boolean,
      default: false,
    },
    cIcon: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isMobile']),
  },
};
</script>

<style lang="scss" scoped>
.icon_wrap {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  text-align: center;
  cursor: pointer;

  .icon {
    color: $theme-color;
  }

  &.spec {
    background-color: $theme-color;

    .icon {
      color: #fff;
    }
  }

  &.c_icon {
    .icon {
      width: 12px;
      height: 12px;
    }
  }

  &.center_icon {
    .icon {
      padding-left: 2px;
    }
  }

  &.mobile_spec {
    width: 28px;
    height: 28px;
  }
}
</style>
