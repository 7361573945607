<template>
<!--    <van-action-sheet-->
<!--      v-model="popupShow"-->
<!--      :actions="actions"-->
<!--      description="时间间隔"-->
<!--      close-on-click-action-->
<!--      :closeable="true"-->
<!--      @select="onSelect"-->
<!--      class="mode-popup"-->
<!--    />-->
  <van-action-sheet v-model:show="popupShow" title="时间间隔" class="mode-popup">
    <div class="factor-div">
      <van-grid :border="false" :column-num="4" class="grid-div" :gutter="10">
        <van-grid-item v-for="item in actions" :key="item.type" class="grid-item-div"
                       :class="gapItem === item.type ? 'active' : ''" @click="onSelect(item)">
          <div class="flex-col justify-between align-center">
            <div class="flex-col">{{ item.name }}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </van-action-sheet>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Bus from "@/bus/index";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actions: [
        { name: "1小时", type: 1 },
        { name: "3小时", type: 3 },
        { name: "6小时", type: 6 },
        { name: "24小时", type: 24 },
      ],
      gapItem: ''
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['timeGap', 'factorName', 'popupVal', 'popupUnit', 'timeGap']),
    popupShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    isT1() {
      return sessionStorage.getItem('tmode') === 't1'
    }
  },
  watch: {
    popupShow(val) {
      if(val) {
        this.actions.map(item => {
          if (item.name === this.timeGap) {
            this.gapItem = item.type;
          }
        })
      }
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations('map',["setTimeGap"]),
    onSelect(val) {
      this.gapItem = val.type;
      this.setTimeGap(val.name);
      Bus.$emit('buildTimeLineData');
      Bus.$emit("closeDetailShow");
      this.popupShow = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";
.mode-popup {
  background: $bg-popup;
  color: #FFFFFF;
  height: 18%;
  z-index: 99999;
  .mode-top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .title {
      flex: 1;
      text-align: center;
      font-size: 16px;
    }
  }
}

.factor-div {
  border-radius: 8px;
  margin: 10px;
  background-color: rgba(66, 66, 66, 0.75);

  .grid-div {
    padding: 15px 5px;
  }

  .grid-item-div {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
      }
    }
  }

  .grid-item-click {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
        border: 3px solid rgba(87, 127, 255, 1);
      }
    }
  }

  .grid-item-disable {
    ::v-deep {
      .van-grid-item__content {
        background-color: #808080;
        border-color: #c8c9cc;
        cursor: not-allowed;
      }
    }
  }

  .text {
    font-size: 11px;
    padding-top: 10px;
  }

  .active {
    ::v-deep {
      .van-grid-item__content {
        background-color: rgba(87, 127, 255, 1);
      }
    }
  }
}

::v-deep {
  .van-grid-item__content {
    background-color: $bg-popup;
    padding: 10px 4px;
  }
}
</style>
