<template>
  <div
    class="popover-wrap"
    v-click-outside="outside"
    :class="{ mobile: isMobile, satelite: satelite }"
  >
    <el-popover
      v-model="isShow"
      placement="top-start"
      width="150"
      trigger="click"
      title="声明"
    >
      <div class="content">
        <span>本网站数据为计算结果，仅供参考。</span>
        <i
          class="icon el-icon-close"
          @click="outside()"
        />
      </div>
      <div
        slot="reference"
      >
        <i
          class="el-icon-info"
          style="color: #fff;font-size: 16px;"
        />
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import vClickOutside from '@/plugins/v-click-outside/v-click-outside';


export default {
  directives: {
    clickOutside: vClickOutside,
  },
  props: {
    keywords: {
      type: String,
      required: true,
    },
    satelite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    ...mapState(["isShowInfo"]),
    isMobile() {
      return this.$store.state.isMobile;
    },
    isShow: {
      get() {
        return this.isShowInfo;
      },
      set(value) {

      }
    },
    read() {
      return localStorage.getItem(this.keywords) === 'true';
    },
  },
  mounted() {
    
  },
  methods: {
    ...mapMutations(["setShowInfo"]),
    openTj() {
      window.open('https://www.tjweather.com/', '_blank');
    },
    outside() {
      console.log('点击外部');
      this.setShowInfo(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-wrap {
  position: fixed;
  z-index: 1999;
  left: 30px;
  bottom: 70px;
  cursor: pointer;

  &.mobile {
    bottom: 120px;
    //left: 10px;

    &.satelite {
      bottom: 88px;
    }

    .info-wrap {
      background: rgba(50, 50, 50, 0.5);
      border-radius: 6px;

      &.more, &.info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 45px;
        height: 45px;
        border-radius: 6px;
      }
    }
  }

  .info-wrap {
    width: 45px;
    height: 50px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 10px 0 2px;

    &.info {
      border-radius: 6px 6px 0 0;
    }

    &.more {
      border-radius: 0 0 6px 6px;
    }

    span {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.content {
  font-size: 10px;
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
}
</style>
