const radiusUnit = ['NE', 'NW', 'SE', 'SW'];
const getRadiusText = (d, index) => (d ? parseInt(d) === -1 ? '--' : parseInt(d) : '--') + radiusUnit[index];
export const Reflect = {
  Head: { name: '', content: '_TableReflect_Head_', render: (data) => data.name },
  Table: [
    { name: '过去时间', content: '_TableReflect_PastTime_', render: (data) => data.time },
    { name: '中心位置', content: '_TableReflect_CenterPosition_', render: (data) => `${data.lng}°E，${data.lat}°N` },
    { name: '最大风力', content: '_TableReflect_StrongDesc_', render: (data) => `${data.StrongDesc}` },
    { name: '最大风速', content: '_TableReflect_MaxSpeed_', render: (data) => `${data.vmax ?? 0} 米/秒` },
    { name: '中心气压', content: '_TableReflect_Pressure_', render: (data) => `${data.pressure ?? 0} 百帕` },
    { name: '移动速度', content: '_TableReflect_Speed_', render: (data) => `${data.speed}` },
    // { name: '移动方向', content: '_TableReflect_MoveDirection_', render: (data) => data.movedirection },
    { name: '八级风圈半径(公里)', content: '_TableReflect_SevenLevel_', render: (data) => data.radius7.map((d, index) => getRadiusText(d, index)) },
    { name: '十级风圈半径(公里)', content: '_TableReflect_TenLevel_', render: (data) => data.radius10.map((d, index) => getRadiusText(d, index)) },
    { name: '十二级风圈半径(公里)', content: '_TableReflect_TwelveLevel_', render: (data) => data.radius12.map((d, index) => getRadiusText(d, index)) },
  ],
};

export const replaceData = (html, data) => {
  const { Head, Table } = Reflect;

  html = html.replace(Head.content, Head.render(data));

  Table.forEach(({ content, render }) => {
    html = html.replace(content, render(data));
  });

  return html;
};
