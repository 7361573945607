<template>
  <div
    class="player_satellite"
    :class="{
      vertical_player: isVertical,
    }"
  >
    <div class="drag_wrap">
      <v-base-time-select ref="timeSelect" />

      <div class="player_wrap">
        <div
          v-if="!isVertical"
          class="picker_wrap"
        >
          <div class="label">
            日期
          </div>
          <el-date-picker
            v-show="!isVertical"
            v-model="date"
            type="date"
            placeholder="选择日期"
            :picker-options="{ disabledDate }"
            :clearable="false"
          />
          <div class="now" @click="toNow">现在</div>
        </div>

        <template
          v-else
        >
          <v-icon-word
            v-if="status === 'pause'"
            icon="play"
            desc="播放"
            spec
            @click.native="play"
          />
          <v-icon-word
            v-else
            icon="pause"
            desc="暂停"
            spec
            @click.native="pause"
          />
        </template>

        <v-icon-word
          icon="p-first"
          desc="第一张"
          spec
          c-icon
          @click.native="goBorder(0);"
        />

        <v-icon-word
          icon="p-prev"
          desc="上一张"
          spec
          c-icon
          @click.native="prev"
        />

        <template v-if="!isVertical">
          <v-icon-word
            v-if="status === 'pause'"
            icon="play"
            desc="播放"
            spec
            center
            @click.native="play"
          />
          <v-icon-word
            v-else
            icon="pause"
            desc="暂停"
            spec
            @click.native="pause"
          />
        </template>
        <div
          v-else
          class="vertical_time_wrap"
        >
          <el-select
            v-show="isVertical"
            v-model="time"
          >
            <el-option
              v-for="item in timeList"
              :key="item.forecastTime"
              :label="item.forecastTime | formatCurrentTime"
              :value="item.forecastTime"
            />
          </el-select>
        </div>

        <v-icon-word
          icon="p-next"
          desc="下一张"
          spec
          c-icon
          @click.native="next(false, true);"
        />

        <v-icon-word
          icon="p-last"
          desc="最后一张"
          spec
          c-icon
          @click.native="goBorder(timeList.length - 1)"
        />
      </div>
    </div>
    <v-timeline
      v-show="!isVertical"
      color
      :index="hourIndex"
      model-type="satellite"
      @change="setTime"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Bus from '@/bus/index';
import VIconWord from '@/components/Map/IconWord.vue';
import VTimeline from '@/components/Map/Timeline.vue';
import VBaseTimeSelect from './BaseTImeSelectNew.vue';

export default {
  components: {
    VIconWord,
    VTimeline,
    VBaseTimeSelect,
  },

  filters: {
    formatCurrentTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },

  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: 1,
      hourIndex: 1,
    };
  },

  computed: {
    ...mapState('satellite', ['status', 'index', 'requetIndex', 'loading']),
    ...mapGetters('satellite', ['currentTime', 'timeList']),
    time: {
      get() {
        return this.currentTime;
      },
      set(value) {
        this.pause();
        if (value in this.timeMap) {
          this.setLoading(true);
          this.goToFrame(this.timeMap[value]);
        } else {
          this.$message('您选择的时间超出了预测范围');
        }
      },
    },
    // 时间选择器绑定的日期
    date: {
      get() {
        return moment(this.currentTime).format('YYYY-MM-DD');
      },
      set(value) {
        // const time = moment(value);
        // const str = time.add((this.position >> 1) - 8, 'h').format('YYYY-MM-DDTHH:mm:ss.000+00:00');

        // if (str in this.timeMap) {
        //   this.pause();
        //   this.goToFrame(this.timeMap[str]);
        // } else {
        //   this.$message('您选择的时间超出了预测范围');
        // }
        const time = moment(value);
        const str = time.format("YYYYMMDD");
        console.log(this.dayMap)
        const index = this.dayMap[str][0];
        this.pause();
        this.goToFrame(index);
      },
    },
    // 根据返回的时间生成map，为禁用时间提供hash表，降低查询的时间复杂度
    timeCollection() {
      return this.timeList.reduce((target, item) => {
        const str = moment(item.forecastTime).format('YYYYMMDD');
        target.add(str);
        return target;
      }, new Set());
    },
    dayMap() {
      return this.timeList.reduce((target, item, index) => {
        const str = moment(item.forecastTime).format("YYYYMMDD");
        if (target[str]) {
          target[str].push(index);
        } else {
          target[str] = [index];
        }
        return target;
      }, {});
    },
    // 根据forecastTime生成hash表，方便计算索引
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
  },
  watch: {
    currentTime: {
      handler(value) {
        const hour = moment(value).hour();
        this.position = hour * 2 + 1;
        this.hourIndex = hour;
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('next', this.next);
    Bus.$on('pause', this.pause);
  },

  beforeDestroy() {
    Bus.$off('next', this.next);
    Bus.$off('pause', this.pause);
  },

  methods: {
    ...mapMutations('satellite', ['setStatus', 'setIndex', 'setLoading', 'setRequestIndex']),
    toNow() {
      // this.toNowIndex();
      const now = this.getNowHasBaseTime();
      const nowTimestamp = Date.parse(now);
      const flagIndex = this.timeList.findIndex(
        (item) => Date.parse(item.forecastTime) === Date.parse(now)
      );
      if (flagIndex === -1) {
        let nearestIndex = -1;
        if (this.timeType.type !== 24) {
          let minDiff = Infinity;

          this.timeList.forEach((item, index) => {
            const itemTimestamp = Date.parse(item.forecastTime);
            const diff = Math.abs(nowTimestamp - itemTimestamp);
            if (diff < minDiff) {
              minDiff = diff;
              nearestIndex = index;
            }
          });
        } else {
          const sameDateMidnightIndex = this.timeList.findIndex((timeObj) => {
            const time = new Date(timeObj.forecastTime);
            const nowTime = new Date(nowTimestamp);
            return (
              time.getDate() === nowTime.getDate()
            );
          });

          if (sameDateMidnightIndex !== -1) {
            nearestIndex = sameDateMidnightIndex; // Set closest index to the found midnight time index
          } else {
            nearestIndex = 0; // If no midnight time found, set index to 0
          }
        }

        this.setIndex(nearestIndex);
        return;
      }
      console.log(flagIndex)
      this.setIndex(flagIndex);
      this.$nextTick(() => {
        Bus.$emit('renderSatellite');
      });
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split("T")[0].split("-");
      const time = base.split("T")[1].split(":");
      const now = new Date();

      date[1] = `0${now.getUTCMonth() + 1}`.slice(-2);
      date[2] = `0${now.getUTCDate()}`.slice(-2);

      time[0] = `0${Number(now.getUTCHours()) + 1}`.slice(-2);

      return `${date.join("-")}T${time.join(":")}`;
    },
    /**
     * @description 播放
     * @returns {void}
     * @author yujie
     */
    play() {
      this.setStatus('play');
      this.next(true);
    },
    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      this.setStatus('pause');
    },
    /**
     * @description 播放上一张
     * @returns {void}
     * @author yujie
     */
    prev() {
      this.pause();
      this.setLoading(true);
      const index = (this.index - 1 + this.timeList.length) % this.timeList.length;
      this.goToFrame(index);
    },
    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    next(type, click) {
      if (click) {
        this.pause();
        this.setLoading(true);
      }
      const index = (this.index + 1) % this.timeList.length;
      // if (this.loading) {
      //   this.setIndex(loadIndex);
      // }
      this.goToFrame(index, type);
    },
    goBorder(index) {
      this.pause();
      this.setLoading(true);
      this.goToFrame(index);
    },
    /**
     * @description 跳转到指定帧
     * @returns {void}
     * @author yujie
     */
    goToFrame(index, type) {
      if (type) {
        this.setRequestIndex(index);
      } else {
        this.setIndex(index);
      }
      this.$nextTick(() => {
        Bus.$emit('renderSatellite');
      });
    },
    /**
     * @description 禁用时间方法，timepicke提供
     * @param {Date} date 时间参数
     * @returns {Boolean} 当前日期是否禁用
     * @author yujie
     */
    disabledDate(value) {
      const str = moment(value).format('YYYYMMDD');
      return !this.timeCollection.has(str);
    },

    setTime(position) {
      this.pause();
      const time = moment(this.currentTime);
      const str = time.add(position - 1 - time.hour(), 'h').utc().format('YYYY-MM-DDTHH:mm:ss.000+00:00');

      if (str in this.timeMap) {
        this.setLoading(true);
        this.goToFrame(this.timeMap[str]);
      } 
      // else {
      //   this.$message('您选择的时间超出了预测范围');
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.player_satellite {
  position: absolute;
  bottom: 0;
  height: 45px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background: rgba(66,66,66,0.55);
  backdrop-filter: blur(1px);
  left: 0;
  display: flex;
  z-index: 1000;
  align-items: center;

  .drag_wrap {
    display: flex;
    position: relative;
  }

  .player_wrap {
    margin-left: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 21px;
    padding: 0 5px;
    .now {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50px;
      font-size: 12px;
      color: #fff;
      margin-left: 10px;
      height: 24px;
      width: 50px;
      background: #027aff;
    }

    .picker_wrap {
      display: flex;
      align-items: center;

      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }

      ::v-deep {
        .el-input__inner,
        .el-input__icon {
          color: #ffffff;
          border-radius: 4px;
          border-color: #ffffff;
        }

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 120px;
        }
      }
    }
  }
}

.player_satellite.vertical_player {
  height: 84px;
  bottom: 0;
  background: #fff;

  .drag_wrap {
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .basetime_select {
      padding-left: 25px;
      //margin-bottom: 4px;

      .label {
        margin-right: 15px;
      }
    }

    .player_wrap {
      margin: 0;

      .icon_wrap {
        margin-left: 6px;
      }
    }
  }

  .vertical_time_wrap {
    width: 164px;
  }
}
</style>
