<template>
  <div style="height: 30px; width: 100%; margin-left: 36px; margin-top: 22px">
    <div class="timeline">
      <div
        class="target"
        :style="{
          left: `${(indexFlag) * (100 / (timeList.length - 1)) + 0.2}%`,
        }"
      >
        <div class="box" />

        <div v-if="date" class="datetime" :class="{ last_tip: isLast || indexFlag === (timeList.length - 1) }">
          {{ date }}
        </div>
      </div>
      <div class="time_wrap">
        <div
          v-for="(item, i) in timeList"
          :key="i"
          class="time_item"
          :class="{
            active: i === indexFlag,
            animation: i < indexFlag,
          }"
        >
          <div class="clickDiv" @click="timeClick(i)" />
          <div
            class="circle"
            :class="{
              animation: i < indexFlag,
            }"
          />
          <div class="word satellite" v-if="!(i % 2)" :class="{'issLast': i === timeList.length - 1}">
            {{ getTime(item, i) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapMutations, mapState, mapGetters } from "vuex";

export default {
  props: {
    index: '',
    color: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isClick: false,
      isLast: false,
    };
  },
  computed: {
    ...mapState([
      "timeType",
      "timeTypeMap",
      "timeType",
      "currentData",
    ]),
    ...mapGetters([]),
    ...mapGetters("satellite", ["currentSatelliteTime"]),
    timeList() {
      if(!this.timeTypeMap[this.timeType.type]) return 0;
      console.log('this.timeTypeMap[this.timeType.type]', this.timeTypeMap[this.timeType.type]);
      return this.timeTypeMap[this.timeType.type];
    },
    indexFlag() {
      return this.index;
    },
    date() {
        return this.currentData ? moment(this.currentData.timestamp).format("YYYY-MM-DD") : '';
    },
  },
  methods: {
    ...mapMutations([
      "setCurrentData",
    ]),
    getTime(item, i) {
      if(!item) return '';
      return item ? moment(item.timestamp).format("MM-DD") : '';

    },
    timeClick(i) {
      this.isClick = true;
      if (i === this.timeList.length) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.setCurrentData(this.timeList[i]);
      // this.$emit("change", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  height: 1px;
  background: #6b738b;
  box-sizing: border-box;
  display: flex;
  color: rgba($color: #fff, $alpha: 1);
  font-size: 12px;
  position: relative;

  .issLast {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 63px;
  }

  &.satellite {
    background: #fff;
  }

  .slider {
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    color: rgba($color: #f00, $alpha: 0.5);
    font-size: 20px;
  }

  .target {
    position: absolute;
    top: -7px;
    padding: 4px;
    z-index: 2;
    transform: translateX(-65%);
    border-radius: 50%;
    background: $text-operate;

    .box {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $theme-color;
    }

    .datetime {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      background: #fff;
      color: $theme-color;
      width: 118px;
      line-height: 24px;
      border-radius: 4px;
      text-align: center;

      &::before {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-top: 3px solid #fff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }

    .last_tip {
      left: -210%;

      &::before {
        left: 76%;
      }
    }
  }

  .time_wrap {
    width: 100%;
    display: flex;

    .time_item {
      flex: 1;
      cursor: pointer;
      position: relative;

      &:last-child {
        cursor: pointer;
        width: 2px;
        flex: 0;

        .word {
          transform: translateX(-50%);
        }
      }

      &.animation {
        background: #bac3db;
      }

      .clickDiv {
        position: absolute;
        top: -10px;
        left: -4px;
        cursor: pointer;
        width: 10px;
        height: 35px;
        z-index: 9999;
      }

      .circle {
        width: 1px;
        height: 7px;
        background-color: #6b738b;
        position: absolute;
        top: -6px;
        cursor: pointer;

        &.satellite,
        &.animation {
          background: #fff;
        }
      }

      .word {
        position: absolute;
        top: 4px;
        transform: translateX(-48%);
        text-align: center;
      }
    }
  }
}
</style>

<style>
.current_a {
  background-color: #ffffff;
}
</style>
