<template>
  <div id="map" ref="map">
    <el-popover
      ref="popover5"
      v-model="visible2"
      placement="top"
      width="160"
    >
      <p>这是一段内容这是一段内容确定删除吗？</p>
      <div style="text-align: right; margin: 0">
        <el-button size="mini" type="text" @click="visible2 = false">取消</el-button>
        <el-button type="primary" size="mini" @click="visible2 = false">确定</el-button>
      </div>
    </el-popover>
    <div style="display: none;">
      <Popup ref="popupRef" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import L from 'leaflet';
import { TENCENT_KEY } from '@/config';
import locationIcon from "@/assets/images/search_locate.png";
import Bus from '@/bus/index';
import {
  geoserverUrl,
} from '@/config/MapConfigT4';
import TyphoonDisplay from './TyphoonDisplay';
import Popup from './components/Popup/index.vue';
import { cityLayerFeature } from '@/plugins/CityLayer';
import { getTyphoonList } from '@/api/typhoon';

export default {
  name: 'TyphoonMap',
  components: {
    Popup,
  },
  data() {
    return {
      // 地图实例
      map: null,
      // 弹出框实例
      popup: null,
      // 当前可视区域的范围
      bounds: null,
      // 辅助播放，记录当前时间
      time: Date.now(),
      // 延时器id
      timer: 0,
      // 当需要重新渲染风场时，是否更新的标志
      update: true,
      // 当需要重新渲染海平面气压图层时，是否更新的标志
      slpUpdate: true,

      show: true,
      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 是否初始化位置
      initLocal: true,

      // 记录当前位置
      locationMarker: null,

      // 台风对象
      typhoon: null,

      typhoonData: [],

      visible2: false,
      // Popup 组件的 html 文本
      popupHTML: '',
      loadMapLayerFactors: ['wgrd10m', 'w100m', 't2mz', 'rh2m', 'tmp2m'],
    };
  },
  computed: {
    ...mapState('typhoon', ['typhoonObj', 'tableListData', 'isMorePlay', 'qbTime', 'index', 'timeLineLength',
      'ObserveTyphoonList', 'modelLegend', 'typhoonLineObj', 'observeTyphoonObj', 'modelLegend',
      'timeLineLengthObj', 'tcvitalName', 'timeLineLengthObj', 'linePointMaxLength', 'pointLengthObj',
      'initMove', 'mapCenter']),
    ...mapState('map', ['city', 'layerType']),
    ...mapState('typhoon', []),
    ...mapGetters('typhoon', ['currentPoint']),
  },
  watch: {
    layerType(value) {
      this.clearLayer('toggleMap');

      if (value === 'graph') {
        this.renderGeoLayer();
      } else if (value === 'shadow') {
        this.renderTianDiMapLayer();
      } else {
        this.renderWindyMapLayer();
      }

      this.clearLayer('city');
      if (this.city !== 'none') {
        // 判断字体
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === 'none') {
        this.clearLayer('city');
      } else {
        // 判断字体
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
    },
  },
  created() {
    Bus.$on('zoomIn', this.zoomIn);
    Bus.$on('zoomOut', this.zoomOut);
    Bus.$on('locate', this.manualLocation);
    Bus.$on('renderTyphoon', this.renderTyphoon);
    Bus.$on('replayTyphoon', this.replayTyphoon);
    Bus.$on('pauseTyphoon', this.pauseTyphoon);
    Bus.$on('overTyphoon', this.overTyphoon);
    Bus.$on('continuePlay', this.continuePlay);
    Bus.$on('deleteTyphoon', this.deleteTyphoon);
    Bus.$on('locationToTyphoon', this.locationToTyphoon);
    Bus.$on('pointClick', this.pointClick);
    Bus.$on('timeLinePointClick', this.timeLinePointClick);
    Bus.$on('renderObserveTyphoon', this.renderObserveTyphoon);
    Bus.$on('closePop', this.closePop);
    Bus.$on("setLocation", this.setLocation);
  },
  beforeDestroy() {
    Bus.$off('zoomIn', this.zoomIn);
    Bus.$off('zoomOut', this.zoomOut);
    Bus.$off('locate', this.manualLocation);
    Bus.$off('renderTyphoon', this.renderTyphoon);
    Bus.$off('replayTyphoon', this.replayTyphoon);
    Bus.$off('pauseTyphoon', this.pauseTyphoon);
    Bus.$off('overTyphoon', this.overTyphoon);
    Bus.$off('continuePlay', this.continuePlay);
    Bus.$off('deleteTyphoon', this.deleteTyphoon);
    Bus.$off('locationToTyphoon', this.locationToTyphoon);
    Bus.$off('pointClick', this.pointClick);
    Bus.$off('timeLinePointClick', this.timeLinePointClick);
    Bus.$off('renderObserveTyphoon', this.renderObserveTyphoon);
    Bus.$off('closePop', this.closePop);
    Bus.$off("setLocation", this.setLocation);
    this.map.off('zoomend', this.stroageZoom);
    this.setMapCenter(this.map.getCenter());
    this.setCenter(this.map.getCenter());
  },
  mounted() {
    this.initMap();
    // 定位到用户当前位置
    this.map.locate();
    this.popupHTML = this.$refs.popupRef.$el.outerHTML;
    this.initTyphoonLine();
  },

  methods: {
    ...mapMutations('typhoon', ['setTyphoonListData', 'setTyphoonLayerData', 'setCurrentTyphoonId',
      'setStatus', 'setIsPause', 'setTyphoonObj', 'setIndex', 'setMoreStatus', 'setIsMorePause',
      'setObserveTyphoonList', 'setObserveTyphoonObj', 'setTyphoonLineObj', 'setTimeLineDate',
      'setTimeLineLength', 'setTimeLineIndex', 'setTcvitalName', 'setLinePointMaxLength', 'setInitMove',
      'setMapCenter']),
    ...mapMutations(['setCenter']),
    ...mapMutations('map', ['setLoading']),

    /**
     * @description 初始化地图
     * @returns {void}
     * @author yujie
     */
    initMap() {
      let mapCenter = [28, 146];
      // if (Object.keys(this.mapCenter).length > 0) {
      if (this.mapCenter) {
        mapCenter = [this.mapCenter.lat, this.mapCenter.lng];
      }
      const reg = /cpu iphone os (.*?) like mac os/;
      const ios = !!navigator.userAgent.toLowerCase().match(reg);
      this.map = L.map('map', {
        crs: L.CRS.EPSG4326, // 坐标系--大地坐标系
        center: mapCenter, // 坐标中心
        // 最大倍数
        maxZoom: 9,
        minZoom: this.t3 ? 8 : 2, // 最小倍数
        zoom: localStorage.getItem('current-zoom') ? localStorage.getItem('current-zoom') : 4, // 初始缩放倍数, // 初始缩放倍数
        maxBounds: this.t3 ? [[this.region.latMin, this.region.lonMin], [this.region.latMax, this.region.lonMax]] : [[-90, -Infinity], [90, Infinity]], // 区域限制范围
        maxBoundsViscosity: 1,
        attributionControl: false, // 是否去除右下角标志
        zoomControl: false, // 是否显示地图缩放图例
        zoomAnimation: !(this.isMobile && ios),
      });

      // 创建不同的窗口(由于zIndex无效)
      this.map.createPane('borderLayerPane');
      this.map.createPane('cityLayerPane');
      this.map.createPane('mapLayerPane');

      // 设置窗口对应层级
      this.map.getPane('cityLayerPane').style.zIndex = 100;
      this.map.getPane('borderLayerPane').style.zIndex = 98;
      this.map.getPane('mapLayerPane').style.zIndex = 30;
      this.setCurrentBounds();
      this.addLayer();
      // this.renderCordon();
      this.addEvent();

      // this.convertMapByZoom();
    },

    async initTyphoonLine() {
      if (this.qbTime === '') {
        // 中科天机
        const typhoonData = await getTyphoonList('model=sd3Addall');
        // const typhoonData = await getTyphoonList();
        this.setTyphoonListData(typhoonData);
      } else {
        this.setTcvitalName(null);
        Bus.$emit('initTyphoon', this.qbTime);
      }
    },

    /**
     * @description 地图缩放等级放大一级
     * @returns {void}
     * @author yujie
     */
    zoomIn() {
      this.map.zoomIn();
    },

    /**
     * @description 地图缩放等级缩小一级
     * @returns {void}
     * @author yujie
     */
    zoomOut() {
      this.map.zoomOut();
    },

    /**
     * @description 手动定位到用户当前所在位置
     * @returns {void}
     * @author yunpengliu
     */
    manualLocation() {
      this.map.flyTo([this.currentLatitude, this.currentLogitude], 7);
    },

    changeMapLayer(value) {
      this.borderLayer = this.setCountryBorder();
      this.clearLayer('toggleMap');
      if (value === 'graph') {
        this.map.addLayer(this.borderLayer);
        this.renderGeoLayer();
      } else if (value === 'shadow') {
        this.renderTianDiMapLayer();
        this.map.addLayer(this.borderLayer);
      } else {
        this.renderWindyMapLayer();
      }
    },

    /**
     * @description 设置当前可视区域的范围
     * @returns {void}
     * @author yujie
     */
    setCurrentBounds() {
      this.bounds = this.map.getBounds();
    },

    /**
     * 构建城市图层
     */
    setCityLayer(type) {
      type = this.layerType === 'shadow' ? true : type;
      const cityLayer = cityLayerFeature('/cities/{z}/{x}/{y}.json', {
        zoomOffset: 1,
        updateInterval: 5000,
        selectColor: type,
      });

      return cityLayer;
    },

    /**
     * @description 添加图层
     * @returns {void}
     * @author yujie
     */
    addLayer() {

      this.changeMapLayer(this.layerType);
      if (this.city === 'normal') {
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }

      // 将图层添加到地图上
      // .addLayer(this.mapLayer);
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: 'borderLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {
            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: '#333',
                fillOpacity: 1,
                fill: true,
                color: '#333',
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: '#333',
              fillOpacity: 1,
              fill: true,
              color: '#333',
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: '#1d1d1d',
                weight: 1,
              };
            }
            return {
              color: '#333',
              dashArray: '2, 4',
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: '#999',
                dashArray: '2, 6',
                weight: 1,
              };
            }
            if (properties.country_level === 4) {
              return {
                color: '#ccc',
                dashArray: '2, 8',
                weight: 1,
              };
            }
            if (properties.country_level === 2) {
              return {
                color: '#333',
                dashArray: '2, 4',
                weight: 1.2,
              };
            }
            return {
              color: '#1d1d1d',
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    setWindyLayer() {
      return L.tileLayer(`https://www.tjweather.com/clear/{z}/{x}/{-y}.png`, {
        zoomOffset: 1,
        maxNativeZoom: 7,
        pane: "mapLayerPane",
      });
    },
    renderWindyMapLayer() {
      if (this.borderLayer){
        this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }
      this.mapLayer = this.setWindyLayer();
      this.map.addLayer(this.mapLayer);
    },

    renderGeoLayer() {
      if (this.mapLayer){
        this.map.removeLayer(this.mapLayer)
      }
      // 地形图
      this.mapLayer = this.setHillShadeLayer();
      this.map.addLayer(this.mapLayer);
      const zoomlevel = this.map.getZoom();
      if (zoomlevel<9){
        if (!this.borderLayer){
          this.borderLayer = this.setCountryBorder();
          this.map.addLayer(this.borderLayer)
        }
      }
    },

    /**
     * @description 移除地图图层
     * @returns {void}
     * @author yujie
     */
    clearLayer(type) {
      if (type === 'city') {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
      }

      // change 地形图 还是天地图
      if (type === 'toggleMap') {
        if (this.mapLayer) this.map.removeLayer(this.mapLayer);
        this.mapLayer = null;
      }

      if (!type || type === 'isobar') {
        if (this.isobarLayer) this.map.removeLayer(this.isobarLayer);
        this.isobarLayer = null;
      }
    },

    setHillShadeLayer() {
      const hillshade = L.tileLayer(`${geoserverUrl}/hillshade/{z}/{x}/{y}.png`, {
        zoomOffset: 1,
        pane: 'mapLayerPane',
        className: 'hillshade-class-img-map',
      });
      return hillshade;
    },

    setSatelliteLayer() {
      const satellite = L.tileLayer(`${geoserverUrl}/satellite/{z}/{x}/{y}.png`, { // 链接要改对应的
        zoomOffset: 1,
        pane: 'mapLayerPane',
        className: 'satellite-class-img-map',
      });
      return satellite;
    },

    renderTianDiMapLayer() {
      // 影像图
      if (this.mapLayer) {
        this.map.removeLayer(this.mapLayer)
      }
      this.mapLayer = this.setSatelliteLayer();
      // this.borderLayer = this.setCountryBorder();
      this.map.addLayer(this.mapLayer);
      const zoomlevel = this.map.getZoom();
      if (zoomlevel<9){
        if (!this.borderLayer){
          this.borderLayer = this.setCountryBorder();
          this.map.addLayer(this.borderLayer)
        }

      }
    },

    closePop() {
      this.map.closePopup();
    },

    renderTyphoon(renderData) {
      this.setLoading(false)
      if (this.initMove && Object.keys(renderData.center).length > 0 && (!this.mapCenter || !this.initLocal)) {
        this.initLocal = false;
        // 移动到地图中心点
        this.map.setView([renderData.center.lat, renderData.center.lon]);
      }

      const data = renderData.data;
      if (data) {
        const typhoon = new TyphoonDisplay(
          this.map,
          renderData.key + data.id,
          renderData.key,
          this.popupHTML,
          data.max,
          this.modelLegend[renderData.key],
          true,
          data.nameCn,
          renderData.haveQb,
          false,
        );
        typhoon.replay(data);
        // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
        // this.setCurrentTyphoonId(v.id);
        this.setTyphoonLineObj({
          id: renderData.key + data.id,
          typhoon,
        });
      }
      // if (data && data.length > 0) {
      //   // this.setStatus('play');
      //   data.forEach(v => {
      //     const typhoon = new TyphoonDisplay(this.map,renderData.key+v.id, renderData.key,this.popupHTML,v.max,
      //       this.modelLegend[renderData.key],true,v.nameCn,false);
      //     typhoon.replay(v);
      //     // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
      //     // this.setCurrentTyphoonId(v.id);
      //     console.log('renderData.key+v.id==>',renderData.key+v.id)
      //     this.setTyphoonLineObj({
      //       id: renderData.key+v.id,
      //       typhoon: typhoon,
      //     });
      //   });
      //   // this.typhoonData = data;
      // }
    },

    pointClick(item) {
      this.setTimeLineDate(item.time);
      const currentPoint = this.currentPoint;
      if (currentPoint) {
        currentPoint.ispop = true;
        currentPoint.key = item.key;
        currentPoint.windIndex = item.index;
        currentPoint.lineClick = false;
        Bus.$emit('tiggerTimeClick', currentPoint);
        // 需要绘制其他相同时刻的风圈
        // this.findSameTimeByObserve(currentPoint.value);
      } else if (item.key === 'tcvital' || item.key === 'sd3ens') {
        // 如果观测的点击点不在时间轴上，其他观测有相同时间的，则共同弹出
        const checkObj = this.checkLineChecked(item.key);
        if (checkObj.windChecked) {
          this.findSameTimeByObserve(item.time);
        }
        // 清除掉预报的风圈，因为没有时间与之相对应
        if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
          Object.keys(this.typhoonLineObj).forEach((t) => {
            this.typhoonLineObj[t].forecastDeleteWindCircle();
          });
        }
      }
    },

    timeLinePointClick(item) {
      // console.log('item_index===>',item.clickTime,'this.typhoonLineObj==>',this.typhoonLineObj);
      if (!item.ispop) {
        this.map.closePopup();
      }
      this.$sendPoint({
        category: 'button',
        action: '时间轴-台风',
        opt_label: `${item.clickTime}`,
      });
      // 如果点击的点等于起报，并且key = sd3ens，则只弹出一个框即可
      const selectValue = this.qbTime.split('#');
      const qbTime = selectValue[0];
      if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        Object.keys(this.typhoonLineObj).forEach((t) => {
          // 先判断风是否展示，如果风展示然后再判断是否展示风圈
          if (item.clickTime === qbTime && item.key === 'sd3ens') {
            this.typhoonLineObj[t].pointClickCircleWithTime(item.clickTime, false);
          } else {
            const checkObj = this.checkLineChecked(t);
            if (checkObj.lineChecked) {
              this.typhoonLineObj[t].pointClickCircleWithTime(item.clickTime, checkObj.windChecked);
            }
          }
        });
      }
      // if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
      //   Object.keys(this.typhoonLineObj).forEach(t => {
      //     let newIndex = item.windIndex;
      //     // 判断是否展示风圈,如果当前所选时刻部分线路不存在当前时刻，则不展示风圈
      //     if (item.lineClick && this.typhoonLineObj[t].haveQb) {
      //       newIndex ++;
      //     }
      //     if (newIndex  >= this.timeLineLengthObj[t]) {
      //       this.typhoonLineObj[t].deleteTyphoonIcon();
      //     }
      //
      //     // 先判断风是否展示，如果风展示然后再判断是否展示风圈
      //     if (item.value === qbTime && item.key === 'sd3ens'){
      //       // this.setTimeLineLength(newIndex);
      //       this.typhoonLineObj[t].pointClickCircle(newIndex, false);
      //     } else {
      //       const checkObj = this.checkLineChecked(t)
      //       if (checkObj.lineChecked) {
      //         // this.setTimeLineLength(newIndex);
      //         this.typhoonLineObj[t].pointClickCircle(newIndex, checkObj.windChecked);
      //       }
      //     }
      //
      //   });
      // }
      this.findSameTimeByObserve(item.clickTime);
    },

    checkLineChecked(t) {
      let windChecked = true;
      let lineChecked = true;
      for (const key of Object.keys(this.modelLegend)) {
        if (t.indexOf(key) > -1) {
          windChecked = this.modelLegend[key].windChecked;
          lineChecked = this.modelLegend[key].checked;
        }
      }
      return { lineChecked, windChecked };
    },

    findSameTimeByObserve(date) {
      // 判断实况的时间是否在当前时刻重叠
      // 判断风圈是否展示
      const checkObj = this.checkLineChecked('tcvital');
      if (checkObj.lineChecked) {
        if (this.observeTyphoonObj && Object.keys(this.observeTyphoonObj).length > 0) {
          Object.keys(this.observeTyphoonObj).forEach((t) => {
            this.observeTyphoonObj[t].pointClickCircleWithTime(date, checkObj.windChecked);
          });
        }
      }
    },

    locationToTyphoon(id) {
      this.typhoon = this.typhoonObj[id];
      this.map.fitBounds(this.typhoon.timeLinePointClickpolyline.getBounds());
    },

    replayTyphoon() {
      // 如果没有可执行的
      // 从当前点位开始播放，只需要绘制风圈和图标即可
      // 定时器100ms，动态的塞入坐标数据
      // this.timer = setInterval(
      //   async () => {
      //     Bus.$emit('next');
      //   }, 600,
      // )

      // let runFlag = false;
      // if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
      //   Object.keys(this.typhoonLineObj).forEach(v => {
      //     let checked = true;
      //     for (let key of Object.keys(this.modelLegend)) {
      //       if (v.indexOf(key) > -1) {
      //         checked = this.modelLegend[key].checked;
      //         break;
      //       }
      //     }
      //     if (checked) {
      //       // 播放只播放预报的
      //       this.typhoonLineObj[v].replayForecastLine(null,-1,true);
      //       runFlag = true;
      //     }
      //   })
      // }
      // if (!runFlag) {
      //   this.setStatus('pause');
      //   this.setIsPause(false);
      // }
    },

    continuePlay() {
      if (this.typhoonLineObj) {
        const typhoonObjKeys = Object.keys(this.typhoonLineObj);
        if (typhoonObjKeys.length > 0) {
          typhoonObjKeys.forEach((v) => {
            const checkObj = this.checkLineChecked(v);
            if (checkObj.lineChecked) {
              // this.typhoonLineObj[v].pointClickCircle(item.index, checkObj.windChecked);
              this.typhoonLineObj[v].continueDrawLine(checkObj.windChecked);
            }
          });
        }
      }
    },

    pauseTyphoon() {
      if (this.typhoonLineObj) {
        const typhoonObjKeys = Object.keys(this.typhoonLineObj);
        if (typhoonObjKeys.length > 0) {
          typhoonObjKeys.forEach((v) => {
            const checkObj = this.checkLineChecked(v);
            if (checkObj.lineChecked) {
              this.typhoonLineObj[v].pause();
            }
          });
        }
      }
    },

    overTyphoon(length) {
      // 点击时间轴的播放按钮
      if (this.linePointMaxLength === length) {
        this.setStatus('pause');
        this.setIsPause(false);
      }

      // if (this.isMorePlay) {
      //   // 点击table上面的播放按钮
      //   this.setMoreStatus('pause');
      //   this.setIsMorePause(false);
      // } else {
      //   // 点击时间轴的播放按钮
      //   this.setStatus('pause');
      //   this.setIsPause(false);
      // }
    },

    deleteTyphoon() {
      if (this.typhoon) {
        this.typhoon.deleteTyphoon();
      }
    },

    addEvent() {
      this.map.on('zoomend', this.stroageZoom);
      // 定位到用户当前位置
      // const dotIcon = L.icon({
      //   iconUrl: locateIcon,
      //   iconSize: [48, 48],
      // });
      L.control
        .scale({
          imperial: false,
          maxWidth: 70,
          position: "bottomleft",
        })
        .addTo(this.map);

      const dotIcon = L.divIcon({
        className: "blue-circle-icon",
        iconSize: [10, 10], // 设置图标大小
        html: '<div style="background-color: #156FCF; width: 10px; height: 10px; border-radius: 50%;border: 2px solid white;"></div>', // 使用HTML来定义圆形图标
      });

      // this.map.on('locationfound', (position) => {
      //   const locationMarker = L.marker([position.latitude, position.longitude], { icon: dotIcon });
      //   locationMarker.addTo(this.map);
      //   this.currentLatitude = position.latitude;
      //   this.currentLogitude = position.longitude;
      // });
      // this.map.on('locationerror', () => {
        // 使用腾讯地图定位
        this.tencentMapLocation(dotIcon);
      // });

      this.map.on("mousemove", (e) => {
        const {lat, lng} = e.latlng;
        const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(lng) > 0.0 ? 'E' : 'W';
        const lnglatText = Math.abs(lng).toFixed(2) + lngS + '\xa0\xa0'
          + Math.abs(lat).toFixed(2) + latS;
        this.$emit('mapMouseMove',lnglatText)
      })

      this.map.doubleClickZoom.disable();
    },

    stroageZoom() {
      localStorage.setItem('typhoon-current-zoom', this.map.getZoom());
      localStorage.setItem('current-zoom', this.map.getZoom());
    },

    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation(dotIcon) {
      this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key: TENCENT_KEY,
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          const locationMarker = L.marker([res.result.location.lat, res.result.location.lng], { icon: dotIcon });
          locationMarker.addTo(this.map);
          this.currentLatitude = res.result.location.lat;
          this.currentLogitude = res.result.location.lng;
        }
      });
    },

    // 处理地图popup的更多点击事件
    handlerOmap(e) {
      const { className } = e.target;
      // console.log(className)
      if (className.includes('el-icon-close')) {
        this.visible2 = true;
      }
    },

    setLocation(position) {
      const { lat, lon } = position;
      position = L.latLng([lat, lon]);
      this.setMarker(position);
    },

    setMarker(position) {
      this.removeMarker();
      const dotIcon = L.divIcon({
        className: "",
        iconSize: [10, 10], // 设置图标大小
        html: '<div id="popid" style="background-color: #ff0000; width: 6px; height: 6px; border-radius: 50%;' +
          'border: 2px solid white;"></div>', // 使用HTML来定义圆形图标
      });
      this.locationMarker = L.marker(position, {
        icon: dotIcon,
      });
      // this.locationMarker = L.marker(position, {
      //   icon: L.icon({
      //     iconUrl: locationIcon,
      //     className: "locatedIcon",
      //     iconSize: [20, 20],
      //     iconAnchor: [7, 14],
      //   }),
      //   noWrap: true,
      // });
      this.locationMarker.addTo(this.map);
      this.map.panTo(position);
      const popUpTips = document.getElementById('popid');
      if (popUpTips) {
        let el = document.createElement('div');
        el.className = 'pulsating-icon repeat';
        popUpTips.append(el);
      }
    },

    // 移除地图标记
    removeMarker() {
      this.locationMarker?.remove();
    },

    renderObserveTyphoon(data) {
      // const data = this.ObserveTyphoonList;
      if (data) {
        const typhoon = new TyphoonDisplay(
          this.map,
          `tcvital${data.id}`,
          'tcvital',
          this.popupHTML,
          data.max,
          this.modelLegend.tcvital,
          false,
          data.nameCn,
          true,
          false,
        );
        typhoon.replay(data);
        // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
        // this.setCurrentTyphoonId(v.id);
        this.setObserveTyphoonObj({
          id: `tcvital${data.id}`,
          obj: typhoon,
        });
      }
      // if (data && data.length > 0) {
      //   // this.setStatus('play');
      //   data.forEach(v => {
      //     console.log('v===>',v);
      //     const typhoon = new TyphoonDisplay(this.map,'tcvital'+v.id,'tcvital',this.popupHTML,
      //       v.max,this.modelLegend['tcvital'],false,v.nameCn,false);
      //     typhoon.replay(v);
      //     // this.setTyphoonLayerData(this.typhoon.myLayerGroupPoint);
      //     // this.setCurrentTyphoonId(v.id);
      //     this.setObserveTyphoonObj({
      //       id: 'tcvital'+v.id,
      //       obj: typhoon,
      //     });
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

#factor-tooltip {
  display: none;
  background: #c8c8c8;
  color: #333;
  opacity: 0.7;
  border-radius: 5px;
  font-family: "微软雅黑", sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 20px;
}

::v-deep {
  .leaflet-left {
    bottom: 45px;

    .leaflet-control-scale {
      margin-left: 5px;
    }
  }

  .leaflet-right {
    .leaflet-control-scale {
      margin-right: 22px;
    }
  }

  .leaflet-popup-close-button {
    right: 12px !important;
    line-height: 38px !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    color: white !important;
    padding: 0px !important;
    margin: 0px;
  }

  .leaflet-popup-content-wrapper {
    padding: 0px 0px 2px 0px;
    border-radius: 4px;
  }

  .leaflet-popup-content {
    padding: 0px;
    margin: 0px;
    width: 360px !important;
  }

  //.leaflet-popup-content-wrapper {
  //  position: absolute;
  //  left: 60%;
  //  top: -90px;
  //  margin-left: -1px;
  //  border-radius: 0 40px 40px 0;
  //  background-color: rgba($text-operate, 0.92);
  //  color: $text-menu;
  //
  //  .leaflet-popup-content {
  //    margin: 3px 10px;
  //
  //    .line {
  //      border-left: 2px solid rgba($text-operate, 1);
  //      height: 90px;
  //      position: absolute;
  //      left: 0;
  //      top: 0;
  //    }
  //
  //    .close {
  //      position: absolute;
  //      font-size: 20px;
  //      right: -20px;
  //      top: 0;
  //      color: $text-operate;
  //      cursor: pointer;
  //    }
  //
  //    .custom-popup-content {
  //      .custom-popup-text {
  //        display: flex;
  //        align-items: center;
  //        white-space: nowrap;
  //        justify-content: space-between;
  //
  //        .current-value {
  //          font-size: 14px;
  //        }
  //
  //        .more-factor-data {
  //          width: 30px;
  //          height: 30px;
  //          margin-left: 6px;
  //          text-align: center;
  //          line-height: 30px;
  //          border-radius: 50%;
  //          background-color: $theme-color;
  //          color: #fff;
  //          font-size: 16px;
  //          font-weight: 700;
  //        }
  //      }
  //    }
  //  }
  //}

  .custom-popup-sepcial>.leaflet-popup-content-wrapper {
    top: 64px;

    .leaflet-popup-content {
      .line {
        top: -64px;
      }
    }
  }

  .leaflet-marker-icon {
    .pulsating-icon {
      animation: pulsate .5s ease-out ;
      -webkit-animation: pulsate .5s ease-out;
      animation-iteration-count: 3 ;
      -webkit-animation-iteration-count: 3;
      border: 2px solid #FFFFFF;
      pointer-events: none;
      height: 14px;
      width: 14px;
      margin-top: -6px;
      margin-left: -6px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      opacity: 0
    }

    .pulsating-icon.repeat {
      animation: pulsate 2s ease-out;
      -webkit-animation: pulsate 2s ease-out;
      /* animation-iteration-count:infinite; */
      -webkit-animation-iteration-count: infinite;
    }
  }

  //.leaflet-popup-tip-container {
  //  top: -2px;
  //
  //  .leaflet-popup-tip {
  //    border-radius: 50%;
  //    width: 12px;
  //    height: 12px;
  //    background: $theme-color;
  //    box-sizing: border-box;
  //    border: 2px solid $text-operate;
  //    margin: 0 auto;
  //  }
  //}

  .more-factor-data {
    color: #0078a8;
    cursor: pointer;
  }
}

.locatedIcon {
  margin-top: -10px !important;
}

@keyframes pulsate {
  0% {
    transform: scale(0.3, 0.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.6,1.6);
    opacity: 0;
  }
}

</style>
