import { httpHD as http } from './http';

/**
 * @description 获取台风列表
 * @returns {Promise}
 * @author guoqb
 */
export const getTyphoonList = (paramsStr) => (
  http.get(paramsStr ? `/main/tropical-cyclone/list?${paramsStr}` : '/main/tropical-cyclone/list', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
);

/**
 * @description 获取台风详细列表
 * @returns {Promise}
 * @author guoqb
 */
export const getTyphoonDetailList = (ids) => (
  http.get('/main/tropical-cyclone/details', { params: { ids } })
);

/**
 * @description 获取台风列表
 * @returns {Promise}
 * @author guoqb
 */
export const getTyphoonLatestList = (paramsStr) => (
  http.get(paramsStr ? `/main/tropical-cyclone/tcvital/latest?${paramsStr}` : '/main/tropical-cyclone/tcvital/latest', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  })
);
