/**
 * 节流函数
 * @param {Function} func 目标函数
 * @param {*} wait 节流间隔
 * @param {*} delay 最后一次执行延迟，默认为 wait 的一半
 */
const throttle = (func, wait, delay) => {
  let timeout;
  let time = 0;
  delay = delay || (wait / 2);
  return () => {
    const context = this;
    // eslint-disable-next-line no-undef
    const args = arguments;
    const now = Date.now();
    if (timeout) {
      clearTimeout(timeout);
    }
    if (now - time > wait) {
      time = now;
      timeout = null;
      func.apply(context, args);
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait + time - now + delay);
    }
  };
};

/**
 * @description 获取图片质量
 * @param {Number} diff ms数
 * @returns {Number} 图片的压缩比
 * @author yujie
 */
const getQuality = (diff) => {
  const map = [1500, 3000, 4500];

  if (diff <= map[0]) {
    return 1;
  }

  if (diff > map[0] && diff < map[1]) {
    return 0.9;
  }

  if (diff > map[1] && diff < map[2]) {
    return 0.7;
  }

  return 0.5;
};

// 获取浏览器窗口的可视区域的高度
function getViewPortHeight() {
  return document.documentElement.clientHeight || document.body.clientHeight;
}

export {throttle, getQuality, getViewPortHeight};
