<template>
  <div class="popup" v-show="popupShow">
    <div class="left">
      <div class="text">{{ popupVal }}
        <span>{{ popupUnit === '℃' ? '°' : popupUnit }}</span>
      </div>
    </div>
    <div class="right">
      <div class="lat-lng">{{ latlngText }}</div>
      <van-button type="primary" size="small" round @click="handleMore" v-if="isShowDetailBtn">该地点预报</van-button>
      <van-icon name="cross" size="26px" class="icon" @click="close"/>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import Bus from "@/bus/index";

export default {
  data() {
    return {
      precpData: '',
    }
  },
  computed: {
    ...mapState(['isMobile','factor']),
    ...mapState('map', ['isPopupShow', 'factorName', 'popupVal', 'popupUnit', 'latlng']),
    popupShow: {
      get() {
        return this.isPopupShow
      },
      set(val) {
        this.setPopupShow(val)
      }
    },
    latlngText() {
      const { lat, lng } = this.latlng
      const rightLng =((lng - 180) % 360 + 360) % 360 - 180;
      const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
      const lngS = parseFloat(rightLng) > 0.0 ? 'E' : 'W';
      return  `${Math.abs(rightLng).toFixed(2)}${lngS},${Math.abs(lat).toFixed(2)}${latS}`
    },
    isShowDetailBtn() {
      return localStorage.getItem("router-url") === 'weather_hd' ||
      localStorage.getItem("router-url") === 'earth' ||
      this.factor === 'precp'
    }
  },
  created() {
    Bus.$on("closeDetailShow", this.close);
  },
  beforeDestroy() {
    Bus.$off("closeDetailShow", this.close);
  },
  mounted() {
    Bus.$on("precpData", (precpData)=>{
      this.precpData = precpData
    });
  },
  methods: {
    ...mapMutations('map',["setPopupShow"]),
    close() {
      this.popupShow = false
      Bus.$emit("removePopup");
      Bus.$emit("hiddenBottom");
    },
    handleMore() {
      Bus.$emit("showDetailInfo", this.latlng, this.precpData);
    },
  }
};
</script>

<style lang="scss" scoped>
.popup {
  position: absolute;
  z-index: 9999999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background: $bg-popup;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  .left {
    display: flex;
    align-items: center;
    .lat-lng {
      font-size: 12px;
      color: lightgrey;
    }
    .text {
      margin-left: 15px;
      font-size: 20px;
      font-weight: 500;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
    }
    .lat-lng {
      font-size: 12px;
      color: lightgrey;
      margin-right: 10px;
    }
  }

}
</style>
