<template>
  <div
    ref="selectDom"
    class="elevation_select"
    :class="{
      left: viewHeight >= 800,
      right: viewHeight < 800,
    }"
  >
    <div class="box">
      <div class="empty-box">
        <div
          v-for="i in elevationList"
          :key="i"
          class="empty-item"
          :data-value="i"
          @click="handler(i)"
          @mousemove="handlerMouseover(i, $event)"
          @mouseout="moveShow = false"
        />
      </div>
      <div
        class="target"
        :style="{
          height: (position.h + 10) + 'px',
        }"
      />
      <div
        class="circle active"
        :style="{
          bottom: position.h + 'px',
        }"
      >
        <div
          class="word"
          :class="{
            left: viewHeight >= 800,
            right: viewHeight < 800,
          }"
        >
          {{ elevation }}hPa
        </div>
      </div>
      <div
        v-show="moveShow"
        class="circle hover"
        :style="{
          bottom: position.m + 'px',
        }"
      >
        <div
          class="word"
          :class="{
            left: viewHeight >= 800,
            right: viewHeight < 800,
          }"
        >
          {{ mValue }}hPa
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import Bus from '@/bus/index';

export default {
  data() {
    return {
      sliderHeight: null,
      moveShow: false,
      mValue: 0,
      position: {
        // 滑块的高度
        h: 0,
        disX: 0,
        clientHeight: 0,
        m: 0,
      },
      // 鼠标滑过的距离
    };
  },
  computed: {
    ...mapState('map', ['elevation', 'viewHeight']),
    ...mapGetters('map', ['elevationList']),
    value: {
      get() {
        return this.elevation;
      },
      set(value) {
        this.setElevation(value);
        Bus.$emit('clearLayer', 'map');
        Bus.$emit('clearLayer', 'wind');
        Bus.$emit('renderElevation');
      },
    },
  },
  mounted() {
    const oDom = this.$refs.selectDom;
    const { offsetTop, clientHeight } = oDom;
    // 获取元素底部到达屏幕顶部的距离
    const disX = offsetTop + clientHeight;
    // 鼠标移动的事件处理函数
    const handlerMouseMove = (e) => {
      const { clientY } = e;
      // const { clientY, target } = e;
      // const { dataset } = target;
      // 滑块可移动的距离
      const moveH = disX - clientY - 5;
      // h就是距离元素底部的距离 = 滑块的滑动距离
      const h = moveH > 0 ? moveH : 0;
      const cH = clientHeight - 10;
      const $h = h > cH ? cH : h;
      this.position.h = $h;
      // 计算当前高程的值得index
      let index = 0;
      // 一个高程单位所占据的百分比
      const per = 1 / this.elevationList.length;
      // 当前位置所占的百分比
      const currentH = $h / cH;
      if (currentH >= 0 && currentH < per) {
        index = 1;
      } else if (currentH >= per && currentH < 2 * per) {
        index = 2;
      } else if (currentH >= 2 * per && currentH < 3 * per) {
        index = 3;
      } else if (currentH >= 3 * per && currentH < 4 * per) {
        index = 4;
      } else if (currentH >= 4 * per && currentH <= 5 * per) {
        index = 5;
      }
      this.handler(this.elevationList[this.elevationList.length - index]);
    };
    this.sliderHeight = clientHeight;
    this.position.clientHeight = clientHeight;
    this.position.disX = disX;
    oDom.addEventListener('mousedown', (e) => {
      const { clientY } = e;
      // h就是距离元素底部的距离 = 滑块的滑动距离
      this.position.h = disX - clientY - 5;
      oDom.addEventListener('mousemove', handlerMouseMove);
    });
    oDom.addEventListener('mouseup', () => {
      oDom.removeEventListener('mousemove', handlerMouseMove);
    });
    oDom.addEventListener('mouseleave', () => {
      oDom.removeEventListener('mousemove', handlerMouseMove);
    });
  },
  methods: {
    ...mapMutations('map', ['setElevation']),
    handler(value) {
      if (value !== this.elevation) {
        this.setElevation(value);
        Bus.$emit('clearLayer', 'map');
        Bus.$emit('clearLayer', 'wind');
        Bus.$emit('renderElevation');
      }
    },
    handlerMouseover(value, e) {
      const { clientY } = e;
      const moveH = this.position.disX - clientY - 5;
      const h = moveH > 0 ? moveH : 0;
      const cH = this.position.clientHeight - 10;
      // h就是距离元素底部的距离 = 滑块的滑动距离
      this.position.m = h > cH ? cH : h;
      this.moveShow = true;
      this.mValue = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.elevation_select {
  position: absolute;
  z-index: 10001;
  //left: 85px;
  bottom: 45px;

  &.right {
    right: 90px;
  }

  &.left {
    left: 85px;
  }

  .box {
    width: 10px;
    height: 130px;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.45);
    position: relative;

    .empty-box {
      width: 10px;
      height: 100%;
      display: flex;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 5;
      flex-direction: column;

      .empty-item {
        flex: 1;
      }
    }

    .target {
      width: 100%;
      height: 30px;
      background: rgba($text-operate, 1);
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 5px;
      cursor: pointer;
    }

    .circle {
      position: absolute;
      left: 0;
      bottom: 30px;
      width: 100%;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      border: 2px solid #ccc;
      color: #333;
      background: rgba(#333, 1);

      &.active {
        z-index: 3;
        border: 2px solid $theme-color;
        color: $theme-color;
        background: rgba($text-operate, 1);
      }

      .word {
        height: 18px;
        padding: 0 5px;
        position: absolute;
        //left: 14px;
        top: 50%;
        transform: translateY(-50%);
        line-height: 18px;
        font-size: 12px;
        text-align: center;
        background: rgba($text-operate, 1);
        border-radius: 3px;

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
        }

        &.left {
          left: 14px;

          &::before {
            left: -3px;
            border-right: 3px solid #fff;
          }
        }

        &.right {
          right: 14px;

          &::before {
            right: -3px;
            border-left: 3px solid #fff;
          }
        }

      }
    }
  }
}
</style>
