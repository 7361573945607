import { VueJsonp } from 'vue-jsonp';
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import moment from 'moment-timezone';
import { sendPoint } from '@/buryingPoint/index.js';
import SvgIcon from '@/components/SvgIcon/index.vue';
import '@/plugins/index';
import App from './App.vue';
import router from './router';
import store from './store';
import { pageMap } from '@/config/MapConfig.js'
import '@/style/index.scss';
import '@/plugins/HeatMapLayerByWebGL';
import '@/utils/loadVconsole'



var sensors = require('sa-sdk-javascript');

let sensor_url = 'https://scnetcn.datasink.sensorsdata.cn/sa?project=production&token=fc73993be7eb95b9';

if (window.location.hostname !== 'www.tjweather.com') {
   sensor_url = '';
}

sensors.init({
  server_url: sensor_url,
  heatmap: {
    //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
    clickmap:'default',
    get_vtrack_config: true,
    //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
    collect_tags:{
      div: {
        max_level: 3 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
      },
      li: true,
      img: true,
      span: true,
    },
    scroll_notice_map: 'default',
  },
  is_track_single_page: true,
  use_client_time:true,
  send_type:'beacon'
});

const userAgent = navigator.userAgent;
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);

sensors.registerPage({
	platform_name: 'tjweather',
  device_type: isMobile ? 'Mobile' : 'PC',
});
sensors.quick('autoTrack');






const req = require.context('@/assets/icons/svg', false, /\.svg$/);
req.keys().map(req);
Vue.component('SvgIcon', SvgIcon);
Vue.use(VueJsonp);
Vue.use(ElementUI);
Vue.use(Vant);
Vue.prototype.$sendPoint = sendPoint;
Vue.prototype.$sensors = sensors;



moment.tz.setDefault('Asia/Shanghai');

Vue.config.productionTip = false;

(function shieldConsole() {
  if (process.env.NODE_ENV === 'production') {
    Reflect.ownKeys(console).map((key) => {
      if (typeof console[key] === 'function') {
        console[key] = () => {};
      }
    });
  }
}());


router.beforeEach((to, from, next) => {
  sensors.track('tj_pageAccess', {
    tj_title: pageMap[to.name],
  })
  next()
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
