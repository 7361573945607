/**
 * @var range
 * @type {Number[]}
 * @description 风场展示的纬度范围
 * @author yujie
 */
const range = [-80, 80];

class ParseWind {
  constructor(surfaceData, limit = true) {
    this.data = surfaceData;
    const {
      width, height,
    } = this.data;
    const [latmax, latmin] = [90, -90];
    // 北纬不计算风场的范围
    this.northRange = limit ? Math.floor((range[0] - latmin) / Math.abs(latmin) * height / 2 * width) * 4 : 0;
    // 南纬不计算风场的范围
    this.southRange = limit ? Math.floor((latmax - range[1]) / latmax * height / 2 * width) * 4 : 0;
  }

  getData() {
    const windv = this.setWindU();
    const windu = this.setWindV();
    return [windv, windu];
  }

  setWindU() {
    const surfaceData = this.data;
    const canvas = document.createElement('canvas');
    canvas.width = surfaceData.width;
    canvas.height = surfaceData.height;
    const ctx = canvas.getContext('2d');
    const windUObj = {
      data: [],
      header: {
        dx: surfaceData.solutionX,
        dy: surfaceData.solutionY,
        la1: surfaceData.latmin ?? 90,
        la2: surfaceData.latmax ?? -90,
        lo1: surfaceData.lonmin ?? 0,
        lo2: surfaceData.lonmax ?? 360,
        nx: surfaceData.width,
        ny: surfaceData.height,
        parameterCategory: 2,
        parameterNumber: 2,
        parameterUnit: 'm.s-1',
        refTime: '2017-02-01 23:00:00',
      },
    };
    ctx.drawImage(surfaceData.image, surfaceData.origionxu, surfaceData.origionyu, surfaceData.width, surfaceData.height, 0, 0, surfaceData.width, surfaceData.height);
    const umin = surfaceData.umin;
    const umax = surfaceData.umax;
    const imageData = ctx.getImageData(0, 0, surfaceData.width, surfaceData.height);
    const data = imageData.data;
    const length = data.length;
    for (let i = surfaceData.levelu; i < length; i += 4) {
      if (i <= this.northRange || i >= length - this.southRange || data[i + 4 - surfaceData.levelu] === 0) {
        windUObj.data.push(0.0);
      } else {
        windUObj.data.push(this.reverse(data[i], umin, umax));
      }
    }
    return windUObj;
  }

  setWindV() {
    const surfaceData = this.data;
    const canvas = document.createElement('canvas');
    canvas.width = surfaceData.width;
    canvas.height = surfaceData.height;
    const ctx = canvas.getContext('2d');
    const windVObj = {
      data: [],
      header: {
        dx: surfaceData.solutionX,
        dy: surfaceData.solutionY,
        la1: surfaceData.latmin ?? 90,
        la2: surfaceData.latmax ?? -90,
        lo1: surfaceData.lonmin ?? 0,
        lo2: surfaceData.lonmax ?? 360,
        nx: surfaceData.width,
        ny: surfaceData.height,
        parameterCategory: 2,
        parameterNumber: 3,
        parameterUnit: 'm.s-1',
        refTime: '2017-02-01 23:00:00',
      },
    };
    ctx.drawImage(surfaceData.image, surfaceData.origionxv, surfaceData.origionyv, surfaceData.width, surfaceData.height, 0, 0, surfaceData.width, surfaceData.height);
    const vmin = surfaceData.vmin;
    const vmax = surfaceData.vmax;
    const imageData = ctx.getImageData(0, 0, surfaceData.width, surfaceData.height);
    const data = imageData.data;
    const length = data.length;
    for (let i = surfaceData.levelv; i < length; i += 4) {
      if (i <= this.northRange || i >= length - this.southRange || data[i + 4 - surfaceData.levelv] === 0) {
        windVObj.data.push(0.0);
      } else {
        windVObj.data.push(this.reverse(data[i], vmin, vmax));
      }
    }
    return windVObj;
  }

  reverse(data, min, max) {
    return (min + data * (max - min) / 255);
  }
}

export default ParseWind;
