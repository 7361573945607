import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  responseType: 'blob',
});

const httpCY = axios.create({
  baseURL: '',
  responseType: 'blob',
});

/**
 * @description 根据path获取图片的二进制流
 * @param {String} path 路径标识
 * @param callback
 * @example image:wgrd10m:2021031812:2021031813.webp
 * @returns {Promise}
 * @author yujie
 */
export const getImage = (path, callback = () => {}) => http.get('/file/get', {
  params: { path },
  cancelToken: new axios.CancelToken(callback),
}).then(({ status, data }) => {
  if (status !== 200) return Promise.reject('fail');
  return window.URL.createObjectURL(data);
}).catch(err => {
  // console.log(err)
});



export const getPrecipImage = (url, callback = () => {}) => httpCY.get(url, {
  cancelToken: new axios.CancelToken(callback),
}).then(({ status, data }) => {
  if (status !== 200) return Promise.reject('fail');
  return window.URL.createObjectURL(data);
}).catch(err => {
  // console.log(err)
});
