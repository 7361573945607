import L from 'leaflet';
import { CanvasLayer } from './leaflet.canvasLayer';

/**
 * 等值线绘制
 */
export const Isoline = CanvasLayer.extend({

  options: {
    showLevel: 4, // 文字的显示级别idw.js
    stroke: true,
    color: '#333',
    weight: 0.8,
    opacity: 1,
    lineCap: 'round',
    lineJoin: 'round',
    fill: false,
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, PingFang SC, Microsoft YaHei, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif',
    // fontFamily: '微软雅黑',
    fontColor: '#000',
    fontStrokeSize: 2,
    fontStrokeColor: '#fff',
    factorCode: 'slp',
    width: 22,
    textOffset: 10,
    len: 20,
  },

  initialize(options = {}, config) {
    CanvasLayer.prototype.initialize.call(this, options);
    this.cfg = {
      lat: '0',
      lng: '1',
      value: '2',
      data: [],
      hlData: [],
      ...config,
    };
    this._data = this.cfg.data;
    // 高低压数据源
    this._hlData = this.cfg.hlData;
    // 解决画两次问题
    this._drawFlag = false;
    this._firstData = {};
  },

  setData(data, hlData) {
    // -- custom data set
    this._data = data || [];
    this._hlData = hlData || [];
    this.needRedraw(); // -- call to drawLayer
  },

  onLayerDidMount() {
  },

  onLayerWillUnmount() {
  },

  onDrawLayer(info) {
    const devicePixelRatio = window.devicePixelRatio || 1;
    const canvas = info.canvas;
    const width = canvas.width;
    const height = canvas.height;
    const ctx = info.canvas.getContext('2d');
    const backingStoreRatio = ctx.webkitBackingStorePixelRatio || 1;
    const ratio = devicePixelRatio / backingStoreRatio;
    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;
    canvas.width = width * ratio;
    canvas.height = height * ratio;
    const map = info.layer._map;
    const zoom = map.getZoom();
    let data = this._data;
    let hlData = this._hlData;
    data = this.onDataHandler(data, map);
    hlData = this.onDataHandler(hlData, map, 'HL');
    if (this._drawFlag) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      this._drawContour(data, ctx, map, zoom, ratio, canvas);
      if (zoom > 2) {
        this._drawHL(hlData, ctx, map, ratio);
      }
    }
    this._drawFlag = true;
  },

  onDataHandler(data, map, type) {
    // 根据地图移动同时对初始经纬度的点进行计算 然后画图
    const minLon = map.getBounds().getWest();
    const maxLon = map.getBounds().getEast();
    const n = Math.floor(Math.max(Math.abs(minLon), Math.abs(maxLon)) / 360);
    if (maxLon >= 540) {
      // 目前平移往右  Math.ceil() 向上   Math.floor();向下
      // 原始数据进行平移
      if (type === 'HL') {
        return data.map((item) => this._translateDataRightOfHL(item, n));
      }
      return data.map((item) => this._translateDataRight(item, n));
    }
    if (minLon <= -540) {
      if (type === 'HL') {
        return data.map((item) => this._translateDataLeftOfHL(item, n));
      }
      return data.map((item) => this._translateDataLeft(item, n));
    }

    return data;
  },

  _drawContour(data, ctx, map, zoom, radio) {
    let text;
    for (let i = 0, len = data.length; i < len; i++) {
      const { points, lPoints, rPoints } = this.getPoints(map, data[i]);
      text = data[i][0][this.cfg.value];
      // 画三张图 -540，540 实现无线拖拽
      this._drawLine(ctx, points, radio, text, zoom);
      this._drawLine(ctx, lPoints, radio, text, zoom);
      this._drawLine(ctx, rPoints, radio, text, zoom);
    }
  },

  _drawPoints(ctx, point, radio, color, lineWidth, r, text) {
    ctx.beginPath();
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.fillText(text, point.x * radio, point.y * radio);
    // (x,y,半径,开始角度，结束角度)，通过这个可以绘制出曲线
    ctx.arc(point.x * radio, point.y * radio, r, 0, 2 * Math.PI);
    ctx.stroke();
    ctx.fill();
  },

  _translateDataLeft(data, n) {
    return data.map((item) => {
      const lan = L.latLng(item[this.cfg.lat], Number(item[this.cfg.lng]) - 360 * n);
      return [lan.lat, lan.lng, item[2]];
    });
  },

  _translateDataRight(data, n) {
    return data.map((item) => {
      const lan = L.latLng(item[this.cfg.lat], Number(item[this.cfg.lng]) + 360 * n);
      return [lan.lat, lan.lng, item[2]];
    });
  },

  // 中心气压偏移

  _translateDataLeftOfHL(data, n) {
    const lan = L.latLng(data[this.cfg.lat], Number(data[this.cfg.lng]) - 360 * n);
    return [lan.lat, lan.lng, data[2], data[3]];
  },

  _translateDataRightOfHL(data, n) {
    const lan = L.latLng(data[this.cfg.lat], Number(data[this.cfg.lng]) + 360 * n);
    return [lan.lat, lan.lng, data[2], data[3]];
  },

  // 后续低压高压中心点画 H L
  _drawHL(data, ctx, map, radio) {
    let latlng; let point; let lpoint; let rpoint; let ishl; let value;
    ctx.save();
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = 'normal normal 18px Microsoft YaHei';
    ctx.fillStyle = this.options.fontColor;
    ctx.strokeStyle = this.options.fontStrokeColor;
    ctx.lineWidth = this.options.fontStrokeSize;
    for (let i = 0, len = data.length; i < len; i++) {
      latlng = L.latLng(data[i][0], data[i][1]);
      point = map.latLngToContainerPoint(latlng);
      lpoint = map.latLngToContainerPoint(latlng.getSubtract360LatLng());
      rpoint = map.latLngToContainerPoint(latlng.getAdd360LatLng());
      ishl = data[i][2];
      value = data[i][3];
      this._drawHLText(ctx, point, ishl, value, radio);
      this._drawHLText(ctx, lpoint, ishl, value, radio);
      this._drawHLText(ctx, rpoint, ishl, value, radio);
    }
    ctx.restore();
  },

  getPoints(map, data) {
    const points = [];
    const lPoints = [];
    const rPoints = [];
    for (let i = 0, len = data.length; i < len; i++) {
      const point = L.latLng(data[i][this.cfg.lat], data[i][this.cfg.lng]);
      const lPoint = L.latLng(data[i][this.cfg.lat], Number(data[i][this.cfg.lng]) - 360);
      const rPoint = L.latLng(data[i][this.cfg.lat], Number(data[i][this.cfg.lng]) + 360);
      points[i] = map.latLngToContainerPoint(point);
      lPoints[i] = map.latLngToContainerPoint(lPoint);
      rPoints[i] = map.latLngToContainerPoint(rPoint);
    }
    return { points, lPoints, rPoints };
  },

  _drawLine(ctx, points, radio, text, zoom) {
    let p;
    ctx.save();
    ctx.beginPath();
    for (let i = 0, len = points.length; i < len - 2; i++) {
      p = points[i];
      if (i === 0) {
        ctx.moveTo(p.x * radio, p.y * radio);
      } else {
        const xc = (points[i].x * radio + points[i + 1].x * radio) / 2;
        const yc = (points[i].y * radio + points[i + 1].y * radio) / 2;
        ctx.quadraticCurveTo(points[i].x * radio, points[i].y * radio, xc, yc);
      }
    }
    ctx.quadraticCurveTo(
      points[points.length - 2].x * radio,
      points[points.length - 2].y * radio,
      points[points.length - 1].x * radio,
      points[points.length - 1].y * radio,
    );
    this._fillStroke(ctx, radio);
    ctx.restore();
    if (zoom >= 4) {
      this._drawCurveLine(ctx, points, radio, text, zoom);
    }
    // drawPoints(ctx, points)
  },

  _drawCurveLine(ctx, points, radio, text, zoom) {
    ctx.lineWidth = 20;
    ctx.lineCap = 'round';
    // ctx.strokeStyle = this.options.color;
    ctx.strokeStyle = '#FFF';
    ctx.fillStyle = this.options.color;
    ctx.font = 'bold 20px Arial';
    ctx.textBaseline = 'middle';
    const offset = zoom > 6 ? 50 : 30;
    for (let i = 2; i < points.length - 2; i += offset) {
      const xc = (points[i].x * radio + points[i + 1].x * radio) / 2;
      const yc = (points[i].y * radio + points[i + 1].y * radio) / 2;
      const angle = Math.atan2(points[i + 1].y * radio - points[i].y * radio, points[i + 1].x * radio - points[i].x * radio);
      let angle_1;
      if ((angle >= 0 && angle <= Math.PI * 0.5) || (angle >= -Math.PI * 0.5 && angle <= 0)) {
        angle_1 = angle;
      } else if (angle > Math.PI * 0.5 && angle <= Math.PI) {
        angle_1 = angle - Math.PI;
      } else if (angle > -Math.PI && angle <= -Math.PI * 0.5) {
        angle_1 = angle + Math.PI;
      }
      ctx.save();
      ctx.translate(xc, yc);
      ctx.rotate(angle_1);
      const textWidth = ctx.measureText(text).width;
      ctx.beginPath();
      ctx.moveTo(-textWidth / 2, 0);
      ctx.lineTo(textWidth / 2, 0);
      ctx.stroke();
      ctx.fillText(text, -textWidth / 2, 2);
      ctx.restore();
    }
  },

  _drawFontSize(zoom) {
    switch (zoom) {
      case 2:
        return '10px';
      case 3:
        return '12px';
      case 4:
        return '14px';
      default:
        return this.options.fontSize;
    }
  },

  _drawHLText(ctx, pt, ishl, value, radio) {
    const hl = ishl === 1 ? (this.options.factorCode.startsWith('t') ? 'W' : 'H') : (this.options.factorCode.startsWith('t') ? 'C' : 'L');
    ctx.strokeText(hl, pt.x * radio, pt.y * radio);
    ctx.fillText(hl, pt.x * radio, pt.y * radio);
    ctx.strokeText(value, pt.x * radio, (pt.y * radio) + 20);
    ctx.fillText(value, pt.x * radio, (pt.y * radio) + 20);
  },

  _fillStroke(ctx, radio) {
    const options = this.options;
    ctx.globalAlpha = options.opacity;
    ctx.lineWidth = options.weight * radio;
    ctx.strokeStyle = options.color;
    ctx.lineCap = options.lineCap;
    ctx.lineJoin = options.lineJoin;
    // 启用抗锯齿
    // ctx.fill()
    // ctx.imageSmoothingEnabled = true;
    // ctx.imageSmoothingQuality = 'high';
    // ctx.globalCompositeOperation="xor"
    // ctx.clearRect(0, 0, canvas.width,canvas.height)
    ctx.stroke();
  },
});
