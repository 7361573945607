<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">气象要素</span>
    </div>
    <div class="factor-div flex-col">
      <van-grid :border="false" :column-num="4" style="padding: 10px 5px">
        <van-grid-item v-for="(item, index) in currentData" :key="'factor'+index">
          <div class="flex-col justify-between align-center">
            <van-badge color="rgba(87, 127, 255, 1)" style="font-size: 14px">
              <div class="flex-col" :class="{change_factor_item_class : item.id === factor}"
                   @click="changeFactorClick(item)">
                <img :src="require(`@/assets/images/app_icon/${item.appIcon}.png`)"
                     style="width: 70px;height: 70px;border-radius: 15px">
              </div>
              <template #content v-if="item.id === factor">
                <van-icon name="success" class="checked_class"/>
              </template>
            </van-badge>
            <div class="flex-col">
              <span class="text_">{{item.text}}</span>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from "@/config";
import { modeFactorMap, factorMap, mapForCn,appFactorMap,dust_factorMap, dustMetrics } from "@/config/MapConfigT4";
import MenuLayerSelect from './MenuLayerSelect.vue'
import Bus from "@/bus/index";

export default {
  components: {
    MenuLayerSelect
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    modelType: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false
    },
    factor: {
      type: String,
      required: true,
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "t2",
    },
  },
  watch: {
    metrics() {
      this.initData();
    },
  },
  data() {
    return {
      input: '',
      currentData: [],
      appFactorMap: appFactorMap,
    }
  },
  computed: {
    ...mapState(['isMobile','t1Mode']),
    ...mapState("map", ["layerType","t2Mode"]),
    popupShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
  },
  created() {
    this.initData();
  },
  mounted() {

  },
  methods: {
    ...mapMutations('map',["setPopupShow"]),
    initData() {
      const metricData = [];
      const productData = [];
      // 根据要素来展示模式选择
      this.metrics.forEach((metric) => {
        if (metric.id !== 'satellite' && metric.id !== 'typhoon'){
          if (metric.children && metric.children.length > 0) {
            metric.children.forEach(v => {
              metricData.push(v);
            })
          } else {
            metricData.push(metric);
          }
        } else {
          productData.push(metric);
        }
      })
      this.currentData = metricData;
      this.$emit("initProductData", productData);
    },
    getParent(metric) {
      return metric.subId && !this.isTyp && !this.isSatel
        ? metric.subId.includes(this.factor)
        : false;
    },
    changeFactorClick(metric) {
      console.log('factor===>',this.factor,'metric===>',metric.id)
      this.changeFactor(metric);
      this.$emit("closePop");
    },
    changeFactor(metric) {
      this.setPopupShow(false)
      Bus.$emit('removePopup')
      this.$emit("changeFactorTip", metric.text);
      const factor =
        sessionStorage.getItem("tmode") === "t1"
          ? this.$store.state["factor"]
          : this.$store.state["map"]["factor"];
      console.log('factor==>',factor)
      this.$sensors.track("Jump_volume", {
        from_factor: mapForCn[factor],
        from_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
        from_space_type: this.$route.name === "earth" ? '三维' : '二维',
        to_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
        to_factor: metric.text,
        to_space_type: this.$route.name === "earth" ? '三维' : '二维',
      });
      //如果是页面则跳转
      if (metric.isPage) {
        this.$sensors.track("CurrentPage", {
          page_type: metric.routerName,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
        });
        //如果是沙城要素记录
        this.$sensors.track("CurrentPage", {
          page_type: metric.text,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
        });

        if(this.$route.name === "earth" && metric.routerName === "dust") {
          this.$emit("change", metric);
          return;
        }

        if (metric.id === 'precp') {
          console.log(this.$route.name,"log name 314");
          if (this.$route.name !== "weather") {
            this.$router.push({ name: metric.routerName });
            this.$store.commit("setFactor", metric.id);
          }else {
            this.$emit("change", metric);
          }
          return;
        }

        this.$router.push({ name: metric.routerName });
        return;
      }

      //如果是台风或明日卫星切回来则做对应记录
      if (this.isTyp || this.isSatel) {
        if (sessionStorage.getItem("tmode") === "t1") {
          let mapType = modeFactorMap[this.$store.state.t1Mode];
          if (!mapType.includes(metric.id)) {
            let str = "";
            for (let key in modeFactorMap) {
              let item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (str.includes("天机1")) {
              this.$store.commit("setMode", str);
              this.$store.commit("setFactor", metric.id);
              this.$router.push({ name: "weather" });
              return;
            } else {
              this.$store.commit("map/setMode", str);
              this.$store.commit("map/setFactor", metric.id);
              this.$router.push({ name: "weather_hd" });
              return;
            }
          } else {
            this.$store.commit("setFactor", metric.id);
            this.$router.push({ name: "weather" });
            return;
          }
        }
        //t2
        else {
          let mapType = modeFactorMap[this.$store.state["map"]["t2Mode"]];
          if (!mapType.includes(metric.id)) {
            let str = "";
            for (let key in modeFactorMap) {
              let item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (!str.includes("天机1")) {
              this.$store.commit("map/setMode", str);
              this.$store.commit("map/setFactor", metric.id);
              this.$router.push({ name: "weather_hd" });
              return;
            } else {
              this.$store.commit("setMode", str);
              this.$store.commit("setFactor", metric.id);
              this.$router.push({ name: "weather" });
              return;
            }
          } else {
            this.$store.commit("map/setFactor", metric.id);
            this.$router.push({ name: "weather_hd" });
            return;
          }
        }
      }

      const mapType =
        sessionStorage.getItem("tmode") === "t1"
          ? modeFactorMap[this.$store.state.t1Mode]
          : modeFactorMap[this.$store.state["map"]["t2Mode"]];
      //如果当前模式不存在该要素则找最近得模式进行切换展示
      // 如果当前是T1，并且路径是weather，切换到沙尘，沙尘有T1，但是需要跳转到weather_hd下,再weather_hd下展示
      // if (!mapType.includes(metric.id)) {
      console.log('this.$route.name==>',this.$route.name,'sessionStorage.getItem("tmode")==>',
        sessionStorage.getItem("tmode"))
      const modeType = sessionStorage.getItem("tmode");
      // 1、判断当前模式下是否存在此要素
      if (mapType.includes(metric.id)) {
        // 如果存在此要素,判断是否是沙尘，如果是沙尘，则要切换到weather_hd路径下，如果不是，判断当前路径是不是
        // weather，如果是则直接切换要素

        if(this.$route.name === "earth"){
          this.$emit("change", metric);
          return
        } else if (dust_factorMap[metric.id]) {
          // 如果当前选择的是t1
          if(modeType === 't1') {
            // 如果当前模式是weather,则切换到weather_hd
            if (this.$route.name === "weather" ){
              this.$store.commit("map/setMode", this.$store.state.t1Mode);
              this.$store.commit("map/setFactor", metric.id);
              if (this.$route.name !== "weather_hd" ){
                this.$router.push({ name: "weather_hd" });
                return;
              }
            }else {
              // 如果不是weather,是原来的weather_hd，则直接切换要素即可
              this.$store.commit("map/setFactor", metric.id);
            }
          } else {
            // 如果当前选择的是t2
            this.$store.commit("map/setFactor", metric.id);
          }
        } else {
          // 如果不是沙尘
          if(modeType === 't1') {
            // 直接切换要素
            this.$store.commit("setMode", this.$store.state.t1Mode);
            this.$store.commit("setFactor", metric.id);
            if (this.$route.name !== "weather" ){
              this.$router.push({ name: "weather" });
              return;
            }
          } else {
            // 直接切换要素
            if (!dust_factorMap[metric.id]){
              const mode = this.$store.state["map"]["t2Mode"]
              this.$store.commit("map/setMode", mode.replace(1,2));
            }
            this.$store.commit("map/setFactor", metric.id);
          }
        }
      } else {
        // 如果当前模式下不存在所选的要素，则切换到当前要素所在模式的第一个
        let str = "";
        for (let key in modeFactorMap) {
          let item = modeFactorMap[key];
          if (item.includes(metric.id) && !str) {
            str = key;
          }
        }

        console.log('str===>',str)
        // 判断是否是沙尘
        if (dust_factorMap[metric.id]) {
          if(this.$route.name === "earth"){
            this.$emit("change", metric);
            return
          }

          sessionStorage.setItem('tmode','t2');
          // 如果是沙尘，则跳转到weather_hd页面
          this.$store.commit("map/setMode", str);
          this.$store.commit("map/setFactor", metric.id);
          if (this.$route.name !== "weather_hd" && this.$route.name !== "earth"){
            this.$router.push({ name: "weather_hd" });
            return;
          }
          // 触发modelName文字描述
          this.changeModeInfo(str);
        } else {
          //如果不是沙尘,则判断是否是天机1
          if (str.includes("天机1")) {
            // 如果是天机1
            sessionStorage.setItem('tmode','t1');
            this.$store.commit("setMode", str);
            this.$store.commit("setFactor", metric.id);
            if (this.$route.name !== "weather" ){
              this.$router.push({ name: "weather" });
              return;
            }
            // 触发modelName文字描述
            this.changeModeInfo(str);
          } else {
            sessionStorage.setItem('tmode','t2');
            this.$store.commit("map/setMode", str);
            this.$store.commit("map/setFactor", metric.id);
            // 如果上个要素是沙尘，点击过来，是不会跳转页面的，所以不会初始化mode的
            if (this.$route.name !== "weather_hd" && this.$route.name !== "earth"){
              this.$router.push({ name: "weather_hd" });
              return;
            }
            // 触发modelName文字描述
            this.changeModeInfo(str);
          }
        }
      }
      if (!factorMap[metric.id]) {
        this.$message("当前要素正在开发中，请耐心等待。");
        return;
      }

      //其余情况正常切换要素
      this.$sensors.track("CurrentFactor", {
        info: `${metric.text}`,
        space_type: this.$route.name === "earth" ? '三维' : '二维',
        mode:
          this.$route.name === "weather_hd" || this.$route.name === "earth"
            ? this.t2Mode
            : this.t1Mode,
        page_type:
          this.$route.name === "weather_hd"
            ? "天机2"
            : this.$route.name === "earth"
              ? "三维"
              : "天机1",
      });
      if (this.$route.name === "weather_hd" && sessionStorage.getItem("tmode") === "t1" && !Reflect.has(dust_factorMap,metric.id)) {
        this.$store.commit("setFactor", metric.id);
        this.$router.push({name: "weather"});
        return;
      }else if(this.$route.name === "weather" && sessionStorage.getItem("tmode") === "t1" && Reflect.has(dust_factorMap,metric.id)){
        this.$store.commit("setFactor", metric.id);
        this.$router.push({name: "weather_hd"});
        return;
      }else if(this.$route.name === "weather" && (sessionStorage.getItem("tmode") === null || sessionStorage.getItem("tmode") === "t2")){
       const mode = this.$store.state["map"]["t2Mode"]
        this.$store.commit("map/setMode", mode);
        this.$store.commit("map/setFactor", metric.id);
        this.$router.push({name: "weather_hd"});
        return;
      }
      // 如果当前要素从沙尘变换过来的，则需要切换到weahter_hd页面
      this.$emit("change", metric);
    },

    changeModeInfo(modeName) {
      this.$emit("changeModeName", modeName);
      this.$emit("changeModeItem", modeName);
    },

    changeDust(metric) {
      const factor = this.$store.state["dust"]["factor"];
      console.log('aaaaaaaaaa',factor)
      if (metric.children && metric.children.length) {
        if (metric.subId.includes(factor)) return;
        metric = metric.children[0];
      }
      if (dust_factorMap[metric.id] && dust_factorMap[this.factor]) {
        this.$sensors.track("CurrentFactor", {
          info: `${metric.text}`,
          space_type: this.$route.name === "earth" ? "三维" : "二维",
          mode: this.t2Mode,
          page_type: "沙尘",
        });
        const factorText = dustMetrics.find(item => item.id === factor).text;
        this.$sensors.track("Jump_volume", {
          from_factor: factorText,
          from_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
          from_space_type: this.$route.name === "earth" ? '三维' : '二维',
          to_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
          to_factor: metric.text,
          to_space_type: this.$route.name === "earth" ? '三维' : '二维',
        });
        this.$emit("change", metric);
        return;
      }
      // this.setMode(TIANJI2_MERGE);
      if (metric.isPage) {
        this.$sensors.track("CurrentPage", {
          page_type: metric.text,
          space_type: this.$route.name === "earth" ? "三维" : "二维",
        });
        this.$router.push({ name: metric.routerName });
        return;
      }
      if (metric.children && metric.children.length) {
        return;
      }

      //切换的要素不是沙尘要素跳转到天机1/天机2
      console.log('dust_factorMap[metric.id]==>',dust_factorMap[metric.id])
      console.log('dust_factorMap[this.factor]==>',dust_factorMap[this.factor])
      if (!dust_factorMap[metric.id] || !dust_factorMap[this.factor]) {
        if (factorMap[metric.id]) {
          //t2
          let mapType = modeFactorMap[this.t2Mode];

          if (["tmp2m", "t2mz"].includes(metric.id)) {
            metric.id = !this.isT2Mode ? "tmp2m" : "t2mz";
          }
          if (!mapType.includes(metric.id)) {

            let str = "";
            for (let key in modeFactorMap) {
              let item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            console.log('str====>',str)
            if (!str.includes("天机1")) {
              this.$store.commit("map/setMode", str);
              this.$store.commit("map/setFactor", metric.id);
              this.$router.push({ name: "weather_hd" });
            } else {
              this.$store.commit("setMode", str);
              this.$store.commit("setFactor", metric.id);
              this.$router.push({ name: "weather" });
            }
          } else {
            console.log("includes", mapType.includes(metric.id), mapType);
            this.$store.commit("map/setFactor", metric.id);
            this.$router.push({ name: "weather_hd" });
          }

          this.$sensors.track("CurrentFactor", {
            info: `${metric.text}`,
            mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
            space_type: this.$route.name === "earth" ? "三维" : "二维",
            page_type: sessionStorage.getItem("tmode") === "t1" ? "天机1" : "天机2",
          });

          return;
        }
        this.$message("当前要素正在开发中，请耐心等待。");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.factor-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .checked_class {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .change_factor_item_class {
    border: 2px solid rgba(87, 127, 255, 1);
    border-radius: 17px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}

::v-deep {
  .van-grid-item__content {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 4px;
  }
  .van-badge--fixed {
    top: 5px;
    right: 5px;
  }
  .van-badge {
    //border: 0;
    border: 1px solid #FFFFFF !important;
  }
}
</style>
