var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"height":"30px","width":"100%","margin-left":"20px","margin-top":"22px"}},[_c('div',{staticClass:"timeline"},[_c('div',{staticClass:"target",style:({
        left: `${_vm.indexFlag * (100 / (_vm.timeType.timeLength - 1)) + 0.2}%`,
      })},[_c('div',{staticClass:"box"}),_vm._v(" "),(_vm.date)?_c('div',{staticClass:"datetime",class:{ last_tip: _vm.isLast || _vm.indexFlag === _vm.timeType.timeLength - 1 }},[_vm._v("\n        "+_vm._s(_vm.date)+"\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"time_wrap"},_vm._l((_vm.timeType.timeLength),function(i){return _c('div',{key:i,staticClass:"time_item",class:{
          active: i === _vm.indexFlag + 1,
          animation: i <= _vm.indexFlag,
        },style:(_vm.canClick(i, _vm.timeType.type))},[_c('div',{staticClass:"clickDiv",style:(_vm.canClick(i, _vm.timeType.type)),on:{"click":function($event){return _vm.timeClick(i)}}}),_vm._v(" "),_c('div',{staticClass:"circle",class:{
            animation: i <= _vm.indexFlag,
          },style:(_vm.canClick(i, _vm.timeType.type))}),_vm._v(" "),(i % 2 || _vm.timeType.type > 1)?_c('div',{staticClass:"word satellite",style:(_vm.canClick(i, _vm.timeType.type, 'word'))},[_vm._v("\n          "+_vm._s(_vm._f("getTime")((i - 1),_vm.timeType.type))+"\n        ")]):_vm._e()])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }