<template>
  <div class="basetime_select">
    <div class="label">
      起报
    </div>
    <div class="select_wrap">
      <el-select v-model="value" placeholder="起报时间" :style="{ width: isMobile ? '140px' : '164px',}">
        <el-option
          v-for="(item, index) in baseTime"
          :key="index"
          :value="item"
          :label="formatTime(item)"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';

export default {
  filters: {
    formatTime(value) {
      const [time, model] = value.split(' ');
      return `${moment(time).format('YYYY.MM.DD HH时')} ${model}`;
    },
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapState('map', ['currentBaseTime', 'factor']),
    ...mapGetters('map', ['baseTime', 'currentMode']),
    value: {
      get() {
        return this.currentBaseTime;
      },
      set(value) {
        this.setCurrentBaseTime(value);
        this.$emit('updateChartData');
      },
    },
  },
  methods: {
    ...mapMutations('map', ['setCurrentBaseTime']),
    formatTime(value) {
      const [time, model] = value.split(' ');
      return `${moment(time).format('YYYY.MM.DD HH时')} ${this.currentMode ? '' : model}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  height: 22px;
  padding: 0 5px 0 15px;
  display: flex;
  align-items: center;
  border-radius: 21px;

  ::v-deep {
    .el-input__inner {
      background: #FFFFFF;
      border-radius: 4px;
      border: 1px solid #E5E5E5;
    }
  }

  .label {
    font-size: 13px;
    // font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif;
    // text-shadow: 0px 0px 4px black;
    margin-right: 5px;
    width: 25px;
    font-size: 12px;
    color: #666;
    margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 20px;
      }
    }
  }
}
</style>
