var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend_loop",class:{
    mobile: _vm.mobile,
    left: _vm.viewHeight >= 800,
    right: _vm.viewHeight < 800,
  }},[_vm._l((_vm.name),function(legend,key){return _c('div',{key:key,staticClass:"legend"},[(!_vm.isMobile)?_c('div',{staticClass:"color_wrap"},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-checkbox',{staticClass:"checkbox",attrs:{"disabled":legend.disabled,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":(e) => _vm.switchChange(e, key)},model:{value:(legend.checked),callback:function ($$v) {_vm.$set(legend, "checked", $$v)},expression:"legend.checked"}}),_vm._v(" "),_c('div',{style:({
            width: '8px', height: '17px', margin: '3px 5px 0px 8px', background: `${legend.color}`,
          })}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(legend.name))])],1),_vm._v(" "),_c('div',{staticStyle:{"margin-left":"5px"}},[_c('el-checkbox',{staticClass:"checkbox",attrs:{"disabled":legend.disabled,"active-color":"#13ce66","inactive-color":"#ff4949"},on:{"change":(e) => _vm.switchWindChange(e, key)},model:{value:(legend.windChecked),callback:function ($$v) {_vm.$set(legend, "windChecked", $$v)},expression:"legend.windChecked"}}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v("风圈")])],1)]):_c('div',{staticClass:"color_wrap",style:({
        background: _vm.getColor(legend),
      })},_vm._l((legend.colors),function(item,index){return _c('div',{key:index,staticClass:"legend_single color"},[_vm._v("\n        "+_vm._s(_vm._f("formatValue")(legend.values[index],index, legend.colors.length - 1, _vm.mobile))+"\n      ")])}),0)])}),_vm._v(" "),_vm._l((_vm.legendName),function(legend,i){return _c('div',{key:i,staticClass:"legend"},[(!_vm.isMobile)?_c('div',{staticClass:"color_wrap"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require(`@/assets/images/${legend.icon}.png`)}}),_vm._v(" "),_c('span',{staticClass:"text"},[_vm._v(_vm._s(legend.name))])])]):_c('div',{staticClass:"color_wrap",style:({
        background: _vm.getColor(legend),
      })},_vm._l((legend.colors),function(item,index){return _c('div',{key:index,staticClass:"legend_single color"},[_vm._v("\n        "+_vm._s(_vm._f("formatValue")(legend.values[index],index, legend.colors.length - 1, _vm.mobile))+"\n      ")])}),0)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }