export function gesture(element) {
  const start = (point, context) => {
    context.startX = point.clientX;
    context.startY = point.clientY;
    context.moves = [];
    element.dispatchEvent(new CustomEvent('start', {
      detail: {
        clientX: point.clientX,
        clientY: point.clientY,
      },
    }));
    context.tap = true;
    context.pan = false;
    context.press = false;
    context.flick = false;
    context.timer = setTimeout(() => {
      if (context.pan) {
        return;
      }
      context.tap = false;
      context.pan = false;
      context.press = true;
      element.dispatchEvent(new CustomEvent('pressstart', {
        detail: {
          clientX: point.clientX,
          clientY: point.clientY,
        },
      }));
    }, 500);
  };

  const move = (point, context) => {
    const dx = point.clientX - context.startX;
    const dy = point.clientY - context.startY;
    if (dx ** 2 + dy ** 2 > 100 && !context.pan) {
      context.tap = false;
      context.pan = true;
      context.press = false;
    }
    if (context.pan) {
      context.moves.push({
        dx, dy, t: Date.now(),
      });
      context.moves = context.moves.filter((record) => Date.now() - record.t < 300);
      element.dispatchEvent(new CustomEvent('panmove', {
        detail: {
          startX: context.startX,
          startY: context.startY,
          clientX: point.clientX,
          clientY: point.clientY,
        },
      }));
    }
  };

  const end = (point, context) => {
    const threshold = 1.5;
    let speed = 0;
    if (context.tap) {
      element.dispatchEvent(new CustomEvent('tapend', {
        detail: {
          clientX: point.clientX,
          clientY: point.clientY,
        },
      }));
    }
    if (context.pan) {
      const dx = point.clientX - context.startX;
      const dy = point.clientY - context.startY;
      const record = context.moves[0];
      if (record) {
        speed = Math.sqrt((dx - record.dx) ** 2 + (dy - record.dy) ** 2) / (Date.now() - record.t);
      }
      if (speed > threshold) {
        element.dispatchEvent(new CustomEvent('flick', {
          detail: {
            startX: context.startX,
            startY: context.startY,
            clientX: point.clientX,
            clientY: point.clientY,
            speed,
            isFlick: true,
          },
        }));
      }
      element.dispatchEvent(new CustomEvent('panend', {
        detail: {
          startX: context.startX,
          startY: context.startY,
          clientX: point.clientX,
          clientY: point.clientY,
          speed,
          isFlick: speed > threshold,
        },
      }));
    }
    if (context.press) {
      element.dispatchEvent(new CustomEvent('pressend', {
        detail: {
          clientX: point.clientX,
          clientY: point.clientY,
        },
      }));
    }
    element.dispatchEvent(new CustomEvent('end', {
      detail: {
        startX: context.startX,
        startY: context.startY,
        clientX: point.clientX,
        clientY: point.clientY,
        speed,
        isFlick: speed > threshold,
      },
    }));
    clearTimeout(context.timer);
  };

  const cancel = (point, context) => {
    element.dispatchEvent(new CustomEvent('cancel', {}));
    clearTimeout(context.timer);
  };

  const context = Object.create(null);
  if (element.ontouchstart === undefined) {
    const MOUSE_SYMBOL = Symbol('mouse');
    element.addEventListener('mousedown', (e) => {
      context[MOUSE_SYMBOL] = Object.create(null);
      start(e, context[MOUSE_SYMBOL]);

      const mousemove = (event) => {
        move(event, context[MOUSE_SYMBOL]);
      };
      const mouseend = (event) => {
        end(event, context[MOUSE_SYMBOL]);
        document.removeEventListener('mousemove', mousemove);
        document.removeEventListener('mouseup', mouseend);
      };
      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', mouseend);
    });
  }

  element.addEventListener('touchstart', (e) => {
    for (const touch of e.changedTouches) {
      context[touch.identifier] = Object.create(null);
      start(touch, context[touch.identifier]);
    }
  });

  element.addEventListener('touchmove', (e) => {
    e.preventDefault();
    for (const touch of e.changedTouches) {
      move(touch, context[touch.identifier]);
    }
  }, {
    passive: false,
  });

  element.addEventListener('touchend', (e) => {
    for (const touch of e.changedTouches) {
      end(touch, context[touch.identifier]);
      delete context[touch.identifier];
    }
  });

  element.addEventListener('touchcancel', (e) => {
    for (const touch of e.changedTouches) {
      cancel(touch, context[touch.identifier]);
      delete context[touch.identifier];
    }
  });
}

export function drag(el, cb) {
  gesture(el);

  const {
    left, top, right, bottom,
  } = el.getBoundingClientRect();
  const clientWidth = document.documentElement.clientWidth;
  const clientHeight = document.documentElement.clientHeight;

  const padding = 16;

  let startX = 0;
  let startY = 0;

  const updatePosition = (e) => {
    const { clientX, clientY } = e.detail;
    // clientX = Math.max(clientX, 10);
    // clientY = Math.max(clientY, 10);
    let x = clientX - e.detail.startX + startX;
    let y = clientY - e.detail.startY + startY;
    if (x + left > clientWidth - padding) {
      x = clientWidth - padding - left;
    }
    if (x + right < padding) {
      x = padding - right;
    }
    if (y + top > clientHeight - padding) {
      y = clientHeight - padding - top;
    }
    if (y + bottom < padding) {
      y = padding - bottom;
    }
    el.style.transform = `translate(${x}px, ${y}px)`;
    return { x, y };
  };

  el.addEventListener('panmove', (e) => {
    if (typeof (cb) === 'function') {
      cb();
    }
    updatePosition(e);
  });

  el.addEventListener('panend', (e) => {
    const { x, y } = updatePosition(e);
    startX = x;
    startY = y;
    e.preventDefault();
  });
}
