<template>
  <div v-if="!isSatel">
    <div class="title_text flex-row">
      <span class="word3">图层展示</span>
    </div>
    <div class="factor-div flex-col">
      <van-grid :border="false" :column-num="3" :gutter="0" :center="false" style="margin-left: 20px">
        <van-grid-item v-for="(item, index) in getLayerData" :key="'layer'+index">
          <div class="flex-row" style="align-items: flex-end">
            <van-switch v-model="item.checked" size="20px" @change="(e) => layerChange(e,item.name)"/>&nbsp;&nbsp;
            <span class="text_">{{item.text}}</span>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { getDataByFactor } from "@/api/weather_hd";

export default {
  components: {
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    modelType: {
      type: String,
      required: true,
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    windy(value) {
      this.layer_type[0].checked = value === "normal";
      localStorage.setItem("windy", value);
    },
    // 监听等压线控制变量变化
    isobar(value) {
      this.layer_type[2].checked = value === "normal";
      localStorage.setItem("isoline-slp", value);
    },
  },
  data() {
    return {
      layer_type: [
        {
          text: "粒子动画",
          name: "windy",
          checked: localStorage.getItem("windy") === "normal",
        },
        {
          text: "城市",
          name: "city",
          checked: localStorage.getItem("city-layer") === "normal",
        },

        {
          text: "等压线",
          name: "isoline",
          checked: localStorage.getItem("isoline-slp") === "normal",
        },

        {
          text: "压缩",
          name: "compress",
          //localStorage.getItem("compress-layer") === "normal"
          checked: localStorage.getItem("compress-layer") === "normal",
        },
      ],
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState("map", ["t2Mode", "layerType"]),
    getLayerType() {
      return this.layerType;
    },
    getLayerData() {
      if (this.isTyp) {
        return this.layer_type.slice(1, 2);
      }
      if (this.modelType === "t1") {
        return this.layer_type.slice(0, 2);
      }
      return this.layer_type;
    },
  },
  beforeCreate() {
    localStorage.setItem("windy", localStorage.getItem("windy") || "none");
    localStorage.setItem("city-layer", localStorage.getItem("city-layer") || "normal");
    localStorage.setItem("compress-layer", localStorage.getItem("compress-layer") || "normal");
    localStorage.setItem("isoline-slp", localStorage.getItem("isoline-slp") || "none");
  },
  created() {
  },
  mounted() {

  },
  methods: {
    ...mapMutations("map", [
      "setMode",
      "setFactor",
      "setLayerType",
      "setWindy",
      "setCity",
      "setHD",
      "setWindData",
      "setIsobar",
    ]),
    ...mapMutations([
      "setHistory",
      "setStatus",
      "setCurrentData",
      "setT1Windy",
      "setT1LayerType",
      "setT1City",
    ]),
    async layerChange(e, name) {
      this.$sensors.track("CurrentLayer", {
        layer_name: name,
        space_type: this.$route.name === "earth" ? "三维" : "二维",
        mode:
          this.$route.name === "weather_hd" || this.$route.name === "earth"
            ? this.t2Mode
            : this.t1Mode,
        page_type:
          this.$route.name === "weather_hd"
            ? "天机2"
            : this.$route.name === "earth"
              ? "三维"
              : "天机1",
      });
      if (name === "windy") {
        if (this.modelType === "t1") {
          // this.setT1Windy(e ? "normal" : "none");
          this.setWindy(e ? "normal" : "none");
        } else {
          if (e) {
            const factorTempKey = this.factor === "w100m " ? "w100m" : "wgrd10m";
            try {
              // 数据不能只差c10km
              const data = await getDataByFactor(factorTempKey, this.currentMode ?? "t2");
              this.setWindData(data);
            } catch (err) {
              console.error(err);
            }
          }
          this.setWindy(e ? "normal" : "none");
          localStorage.setItem("windy", e ? "normal" : "none");
        }
      } else if (name === "city") {
        if (this.modelType === "t1") {
          this.setCity(e ? "normal" : "none");
        } else {
          this.setCity(e ? "normal" : "none");
        }
        localStorage.setItem("city-layer", e ? "normal" : "none");
      } else if (name === "compress") {
        // localStorage.setItem("compress-layer", e ? "normal" : "none");
        this.setHD(e ? "normal" : "none");
      } else if (name === "isoline") {
        // 显示等压线
        this.setIsobar(e ? "normal" : "none");
        localStorage.setItem("isoline-slp", e ? "normal" : "none");
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.factor-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .checked_class {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 18px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}
</style>
