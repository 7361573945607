import axios from 'axios';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_T3,
  responseType: 'blob',
});

/**
 * @description 根据path获取图片的二进制流
 * @example image:wgrd10m:2021031812:2021031813.webp
 * @returns {Promise}
 * @author yujie
 * @param windId
 * @param callback
 */
export const getImage = (windId, callback = () => {}) => http.get('/file/wind/webp', {
  params: { windId },
  cancelToken: new axios.CancelToken(callback),
}).then(({ status, data }) => {
  if (status !== 200) return Promise.reject('fail');
  return window.URL.createObjectURL(data);
});
