<template>
  <div class="tj_time">
    {{ formatTime }}
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    time: {
      type: String,
      default: '',
    },
    utc: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formatTime() {
      if (this.utc) return moment(this.time || undefined).utc().format('YYYY-MM-DD HH:mm:ss');
      return moment(this.time || undefined).format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
</script>

<style lang="scss" scoped>
.tj_time {
  position: absolute;
  width: 300px;
  left: 200px;
  top: 12px;
  z-index: 100000;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  text-shadow: 0 0 2px #000;
  cursor: move;
}
</style>
