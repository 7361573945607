import L from 'leaflet';

if (!L.DomUtil.setTransform) {
  L.DomUtil.setTransform = (el, offset, scale) => {
    const pos = offset || new L.Point(0, 0);

    el.style[L.DomUtil.TRANSFORM] = (L.Browser.ie3d
      ? `translate(${pos.x}px,${pos.y}px)`
      : `translate3d(${pos.x}px,${pos.y}px,0)`)
      + (scale ? ` scale(${scale})` : '');
  };
}

export const CanvasLayer = (L.Layer ? L.Layer : L.Class).extend({
  // -- initialized is called on prototype
  initialize(options) {
    this._map = null;
    this._canvas = null;
    this._frame = null;
    this._delegate = null;
    this.moving = !1;
    this.zooming = !1;
    L.setOptions(this, options);
  },

  delegate(del) {
    this._delegate = del;
    return this;
  },

  needRedraw() {
    if (!this._frame) {
      this._frame = L.Util.requestAnimFrame(this.drawLayer, this);
    }
    return this;
  },

  //-------------------------------------------------------------
  _onLayerDidResize(resizeEvent) {
    this._canvas.width = resizeEvent.newSize.x;
    this._canvas.height = resizeEvent.newSize.y;
  },
  //-------------------------------------------------------------
  _onLayerDidMove() {
    const size = this._map.getSize();
    this._canvas.width = size.x;
    this._canvas.height = size.y;
    const topLeft = this._map.containerPointToLayerPoint([0, 0]);
    L.DomUtil.setPosition(this._canvas, topLeft);
    this.drawLayer();
  },

  _onZoomEnd() {
    this.zooming = !1;
    this.moving || this._onLayerDidMove();
  },
  _onMoveStart() {
    this.moving = !0;
  },
  //-------------------------------------------------------------
  getEvents() {
    const events = {
      resize: this._onLayerDidResize,
      zoom: this._onZoom,
      movestart: this._onMoveStart,
      moveend: this._onMoveEnd,
      zoomend: this._onZoomEnd,
    };
    if (this._map.options.zoomAnimation && L.Browser.any3d) {
      events.zoomanim = this._animateZoom;
    }

    return events;
  },

  _onMoveEnd() {
    this.moving = !1;
    this._onLayerDidMove();
  },

  _onZoom() {
    cancelAnimationFrame(this._frame);
    const e = this._map.getSize();
    this._canvas.getContext('2d').clearRect(0, 0, e.x, e.y);
    this.zooming = !0;
  },

  //-------------------------------------------------------------
  onAdd(map) {
    this._map = map;
    this._canvas = L.DomUtil.create('canvas', 'leaflet-layer');
    this.tiles = {};
    const size = this._map.getSize();
    this._canvas.width = size.x;
    this._canvas.height = size.y;

    const animated = this._map.options.zoomAnimation && L.Browser.any3d;
    L.DomUtil.addClass(this._canvas, `leaflet-zoom-${animated ? 'animated' : 'hide'}`);

    map._panes.overlayPane.appendChild(this._canvas);
    map.on(this.getEvents(), this);

    const del = this._delegate || this;
    del.onLayerDidMount && del.onLayerDidMount(); // -- callback
    this.needRedraw();

    const self = this;
    setTimeout(() => {
      self._onLayerDidMove();
    }, 0);
  },

  //-------------------------------------------------------------
  onRemove(map) {
    const del = this._delegate || this;
    del.onLayerWillUnmount && del.onLayerWillUnmount(); // -- callback

    map.getPanes().overlayPane.removeChild(this._canvas);

    map.off(this.getEvents(), this);

    this._canvas = null;
  },

  //------------------------------------------------------------
  addTo(map) {
    map.addLayer(this);
    return this;
  },
  // --------------------------------------------------------------------------------
  LatLonToMercator(latlon) {
    return {
      x: latlon.lng * 6378137 * Math.PI / 180,
      y: Math.log(Math.tan((90 + latlon.lat) * Math.PI / 360)) * 6378137,
    };
  },

  //------------------------------------------------------------------------------
  drawLayer() {
    // -- todo make the viewInfo properties  flat objects.
    const size = this._map.getSize();
    const bounds = this._map.getBounds();
    const zoom = this._map.getZoom();

    const center = this.LatLonToMercator(this._map.getCenter());
    const corner = this.LatLonToMercator(this._map.containerPointToLatLng(this._map.getSize()));

    const del = this._delegate || this;
    del.onDrawLayer && del.onDrawLayer({
      layer: this,
      canvas: this._canvas,
      bounds,
      size,
      zoom,
      center,
      corner,
    });
    this._frame = null;
  },
  // -- L.DomUtil.setTransform from leaflet 1.0.0 to work on 0.0.7
  //------------------------------------------------------------------------------
  _setTransform(el, offset, scale) {
    const pos = offset || new L.Point(0, 0);
    el.style[L.DomUtil.TRANSFORM] = (L.Browser.ie3d
      ? `translate(${pos.x}px,${pos.y}px)`
      : `translate3d(${pos.x}px,${pos.y}px,0)`)
      + (scale ? ` scale(${scale})` : '');
  },

  //------------------------------------------------------------------------------
  _animateZoom(e) {
    const scale = this._map.getZoomScale(e.zoom);
    // -- different calc of offset in leaflet 1.0.0 and 0.0.7 thanks for 1.0.0-rc2 calc @jduggan1
    const offset = L.Layer ? this._map._latLngToNewLayerPoint(this._map.getBounds().getNorthWest(), e.zoom, e.center)
      : this._map._getCenterOffset(e.center)._multiplyBy(-scale).subtract(this._map._getMapPanePos());
    L.DomUtil.setTransform(this._canvas, offset, scale);
  },

});

L.canvasLayer1 = () => new L.CanvasLayer();

// eslint-disable-next-line func-names
L.LatLng.prototype.getSubtract360LatLng = function () {
  return new L.LatLng(this.lat, this.lng - 360, this.alt);
};

// eslint-disable-next-line func-names
L.LatLng.prototype.getAdd360LatLng = function () {
  return new L.LatLng(this.lat, this.lng + 360, this.alt);
};
