<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">未来温度模拟</span>
    </div>
    <div class="mod3">
      <div class="row flex-row" v-for="item in dailyCellData" :key="item.item.fxTime">
        <div class="time flex-col">
          <span>{{ getDayDesc(item.item.fxTime) }}</span>
          <span>{{ getTime(item.item.fxTime) }}</span>
        </div>
        <div class="process flex-row">
          <div style="font-size: 18px">{{getTempValue(item.item.tempMin)}}°</div>
          <div style="display: flex;height: 7px;width: 75%" class="flex-row">
            <div v-if="item.currentPercent" class="circle"
                 :style="{left: parseInt(item.currentPercent * 100)+'%'}"></div>
            <div :style="{width: parseInt(item.minPercent * 100)+'%',
            backgroundColor: 'rgba(127, 127, 127, 1)',borderRadius:'5px 0 0 5px'}"></div>
            <div :style="{width: 100 - parseInt((item.minPercent + item.maxPercent) * 100)+'%',
              background: 'linear-gradient(to right,#03C3FF,#cecece,#FF9F46)',borderRadius:'5px'}"></div>
            <div :style="{width: parseInt(item.maxPercent * 100)+'%',
              backgroundColor: 'rgba(127, 127, 127, 1)',borderRadius:'0 5px 5px 0'}"></div>
          </div>
          <div style="font-size: 18px">{{getTempValue(item.item.tempMax)}}°</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState, mapMutations, mapGetters} from 'vuex';
import { getSinglePointMaxMinTemp } from "@/api/weather_hd";
import { modeMap, productionMap } from "@/config/MapConfig";
import moment from "moment";
import {TIANJI1_DA, TIANJI1_ND, TIANJI2_DA, TIANJI2_ND} from "@/config";

export default {
  components: {

  },
  props: {
    productType: {
      type: Array,
      default: () => [],
    },
    detailData: {
      type: Object,
      default: true,
    }
  },
  data() {
    return {
      input: '',
      tempData: [],
      dailyCellData: [],
      currentPercent: '',
      daysDesc: {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六',
      }
    }
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState("map", ["t2Mode", "layerType"]),
    ...mapGetters("map", [ "currentLayerDetails", "isT2Mode"]),
    popupShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    getProductType() {
      return this.productType;
    },

    dustFactorMode(){
      return this.t2Mode === TIANJI1_DA || this.t2Mode === TIANJI1_ND || this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI2_ND;
    },

  },
  created() {

  },
  mounted() {
  },
  methods: {
    getTempProduction(obj) {
      // 判断是否为订正要素
      const isOld = ["early", "late"].includes(obj.mode);
      const production = (this.dustFactorMode ? undefined : productionMap['tmp2m']) ?? (isOld ? "t2" : "nextgen");
      const factorCode = isOld ? 't2mz' : 'tmp2m';
      return {production, factorCode};
    },
    async getSinglePointMaxMinTemp(latlon) {
      const { lat, lng } = latlon;
      const { production, baseTimeString, mode } = this.currentLayerDetails;
      const tempInfo = this.getTempProduction({mode,production});
      const params = {
        lon: lng,
        lat: lat,
        mode: mode,
        baseTime: baseTimeString,
        factorCode: tempInfo.factorCode,
        production: tempInfo.production,
      }
      const tempData = await getSinglePointMaxMinTemp(params);
      this.computeDailyCell(tempData);
    },
    computeDailyCell(tempData){
      if (tempData && tempData.length > 0) {
        const max = tempData.reduce(function (v, e) {
          return parseFloat(v.tempMax) > parseFloat(e.tempMax) ? v : e;
        }).tempMax;
        const min = tempData.reduce(function (v, e) {
          return parseFloat(v.tempMin) < parseFloat(e.tempMin) ? v : e;
        }).tempMin;

        const count = max -min;
        const c_index = moment().format('YYYYMMDD');
        console.log('this.detailData11111===>',this.detailData,'min====>',min,'count===>',count)
        const currentPercent = (parseFloat(this.detailData.temp) - min) / count;
        this.dailyCellData = tempData.map(e => {
          const index = moment(e.fxTime).format('YYYYMMDD');
          if (index === c_index) {
            console.log('this.currentPercent===>',currentPercent)
            return {
              item: e,
              currentPercent: currentPercent,
              maxPercent: (max - parseFloat(e.tempMax)) / count,
              minPercent: (parseFloat(e.tempMin) - min) / count
            }
          }
          return {
            item: e,
            maxPercent: (max - parseFloat(e.tempMax)) / count,
            minPercent: (parseFloat(e.tempMin) - min) / count
          }
        });
        console.log('this.dailyCellData===>',this.dailyCellData)
      }
    },
    getTime(time) {
      return moment(time).format('MM月DD日');
    },
    getDayDesc(time) {
      const index = moment(time).days();
      const timeStr = moment(time).format('YYYYMMDD');
      const cTimeStr = moment().format('YYYYMMDD');
      if (timeStr === cTimeStr) {
        return '今天';
      }
      return this.daysDesc[index];
    },
    getTempValue(value) {
      return value ? parseInt(value) : '';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.mod3 {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .row {
    &:first-child {
      padding: 10px 0 5px 20px;
    }

    &:last-child {
      padding: 5px 0 10px 20px;
    }

    padding: 5px 0 5px 20px;

    .time {
      padding: 5px 0;
    }
  }

  .process {
    justify-content: space-between;
    align-items: center;
    margin-left: 20px;
    width: 70%;

    .circle {
      position: relative;
      background-color: #FFFFFF;
      border-radius: 50px;
      height: 7px;
      width: 7px;
    }
  }

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
}
</style>
