<template>
  <div
    class="legend_loop"
    :class="{
      mobile: mobile,
      left: viewHeight >= 800,
      right: viewHeight < 800,
    }"
  >
    <div
      v-for="(legend, key) of name"
      :key="key"
      class="legend"
    >
      <div
        v-if="!isMobile"
        class="color_wrap"
      >
        <div style="display: flex">
          <!--            <el-switch-->
          <!--              v-model="legend.checked"-->
          <!--              :disabled="legend.disabled"-->
          <!--              active-color="#13ce66"-->
          <!--              @change="(e) => switchChange(e, key)"-->
          <!--              inactive-color="#ff4949">-->
          <!--            </el-switch>-->
          <el-checkbox
            v-model="legend.checked"
            :disabled="legend.disabled"
            active-color="#13ce66"
            class="checkbox"
            inactive-color="#ff4949"
            @change="(e) => switchChange(e, key)"
          />
          <div
            :style="{
              width: '8px', height: '17px', margin: '3px 5px 0px 8px', background: `${legend.color}`,
            }"
          />
          <span class="text">{{ legend.name }}</span>
        </div>

        <div style="margin-left: 5px">
          <!--            <el-switch-->
          <!--              v-model="legend.windChecked"-->
          <!--              :disabled="legend.disabled"-->
          <!--              active-color="#13ce66"-->
          <!--              @change="(e) => switchWindChange(e, key)"-->
          <!--              inactive-color="#ff4949">-->
          <!--            </el-switch>-->
          <el-checkbox
            v-model="legend.windChecked"
            :disabled="legend.disabled"
            active-color="#13ce66"
            class="checkbox"
            inactive-color="#ff4949"
            @change="(e) => switchWindChange(e, key)"
          />
          <span class="text">风圈</span>
        </div>
      </div>
      <div
        v-else
        class="color_wrap"
        :style="{
          background: getColor(legend),
        }"
      >
        <div
          v-for="(item, index) of legend.colors"
          :key="index"
          class="legend_single color"
        >
          {{ legend.values[index] | formatValue(index, legend.colors.length - 1, mobile) }}
        </div>
      </div>
    </div>

    <div
      v-for="(legend, i) of legendName"
      :key="i"
      class="legend"
    >
      <div
        v-if="!isMobile"
        class="color_wrap"
      >
        <div
          class="left"
        >
          <img :src="require(`@/assets/images/${legend.icon}.png`)">
          <span class="text">{{ legend.name }}</span>
        </div>
      </div>
      <div
        v-else
        class="color_wrap"
        :style="{
          background: getColor(legend),
        }"
      >
        <div
          v-for="(item, index) of legend.colors"
          :key="index"
          class="legend_single color"
        >
          {{ legend.values[index] | formatValue(index, legend.colors.length - 1, mobile) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import { drag } from '@/utils/drag';
import Bus from '@/bus/index';

export default {
  filters: {
    formatValue(value, index, length, mobile) {
      const border = mobile ? length : 0;
      return index === border ? value + (value ? '+' : '') : value;
    },
  },
  props: {
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['viewHeight']),
    ...mapState('typhoon', ['modelLegend', 'typhoonObj', 'tableListData', 'ObserveTyphoonList',
      'observeTyphoonObj', 'typhoonLineObj', 'timeLineIndex', 'timeLineData', 'timeLineLengthObj',
      'pointLengthObj', 'timeLineLength', 'renderTyphoonObj', 'timeSetObj', 'timeSet',
      'timeLineClickTime', 'timeRangeData', 'initMove']),
    ...mapGetters('typhoon', ['timeLinePointHourList']),

    name() {
      return this.modelLegend;
    },
    legendName() {
      return [
        { icon: 't-01', name: '热带低压' },
        { icon: 't-02', name: '热带风暴' },
        { icon: 't-03', name: '强热带风暴' },
        { icon: 't-04', name: '台风' },
        { icon: 't-05', name: '强台风' },
        { icon: 't-06', name: '超强台风' },
      ];
    },
  },

  mounted() {
    if (!this.mobile) {
      drag(this.$el);
    }
    // window.addEventListener('resize', () => {
    //   this.setViewHeight(getViewPortHeight());
    // });
  },
  beforeDestroy() {
    // window.removeEventListener('resize', () => {
    //   this.setViewHeight(getViewPortHeight());
    // });
  },

  methods: {
    ...mapMutations('map', ['setViewHeight']),
    ...mapMutations('typhoon', ['setModelLegend', 'setModelLegendWind', 'setLinePointMaxLength',
      'setTimeLineLength', 'setTimeSet', 'setCurrentDateTime', 'setInitMove', 'setTimeLineClickTime']),
    switchChange(value, key) {
      this.setModelLegend({ key, checked: value });
      this.setModelLegendWind({ key, checked: value });
      // this.resetTimeLength();
      const unionTimeSet = this.computeRangeTimeSet(key, value);
      let playObj = {};
      if (key === 'tcvital') {
        playObj = this.observeTyphoonObj;
      } else {
        playObj = this.typhoonLineObj;
      }
      if (!value) {
        // 隐藏所有的key的图
        if (playObj && Object.keys(playObj).length > 0) {
          Object.keys(playObj).forEach((v) => {
            if (v.indexOf(key) > -1) {
              playObj[v].deleteTyphoon();
            }
          });
        }
        this.computeRenderLine(unionTimeSet, key, value);
      } else {
        // 重新展示
        // eslint-disable-next-line no-lonely-if
        if (playObj && Object.keys(playObj).length > 0) {
          let findLine = false;
          Object.keys(playObj).forEach((v) => {
            if (v.indexOf(key) > -1) {
              // 如果已经绘制过，则重新绘制
              playObj[v].reRenderLine();
              findLine = true;
            }
          });

          if (!findLine) {
            // 如果没有绘制过，则重新绘制
            if (this.initMove) {
              this.setInitMove(false);
            }
            this.renderTyphoonObj[key].forEach((params) => {
              Bus.$emit('renderTyphoon', params);
            });
            setTimeout(() => {
              this.computeRenderLine(unionTimeSet, key, value);
            }, 1000);
          } else {
            this.computeRenderLine(unionTimeSet, key, value);
          }
        }
      }
    },
    resetTimeLength() {
      let maxLength = 0;
      Object.keys(this.typhoonLineObj).forEach((v) => {
        let checked = true;
        for (const key of Object.keys(this.modelLegend)) {
          if (v.indexOf(key) > -1) {
            checked = this.modelLegend[key].checked;
            break;
          }
        }
        if (checked) {
          const length = this.timeLineLengthObj[v];
          const timeLength = this.pointLengthObj[v];
          if (length > maxLength) {
            maxLength = length;
            this.setLinePointMaxLength(length);
            this.setTimeLineLength(timeLength);
          }
        }
      });
    },
    // 展示风圈按钮
    switchWindChange(value, key) {
      this.setModelLegendWind({ key, checked: value });
      if (!this.modelLegend[key].checked) {
        return;
      }
      let lineObj = {};
      if (key === 'tcvital') {
        // 实况
        lineObj = this.observeTyphoonObj;
      } else {
        lineObj = this.typhoonLineObj;
      }
      if (!value) {
        // 不展示风圈
        if (lineObj && Object.keys(lineObj).length > 0) {
          Object.keys(lineObj).forEach((t) => {
            if (t.indexOf(key) > -1) {
              lineObj[t].deleteWindCircle();
            }
          });
        }
      } else {
        // 显示风圈，如果当前线路不显示，仍然不展示风圈
        // 如果是观测的，点位不在时间轴区间上，
        // eslint-disable-next-line no-lonely-if
        if (lineObj && Object.keys(lineObj).length > 0) {
          Object.keys(lineObj).forEach((t) => {
            if (t.indexOf(key) > -1) {
              lineObj[t].showWindCircle(this.timeLineClickTime);
            }
          });
        }
      }
    },

    checkLineChecked(t) {
      let windChecked = true;
      let lineChecked = true;
      for (const key of Object.keys(this.modelLegend)) {
        if (t.indexOf(key) > -1) {
          windChecked = this.modelLegend[key].windChecked;
          lineChecked = this.modelLegend[key].checked;
        }
      }
      return { lineChecked, windChecked };
    },

    findObserveLineIndex(id) {
      for (const obj of this.ObserveTyphoonList) {
        if (`tcvital${obj.id}` === id) {
          const data = obj.details;
          if (data && data.length > 0) {
            for (let i = 0; i <= data.length - 1; i++) {
              const detail = data[i];
              if (detail.forecastTimeString === this.timeLineDate) {
                return i;
              }
            }
          }
        }
      }
      return -1;
    },

    computeRangeTimeSet(key, isShow) {
      // 计算当前选择线路的可选日期区间
      const timeSet = this.timeSet;
      const keyTimeSet = this.timeSetObj[key];
      let unionTimeSet = new Set();
      if (isShow) {
        // 展示，合并
        unionTimeSet = new Set([...timeSet, ...keyTimeSet]);
        this.setTimeSet(unionTimeSet);
      } else {
        // 隐藏，去掉timeSet中的数据
        Object.keys(this.timeSetObj).forEach((k) => {
          if (this.modelLegend[k].checked) {
            unionTimeSet = new Set([...unionTimeSet, ...this.timeSetObj[k]]);
          }
        });
        this.setTimeSet(unionTimeSet);
      }
      return unionTimeSet;
    },

    computeRenderLine(unionTimeSet, key, isShow) {
      // 判断当前时刻是否在事件区间内，如果不在，则切换到第一天的第一个时刻点击状态，如果在，则不处理
      const date = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('YYYYMMDD');
      if (!unionTimeSet.has(date)) {
        // 如果不存在
        const [first] = [...unionTimeSet].sort();
        this.setCurrentDateTime(first);
        const currentTime = first + this.timeLinePointHourList[0];
        Bus.$emit('tiggerTimeLineClick', currentTime);
      } else {
        // 如果存在，则判断当前时间点节点小时是否在这个时刻里，如果不在，则获取第一，如果在则不处理
        const hour = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('HH');
        const index = this.timeLinePointHourList.findIndex((v) => v === hour);
        if (index === -1) {
          const [first] = [...unionTimeSet].sort();
          this.setCurrentDateTime(first);
          const currentTime = first + this.timeLinePointHourList[0];
          Bus.$emit('tiggerTimeLineClick', currentTime);
        } else if (isShow && this.modelLegend[key].windChecked) {
          // 如果存在，如果风圈是展示的，则需要展示当前位置风圈
          let lineObj = {};
          if (key === 'tcvital') {
            // 实况
            lineObj = this.observeTyphoonObj;
          } else {
            lineObj = this.typhoonLineObj;
          }
          if (lineObj && Object.keys(lineObj).length > 0) {
            Object.keys(lineObj).forEach((t) => {
              if (t.indexOf(key) > -1) {
                console.log('lineObj[t]==>', lineObj[t]);
                lineObj[t].showWindCircle(this.timeLineClickTime);
              }
            });
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.legend_loop {
  position: absolute;
  right: 22px;
  bottom: 75px;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  background-color: $bg-menu;
  border-radius: 6px;

  .legend {
    cursor: move;
    padding: 0 5px 0 5px;

    .color_wrap {
      display: flex;
      padding: 3px 4px;
      backdrop-filter: blur(1px);
      justify-content: space-between;

      .text {
        color: white;
        font-size: 14px;
        margin: auto;
      }
    }

    .legend_single {
      border-radius: 6px 6px 0 0;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      color: #fff;
      text-align: left;
      min-width: 24px;

      &.color {
        //text-indent: 5px;
        text-align: center;
        text-shadow: 0 0 2px #000;
      }

      &.black {
        color: #000;
      }

      &.unit {
        background: $bg-color;
        text-align: center;
        color: $text-operate;
      }
    }

    &.legend:first-child {
      padding-top: 5px;
      //border-radius: 6px 6px 0 0;
      //border: 1px solid rgba(0, 0, 0, 0.45);
    }

    &.legend:last-child {
      padding-bottom: 5px;
      //border-radius: 0 0 6px 6px;
      //border: 1px solid rgba(0, 0, 0, 0.45);
    }

    .checkbox {
      zoom: 130%;
    }
  }

  &.mobile {
    width: 100%;
    box-sizing: border-box;
    top: auto;
    bottom: 0;
    left: 0;

    .legend {
      display: flex;
      margin-left: 0;
      width: 100%;

      .color_wrap {
        justify-content: space-around;
        border-radius: 0;
      }

      .unit_wrap {
        width: 60px;
      }

      .legend_single {
        color: #fff;
        text-align: center;
        padding: 0;
        white-space: nowrap;
        min-width: 0;

        &.unit {
          background: #fff;
          color: #333;
          height: 10px;
          line-height: 10px;
          border: none;
          border-radius: 0;
        }

        &.unit:first-child {
          padding-top: 3px;
        }

        &.unit:last-child {
          padding-bottom: 3px;
        }
      }
    }

    .color_wrap {
      display: flex;
      width: calc(100% - 60px);
    }
  }
}

::v-deep{
  .color_wrap {
    /* 未选中时的样式 */
    .el-checkbox.el-checkbox__input.el-checkbox__inner {
      //border: 1px solid #47A87D;
      //background: #fff;
      //padding: 10px 10px;
      //line-height: 100%;
      //margin-right: 10px;
    }
    /* 选中状态时的样式 */
    .el-checkbox.is-checked {
      //background: #EDF6F2;
      //border: 1px solid #47A87D;
      //padding: 10px 10px;
      //border-radius: 4px;
    }
    /* 选中状态、同时设置了disabled为true的复选框的样式（HTML代码中第一个checkbox的disabled设置为了true */
    .el-checkbox.is-disabled.is-checked {
      background: #EDF6F2;
    }
    ///* 选中状态、且disabled设置为true的checkbox的文本的样式 */
    .el-checkbox__input.is-disabled+span.el-checkbox__label {
      color: #47a87d;
      cursor: not-allowed;
    }
    ///* 选中状态、disabled设置为true的复选框，设置其可勾选方框的样式 */
    .el-checkbox__input.is-disabled .el-checkbox__inner {
      background-color: #808080;
      border-color: #808080;
    }
  }
}
</style>
