<template>
  <div>
    <div :class="modelType !=='typhoon' ? 'location menu_bg btn' : 'mode menu_bg btn'" v-if="modelType !=='satellite'"
         @click="handleLocation">
      <van-icon name="location-o" color="#fff" size="24"/>
      <span class="text">定位</span>
    </div>
    <div class="search menu_bg btn" v-if="modelType !=='satellite' && modelType !=='typhoon'"
         @click="handleSearch">
      <van-icon name="search" color="#fff" size="24"/>
      <span class="text">搜索</span>
    </div>
    <div class="space menu_bg btn" v-if="modelType !=='satellite' && modelType !=='typhoon'"
         @click="menuClick(activeText)">
      <div style="font-size: 17px">{{ $route.name === "earth" ? '2D' : '3D' }}</div>
      <span class="text">{{ $route.name === "earth" ? '二维' : '三维' }}</span>
    </div>
<!--    <div class="mode btn" @click="qbPopShow">-->
<!--      <van-icon name="notes-o" color="#fff" size="24"/>-->
<!--    </div>-->

    <div class="mode-div" v-if="modelType !=='satellite' && modelType !=='typhoon' && factor!=='precp'"
         @click="modeSelectShow = true">
      <div class="mode-text">
        <span>{{ modifyItem() }}</span><span class="text">{{ ratio() }}</span>
      </div>
      <van-icon name="arrow-up" color="#FFFFFF"/>
    </div>

    <div class="menu menu_bg btn" @click="menuShow = true">
      <van-icon name="list-switch" color="#fff" size="24"/>
      <span class="text">菜单</span>
    </div>

    <div class="mode-div" v-if="modelType ==='typhoon'"
         @click="typhoonSelectShow = true">
      <div class="mode-text">
        <span>{{ typhoonName }}</span>
      </div>
      <van-icon name="arrow-up" color="#FFFFFF"/>
    </div>
    <div class="precp-text" v-if="factor === 'precp'">
      {{ CY }}
      <a style="color: rgb(2, 255, 158);text-decoration: underline " href="https://caiyunapp.com/" target="_blank">彩云天气</a>
    </div>
    <div class="timegap" @click="timegapShow = true" v-if="factor === 'pratesfc' && modelType === 't2'">
      <div class="mode-text">
        <span>{{ timeGap }}</span>
      </div>
      <van-icon name="arrow-up" color="#FFFFFF"/>
    </div>
    <div class="qb-div precp" v-if="factor === 'precp'">
      <div class="mode-text">
        <span>{{ qbSelectName === 'Invalid date' ? '' : qbSelectName }}</span>
      </div>
    </div>
    <div class="qb-div" @click="qbPopShow" v-else>
      <div class="mode-text">
        <span>{{ qbSelectName === 'Invalid date' ? '' : qbSelectName }}</span>
      </div>
      <van-icon name="arrow-up" color="#FFFFFF"/>
    </div>

    <el-popover v-if="modelType !=='satellite' && modelType !=='typhoon'"
      v-model="isShow"
      placement="top-start"
      width="150"
      trigger="click"
      title="声明"
    >
      <div class="content">
        <span>本网站数据为计算结果，仅供参考。</span>
        <i
          class="icon el-icon-close"
          @click="outside()"
        />
      </div>
      <div
        slot="reference" class="info"
      >
        <i
          class="el-icon-info"
          style="color: #fff;font-size: 16px;"
        />
        <span style="margin-left: 5px">{{ factorName }}</span>
      </div>
    </el-popover>
    <!-- 搜索 -->
    <SearchPopup :show.sync="searchShow" @locate="setLocation"></SearchPopup>
    <!-- 起报时间 -->
    <DatePopup :show.sync="modeShow" @changeQbSelectName="changeQbSelectName"></DatePopup>

    <!-- 台风起报时间 -->
<!--    <TyphoonDatePopup :show.sync="typhoonDateShow"/>-->
    <TyphoonDatePopupTree :show.sync="typhoonDateShow" @changeQbSelectName="changeQbSelectName"/>

    <!-- 菜单 -->
    <MenuPopup :show.sync="menuShow" :metrics="metrics" :factor="factor" :model-type="modelType"
               :is-satel="isSatel" :is-typ="isTyp"
               @change="factorChange" @changeFactorTip="changeFactorTip"
               @changeModeName="changeModeName" @changeModeItem="changeModeItem"></MenuPopup>
    <!-- 模式选择框 -->
    <ModeSelectPopup ref="modeSelect" :show.sync="modeSelectShow" :modelType="modelType" :factor="factor"
                     @changeModeName="changeModeName"></ModeSelectPopup>
    <!-- 台风模式选择框 -->
    <TyphoonModePopup :show.sync="typhoonSelectShow"></TyphoonModePopup>
    <!-- 时间间隔 -->
    <TimeGap :show.sync="timegapShow"></TimeGap>
    <!-- 单点弹框 -->
    <PointPrecpDetail v-if="factor==='precp'" ref="detailPopup" :show.sync="pointDetailShow"></PointPrecpDetail>
    <PointDetailPopup v-else ref="detailPopup" :show.sync="pointDetailShow"></PointDetailPopup>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import {dust_factorMap, mapForCn} from "@/config/MapConfigT4";
import SearchPopup from './SearchPopup.vue'
import DatePopup from './DatePopupNew.vue'
import MenuPopup from './MenuPopup.vue'
import ModeSelectPopup from './ModeSelectPopup.vue'
import MetricSelect from '../MetricSelect.vue'
import TyphoonModePopup from './typhoon/TyphoonModePopup.vue'
import TyphoonDatePopup from './typhoon/TyphoonDatePopup.vue'
import TyphoonDatePopupTree from './typhoon/TyphoonDatePopupTree.vue'
import PointDetailPopup from './detail/PointDetailPopup.vue'
import PointPrecpDetail from './detail/PointPrecpDetail.vue'
import TimeGap from './TimeGap.vue'
import Bus from '@/bus/index';
import { TIANJI2_MERGE, CY } from "@/config";


export default {
  components: {
    SearchPopup,
    DatePopup,
    MenuPopup,
    ModeSelectPopup,
    MetricSelect,
    TyphoonModePopup,
    TyphoonDatePopup,
    TyphoonDatePopupTree,
    TimeGap,
    PointDetailPopup,
    PointPrecpDetail
  },
  data() {
    return {
      searchShow: false,
      modeShow: false,
      menuShow: false,
      modeSelectShow: false,
      typhoonSelectShow: false,
      typhoonDateShow: false,
      timegapShow: false,
      pointDetailShow: false,
      factorName: '',
      modeName: '',
      ratioName: '',
      typhoonName: '',
      qbSelectName: '',
      CY:CY,
    }
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    modelType: {
      type: String,
      required: true,
    },
    factor: {
      type: String,
      required: true,
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "t2",
    },
  },
  computed: {
    ...mapState(['isMobile','isShowInfo','t1Mode']),
    ...mapState('map',['t2Mode', 'timeGap']),
    ...mapState('typhoon',['modelLegend']),
    isShow: {
      get() {
        return this.isShowInfo;
      },
      set(value) {

      }
    },
    activeText() {
      return this.$route.name === "earth" ? "二维" : "三维";
    },
  },
  created() {
    this.initFactorName();
    if (this.modelType === 'typhoon') {
      this.initTyphoonName();
    }
  },
  mounted() {
    Bus.$on("showDetailInfo", this.showDetailInfo );
    console.log(this.vuexFactor,'vuexFactor')
  },

  beforeDestroy() {
    Bus.$off("showDetailInfo");
  },

  methods: {
    ...mapMutations(["setShowInfo"]),
    ...mapMutations([
      "setHistory",
    ]),
    ...mapMutations('map', [
      "setFactorName",
    ]),
    ...mapMutations('map',["setPopupShow"]),
    initFactorName() {
      this.metrics.forEach((metric) => {
        if (metric.id !== 'satellite' && metric.id !== 'typhoon'){
          if (metric.children && metric.children.length > 0) {
            metric.children.forEach(v => {
              if (v.id === this.factor) {
                this.factorName = v.text;
                this.setFactorName(v.text);
              }
            })
          } else {
            if (metric.id === this.factor) {
              this.factorName = metric.text;
              this.setFactorName(metric.text);
            }
          }
        }
      });
    },
    handleSearch() {
      this.searchShow = true
      // this.setPopupShow(false)
      // Bus.$emit('removePopup')
    },
    outside() {
      this.setShowInfo(false);
    },
    menuClick(item) {
      this.setPopupShow(false)
      if (this.modelType === "satellite") return;
      this.$sensors.track("Jump_volume", {
        from_factor: mapForCn[this.factor],
        from_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
        from_space_type: item === '二维' ? '三维' : '二维',
        to_mode: sessionStorage.getItem("tmode") === "t1" ? this.t1Mode : this.t2Mode,
        to_factor: mapForCn[this.factor],
        to_space_type: item,
      });
      this.$sensors.track("CurrentSpace", {
        space_type: `三维`,
      });
      if (item === "二维") {
        this.$sensors.track("CurrentSpace", {
          space_type: `二维`,
        });

        // this.$router.push({ name: "weather_hd" });
        if (["dust_conc", "dod", "dust_ddep", "dust_emis"].includes(this.factor)) {
          this.$store.commit("map/setFactor", this.factor);
          this.$router.push({ name: "weather_hd" });
        }
        else  {
          // 如果是首次进入页面，进行路由跳转
          this.$router.push({ name: "weather_hd" });
        }
      } else {
        this.setHistory(this.$route.name);
        this.$router.push({ name: "earth" });
      }
    },
    factorChange(metric) {
      this.$emit("change", metric);
    },
    changeFactorTip(name) {
      this.factorName = name;
      this.setFactorName(name);

    },

    modifyItem(){

      const routeName = this.$route.name
      if (routeName === "weather") {
        return this.t1Mode
      }else {
        return dust_factorMap[this.factor]?this.t2Mode.replace(2,1):this.t2Mode;
      }
    },

    changeModeName(name) {
      this.modeName = dust_factorMap[this.factor]?name.replace(1,2):name;
      this.ratioName = this.ratio();
    },
    changeQbSelectName(name) {
      this.qbSelectName = name;
    },
    setLocation(item) {
      this.$emit('locate', item);
    },
    handleLocation() {
      Bus.$emit('locate');
    },
    changeModeItem(name) {
      this.$refs.modeSelect.changeModeItem(name);
    },
    ratio() {
      if (sessionStorage.getItem("tmode") === 't1') {
        return "12km";
      } else {
        if (["dust_conc", "dod", "dust_ddep", "dust_emis"].includes(this.factor)) {
          return "12km";
        }
        if (this.t2Mode === TIANJI2_MERGE) {
          if (["tmp2m", "t2mz"].includes(this.factor)) {
            return "1km";
          } else if (["pratesfc","pregrt","prerrt","presrt","preirt","pre_total","pres_all",'prer',"max_reflectivity","base_reflectivity"].includes(this.factor)) {
            return "2.5km";
          }else {
            return "10km";
          }
        }
        return "2.5km";
      }
    },
    qbPopShow() {
      if (this.isTyp){
        this.typhoonDateShow = true;
        return;
      }
      this.modeShow = true;
    },
    initTyphoonName() {
      let typhoonName = '';
      for (const key of Object.keys(this.modelLegend)) {
        if (this.modelLegend[key].checked) {
          typhoonName += this.modelLegend[key].name + " , ";
        }
      }
      if (typhoonName !== '') {
        typhoonName = typhoonName.substr(0,typhoonName.length - 3);
      }
      if (typhoonName.length > 9) {
        typhoonName = typhoonName.substr(0,9) + ' ...';
      }
      this.typhoonName = typhoonName;
    },
    showDetailInfo(latlng, precpData) {
      this.$refs.detailPopup.init(latlng,this.metrics,precpData);
      this.pointDetailShow = true;
    },
  }
};
</script>

<style lang="scss" scoped>
.btn {
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(66, 66, 66, 0.55);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  &.location {
    top: 220px;
  }
  &.search {
    top: 280px;
  }
  &.space {
    top: 340px;
    color: #fff;
  }
  &.mode {
    top: 340px;
  }
  &.menu {
    top: 400px;
  }

  &.menu_bg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #FFFFFF;
    border-radius: 10px;
    padding: 4px;
    font-size: 14px;

    .text {
      margin-top: 2px;
    }
  }
}

.mode-div {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 95px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;

  .mode-text {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;

    .text {
      font-size: 11px;
      margin-left: 5px;
    }
  }
}

.info {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 95px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  left: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.content {
  font-size: 10px;
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
}

.qb-div {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 135px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;
  &.precp {
    bottom: 95px;
  }

  .mode-text {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;
  }
}
.timegap {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 175px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;
}
.precp-text {
  bottom: 130px;
  position: absolute;
  z-index: 999;
  right: 8px;
  font-size: 10px;
  border-radius: 30px;
  padding: 10px 10px;
  //background: rgba(50, 50, 50, 0.5);
  box-shadow: 0 0 0 0;
  color: #FFF;
}
</style>
