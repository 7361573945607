<template>
  <div class="control">
    <div
      :class="{
        mobile_control: isMobile,
      }"
    >
      <!-- <div
        v-show="!isMobile && ($route.name === 'satellite' || $route.name === 'earth')"
        class="control-single earth"
        :title="fullScreen ? '退出全屏' : '全屏'"
        style="border-radius: 6px 6px 6px 6px"
        @click="fullScreenFn"
      >
        <svg-icon
          v-if="!fullScreen"
          icon-class="full-screen"
        />
        <svg-icon
          v-else
          icon-class="full-screen-exit"
        />
      </div>
      <div
        v-show="!isMobile && $route.name !== 'satellite' && $route.name !== 'earth'"
        class="control-single"
        style="border-radius: 6px 6px 0 0"
        :title="fullScreen ? '退出全屏' : '全屏'"
        @click="fullScreenFn"
      >
        <svg-icon
          v-if="!fullScreen"
          icon-class="full-screen"
        />
        <svg-icon
          v-else
          icon-class="full-screen-exit"
        />
      </div> -->
      <div
        v-show="$route.name !== 'satellite' && $route.name !== 'earth'"
        class="control-single"
        :class="{ earth: $route.name === 'earth' }"
        title="放大一级"
        @click="control('zoomIn')"
      >
        <svg-icon icon-class="control-expend" />
      </div>
      <div
        v-show="$route.name !== 'satellite' && $route.name !== 'earth'"
        class="control-single"
        :class="{ earth: $route.name === 'earth' }"
        title="缩小一级"
        @click="control('zoomOut')"
      >
        <svg-icon icon-class="control-reduce" />
      </div>
      <div
        v-show="$route.name !== 'satellite' && $route.name !== 'earth'"
        v-if="!t3"
        class="control-single"
        :class="{ earth: $route.name === 'earth' }"
        title="定位当前位置"
        @click="control('locate')"
      >
        <svg-icon icon-class="map-location" />
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Bus from '@/bus/index';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },

    t3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fullScreen: false,
    };
  },
  computed: {
    ...mapState(['isMobile']),
  },
  mounted() {
    // 退出全屏事件监听
    document.addEventListener('fullscreenchange', this.exitHandler);
    document.addEventListener('webkitfullscreenchange', this.exitHandler);
    document.addEventListener('mozfullscreenchange', this.exitHandler);
    document.addEventListener('MSFullscreenChange', this.exitHandler);
  },
  methods: {
    control(type) {
      Bus.$emit(type);
    },

    /**
     * @description 退出全屏事件处理
     * @returns {void}
     * @author yujie
     */
    exitHandler() {
      this.fullScreen = !(!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement);
    },

    fullScreenFn() {
      if (!this.fullScreen) {
        this.launchFullscreen(document.documentElement);
        this.fullScreen = true;
      } else if (this.fullScreen) {
        this.exitFullscreen();
        this.fullScreen = false;
      }
    },

    launchFullscreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullScreen();
      }
    },

    exitFullscreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.control {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  color: #fff;

  .control-single {
    width: 45px;
    height: 45px;
    background: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(1px);
    margin-bottom: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);
    font-weight: 700;

    &:first-child {
      border-radius: 6px 6px 0 0;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
      border-bottom: none;
    }
  }

  .control-single-pc {
    width: 42px;
    height: 42px;
    background: rgba(61, 61, 61, 0.6);
  }

  &.mobile_control {
    right: 10px;

    .control-single {
      width: 30px;
      height: 30px;
      font-size: 16px;
      margin-bottom: 10px;
      border-radius: 50%;
    }
  }

  .earth {
    background: rgba(66,66,66,0.55);
    backdrop-filter: blur(2px);
    box-shadow: 0 0 0 0;
  }

}
</style>
