export default {
  namespaced: true,
  state: () => ({
    autoTriggerMouseStart: false,
    layerManagerAgent: null,
    load: false,
    index: 0,
    requestIndex: 0,
    first: true,
    windList: [],
  }),

  getters: {
    earthWind(state) {
      return state.windList[state.requestIndex] ?? {};
    },
  },

  mutations: {
    setEarthWind(state, value) {
      state.earthWind = value;
    },

    setStatus(state, value) {
      state.status = value;
    },

    setAutoTriggerMouseStart(state, value) {
      state.autoTriggerMouseStart = value;
    },

    setLayerManagerAgent(state, value) {
      state.layerManagerAgent = value;
    },

    setLoad(state, value) {
      state.load = value;
    },

    setFirst(state, value) {
      state.first = value;
    },

    setIndex(state, value) {
      state.index = value;
    },

    setRequestIndex(state, value) {
      state.requestIndex = value;
    },

    setWindList(state, value) {
      state.windList = value;
    },

    reset(state) {
      state.autoTriggerMouseStart = false;
      state.layerManagerAgent = null;
      state.load = false;
      state.index = 0;
      state.requestIndex = 0;
      state.first = true;
      state.windList = [];
    },
  },
};
