import { httpMannger as http, httpManngerPost, httpManngerMain } from './http';


export const toLogin = (param) => httpManngerPost.post(`/gateway/admin/login`, param);

export const regist = (param) => httpManngerPost.post(`/gateway/admin/user/out/regist`, param);

export const getCaptcha = () => http.get(`/gateway/admin/login/captcha`);

export const getCode = (param) => http.get(`/gateway/admin/login/mobile/code`, {params: {...param}});

export const logout = () => httpManngerMain.get('/gateway/admin/logout');

export const getRegistCode = (param) => http.get(`gateway/admin/login/mobile/code/regist/user`, { params: { ...param } });

export const getFindPassWordCode = (param) => http.get(`/gateway/admin/login/mobile/code/back/password`, { params: { ...param } });

export const verfiryCode = (param) => http.get(`/gateway/admin/user/out/back/password`, { params: { ...param } });

export const updataPassWord = (param) => httpManngerPost.post(`/gateway/admin/user/out/update/password`, param);

export const getList = () => http.get(`/gateway/admin/profession/out/list`);

export const verifyUserName = (param) => http.get(`/gateway/admin/user/out/check/username`, {params: {...param}});


export const getUserInfo = (param) => httpManngerMain.get(`/gateway/admin/user/info`);

export const getToken = (param) => httpManngerMain.get(`/gateway/admin/app/info/open-api`);

export const getProvince = (param) => httpManngerMain.get(`/gateway/admin/section/province/list`);

export const getChangeMobileCode = (param) => httpManngerMain.get(`/gateway/admin/login/mobile/code/change/mobile`, {params: {...param}});

export const getChangeMobile = (param) => httpManngerMain.get(`/gateway/admin/user/update/mobile`, {params: {...param}});

export const getProvinceUpCity = (param) => httpManngerMain.get(`/gateway/admin/section/city/list`, {params: {...param}});

export const getCheckLogin = () => httpManngerMain.get(`/gateway/admin/user/check/login`);

// export const getChangeMobileCode = (param) => httpManngerMain.get(`/gateway/admin/login/mobile/code/change/mobile`, {params: {...param}});



export const updataPassWordUser = (param) => httpManngerPost.post(`/gateway/admin/user/update/password`, param);

export const setUserInfomation= (param) => httpManngerPost.post(`/gateway/admin/user/info`, param);


