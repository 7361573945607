// import './L.CanvasLayer';
// import './L.Control.Velocity';
// import L from 'leaflet';
import Windy from './cesiumWindy.js';

export default class VelocityLayer {
  constructor(options) {
    this.registerFunc = {};
    const option = {
      displayValues: false,
      displayOptions: {
        velocityType: 'Velocity',
        position: 'bottomleft',
        emptyString: 'No velocity data',
      },
      maxVelocity: 10, // used to align color scale
      colorScale: null,
      data: null,
    };

    this.options = Object.assign(option, options);

    this._map = null;
    this._canvasLayer = null;
    this._windy = null;
    this._context = null;
    this._timer = 0;
    this._mouseControl = null;

    this.onAdd();

    this.resizeTimer = null;
    const that = this;

    const viewer = options.viewer;
    this.handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);

    let startPosition; // 记录鼠标按下时的位置

    this.windyMoveUdate = () => {
      that._clearWind();
    };
    // 监听相机移动状态改变事件
    viewer.camera.moveStart.addEventListener(this.windyMoveUdate);

    this.windyUdate = () => {
      that._startWindy();
      // console.log('Camera movement ended');
      // 在这里执行相机转动结束后的事件
    };

    viewer.camera.moveEnd.addEventListener(this.windyUdate);

    // 监听鼠标按下事件
    this.handler.setInputAction((click) => {
      startPosition = click.position;
    }, Cesium.ScreenSpaceEventType.LEFT_DOWN);

    // 监听鼠标移动事件
    this.handler.setInputAction(() => {
      if (startPosition) {
        that._clearWind();
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);

    // 监听鼠标抬起事件
    this.handler.setInputAction(() => {
      startPosition = undefined;
    }, Cesium.ScreenSpaceEventType.LEFT_UP);

    window.addEventListener('resize', () => {
      that.reStart(that);
    });
  }

  initialize() {
    // L.setOptions(this, options);
  }

  onAdd() {
    // determine where to add the layer
    this._canvasLayer = {};
    this._canvasLayer._canvas = this.options.canvas;

    if (!this._windy) {
      this._initWindy(this);
    }
  }

  onRemove() {
    this._destroyWind();
  }

  reStart(that) {
    that._clearWind();
    clearTimeout(that.resizeTimer);
    that.resizeTimer = setTimeout(() => {
      that._initWindy(that); // 在屏幕尺寸变化后延迟执行的代码
    }, 100); // 设置延迟的时间，单位是毫秒
  }

  setData(data) {
    this.options.data = data;
    if (this._windy) {
      this._windy.setData(data);
      this._clearAndRestart();
    }
    this.fire('load');
  }

  setOpacity(opacity) {
    // console.log('this._canvasLayer', this._canvasLayer);
    this._canvasLayer.setOpacity(opacity);
  }

  setOptions(options) {
    this.options = Object.assign(this.options, options);
    if (options.hasOwnProperty('displayOptions')) {
      this.options.displayOptions = Object.assign(
        this.options.displayOptions,
        options.displayOptions,
      );
      this._initMouseHandler(true);
    }
    if (options.hasOwnProperty('data')) this.options.data = options.data;
    if (this._windy) {
      this._windy.setOptions(options);
      if (options.hasOwnProperty('data')) this._windy.setData(options.data);
      this._clearAndRestart();
    }

    this.fire('load');
  }

  /* ------------------------------------ PRIVATE ------------------------------------------*/

  onDrawLayer() {
    const self = this;

    if (!this._windy) {
      this._initWindy(this);
      return;
    }

    if (!this.options.data) {
      return;
    }

    if (this._timer) clearTimeout(self._timer);

    // this._timer = setTimeout(() => {
    //   self._startWindy();
    // } 100); // showing velocity is delayed
  }

  _startWindy() {
    // const bounds = this._map.getBounds();
    const size = {
      x: window.innerWidth,
      y: window.innerHeight,
    };
    const camera = this.options.viewer.camera;

    // var frustum = camera.frustum;
    const viewRectangle = camera.computeViewRectangle();

    // console.log('frustum', 111111);
    const southwest = Cesium.Rectangle.southwest(viewRectangle);
    const northeast = Cesium.Rectangle.northeast(viewRectangle);

    const southwestCoordinates = {
      longitude: southwest.longitude,
      latitude: southwest.latitude,
    };

    const northeastCoordinates = {
      longitude: northeast.longitude,
      latitude: northeast.latitude,
    };

    // bounds, width, height, extent
    this._windy.start(
      [
        [0, 0],
        [size.x, size.y],
      ],
      size.x,
      size.y,
      [
        [southwestCoordinates.longitude, southwestCoordinates.latitude],
        [northeastCoordinates.longitude, northeastCoordinates.latitude],
      ],
    );
  }

  _initWindy(self) {
    // windy object, copy options
    const options = {
      canvas: self._canvasLayer._canvas,
      map: this._map,
      viewer: this.options.viewer,
      ...self.options,
    };
    this._windy = new Windy(options);

    // prepare context global var, start drawing
    this._context = this._canvasLayer._canvas.getContext('2d');
    this._canvasLayer._canvas.classList.add('velocity-overlay');
    this._clearAndRestart();

    // this.onDrawLayer();
    // this._map.on('dragstart', self._windy.stop);
    // this._map.on('dragend', self._clearAndRestart);
    // this._map.on('zoomstart', self._windy.stop);
    // this._map.on('zoomend', self._clearAndRestart);
    // this._map.on('resize', self._clearWind);

    // this._initMouseHandler(false);
  }

  _initMouseHandler(voidPrevious) {
    if (voidPrevious) {
      this._map.removeControl(this._mouseControl);
      this._mouseControl = false;
    }
    if (!this._mouseControl && this.options.displayValues) {
      const options = this.options.displayOptions || {};
      options.leafletVelocity = this;
      // this._mouseControl = L.control.velocity(options).addTo(this._map);
    }
  }

  _clearAndRestart() {
    if (this._context) this._context.clearRect(0, 0, 3000, 3000);
    if (this._windy) this._startWindy();
  }

  _clearWind() {
    if (this._windy) this._windy.stop();
    if (this._context) this._context.clearRect(0, 0, 3000, 3000);
  }

  _destroyWind() {
    if (this._timer) clearTimeout(this._timer);
    if (this._windy) this._windy.stop();
    if (this._context) this._context.clearRect(0, 0, 3000, 3000);
    if (this._mouseControl) this._map.removeControl(this._mouseControl);
    this._mouseControl = null;
    this._windy = null;

    // 移除相机事件监听器
    this.options.viewer.camera.moveStart.removeEventListener(this.windyMoveUdate);

    this.options.viewer.camera.moveEnd.removeEventListener(this.windyUdate);

    const leftDownHandler = this.handler.getInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN);
    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOWN, leftDownHandler);

    const mouseMoveHandler = this.handler.getInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.MOUSE_MOVE, mouseMoveHandler);

    const leftUpHandler = this.handler.getInputAction(Cesium.ScreenSpaceEventType.LEFT_UP);
    this.handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_UP, leftUpHandler);

    window.removeEventListener('resize', () => {
      that.reStart(that);
    });
  }
}
