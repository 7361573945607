<template>
  <div class="basetime_select">
    <div class="label">
      起报
    </div>
    <div class="select_wrap">
      <el-select v-model="qbDate" placeholder="起报时间">
        <el-option
          v-for="item in qbDataInfoList"
          :key="item.key"
          :value="item.value"
          :label="item.name"
        />
      </el-select>
    </div>
    <!--    <div class="label" style="margin-left: 10px">-->
    <!--      名称-->
    <!--    </div>-->
    <!--    <div class="select_wrap">-->
    <!--      <el-select-->
    <!--        v-model="typhoonName"-->
    <!--        placeholder="台风名称"-->
    <!--        @change="selectTyphoonName"-->
    <!--      >-->
    <!--        <el-option-->
    <!--          v-for="item in typhoonNameList"-->
    <!--          :key="item.id"-->
    <!--          :value="item.id"-->
    <!--          :label="item.name"-->
    <!--        />-->
    <!--      </el-select>-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { getTyphoonList, getTyphoonDetailList, getTyphoonLatestList } from '@/api/typhoon';
import Bus from '@/bus/index';

export default {
  filters: {
    formatTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },

  data() {
    return {
      typhoonNameList: [],
      value: '',
      typhoonName: '',
      qbObserveObj: {},
      timeLineRangeObj: [],
      observeTimeLine: {},
    };
  },

  computed: {
    ...mapState('typhoon', ['qbDataList', 'nameDataList', 'typhoonId', 'typhoonObj', 'qbTime',
      'forecastModel', 'forecastTyphoonObj', 'typhoonListData', 'typhoonLineObj', 'modelLegend',
      'tcvitalName', 'observeTyphoonObj', 'ObserveTyphoonList', 'timeSetObj', 'observeTimeSetObj', 'timeLinePointHourList']),
    ...mapGetters('satellite', ['baseTime']),
    ...mapGetters('typhoon', ['getTimeShowDate', 'timeLinePointHourList']),

    qbDataInfoList() {
      return this.qbDataList;
    },

    qbDate: {
      get() {
        console.log('this.qbTime===>',this.qbTime)
        return this.qbTime;
      },
      set(value) {
        this.setQbTimeInfo(value);
        this.$sendPoint({
          category: 'button',
          action: '起报-天机2',
          opt_label: `${value}`,
        });
        this.$sensors.track("CurrentStartTime", {
          startTime:  `${value}`,
          space_type: this.$route.name === "earth" ? '三维' : '二维',
          page_type: '台风',
        });
        // const time = value.split('#')[0]
        // const time1 = `${time.slice(0,4)}-${time.slice(4,6)}-${time.slice(6,8)} ${time.slice(8,10)}:00:00`
        // const ms = new Date().getTime() - new Date(time1).getTime();
        // const day = ms / 1000 / 60 / 60 / 24
        // if (day > 30) {
        //   this.$sensors.track("times_of_30_days_ago", {
        //     space_type: this.$route.name === "earth" ? '三维' : '二维',
        //     page_type: '台风',
        //   });
        // }
      },
    },
  },

  watch: {
    baseTime(value) {
      this.setCurrentBaseTime(value[0]);
    },
    qbTime(value) {
      this.selectTyphoon(value);
    },
  },
  created() {
    Bus.$on('initTyphoon', this.selectTyphoon);
  },
  beforeDestroy() {
    Bus.$off('initTyphoon', this.selectTyphoon);
  },
  methods: {
    ...mapMutations('typhoon', ['setTimeLineData', 'setTimeSet', 'setCurrentDateTime',
      'setTimeLineLength', 'setTyphoonObj', 'setModelLegend', 'setStatus', 'setIndex', 'setTimeLineLength',
      'setTableListData', 'setIsMorePlay', 'setIsMorePause', 'setIsPause', 'setQbTime', 'setInitTyphoonLineObj',
      'setBeginDateTime', 'setForecastTyphoonObj', 'setInitForecastTyphoonObj', 'setForecastTyphoonListData',
      'setInitModeLegend', 'setTimeLineLengthObj', 'setTimeLineMaxLength', 'setTimeLineDate', 'setModelLegendAll',
      'setTimeLineDate', 'setTcvitalName', 'setObserveTyphoonList', 'setInitObserveTyphoonLineObj',
      'setInitObserveTyphoonList', 'setQbDataList', 'setLinePointMaxLength', 'setPointLineObj',
      'setRenderTyphoonObj', 'setInitRenderTyphoonObj', 'setCurrentDateTime', 'setTimeLineClickTime',
      'setTimeSetObj', 'setInitTimeSetObj', 'setTimeRangeData', 'setObserveTimeSetObj', 'setInitMove']),
    async selectTyphoon(value) {
      // 2023072808#KHANUN&DOKSURI
      const selectValue = value.split('#');
      const qbTime = selectValue[0];
      const nameEns = selectValue[1];
      await this.initTime();
      await this.initObserve(nameEns, qbTime);
      await this.getForecastLineInfo(qbTime);
    },
    initTime() {
      this.initTyphoon();
      Bus.$emit('closePop');
      // Bus.$emit('initTimeLine');
      if (this.typhoonLineObj && Object.keys(this.typhoonLineObj).length > 0) {
        Object.keys(this.typhoonLineObj).forEach((t) => {
          this.typhoonLineObj[t].deleteTyphoon();
          // delete this.typhoonLineObj[t];
        });
      }

      // 也需要删除实况的风圈和弹框
      if (this.observeTyphoonObj && Object.keys(this.observeTyphoonObj).length > 0) {
        Object.keys(this.observeTyphoonObj).forEach((t) => {
          this.observeTyphoonObj[t].forecastDeleteWindCircle();
        });
      }

      this.setInitTyphoonLineObj();
    },
    async initObserve(nameEn, qbTime) {
      // 是否更新观测数据
      if (this.tcvitalName !== nameEn) {
        // 先删除原来的对象
        if (this.observeTyphoonObj && Object.keys(this.observeTyphoonObj).length > 0) {
          Object.keys(this.observeTyphoonObj).forEach((t) => {
            this.observeTyphoonObj[t].deleteTyphoon();
            // delete this.observeTyphoonObj[t];
          });
        }
        this.setInitObserveTyphoonLineObj();
        this.setInitObserveTyphoonList([]);

        // 重新获取观测数据

        this.setTcvitalName(nameEn);
        // 查询观测,如果是多条，要循环
        const tcvitals = nameEn.split('&');
        const timeSet = new Set();
        const timeLineList = {};
        if (tcvitals.length > 0) {
          for (const name of tcvitals) {
            // eslint-disable-next-line no-await-in-loop
            const observeTyphoonData = await getTyphoonLatestList(`name=${name}`);
            // eslint-disable-next-line no-await-in-loop
            await this.initObserveTyphoonData(observeTyphoonData, qbTime, timeSet, timeLineList);
          }
        }
        // 找到起报时间的点
        this.observeTimeLine = {
          tcvital: timeLineList,
        };
        this.setObserveTimeSetObj(timeSet);
        this.setTimeSetObj({ key: 'tcvital', obj: timeSet });
      } else {
        this.findQbCurrentTime(qbTime);
        this.setTimeSetObj({ key: 'tcvital', obj: this.observeTimeSetObj });
      }
    },
    findQbCurrentTime(qbTime) {
      if (this.ObserveTyphoonList.length > 0) {
        this.qbObserveObj = {};
        this.ObserveTyphoonList.forEach((v) => {
          // eslint-disable-next-line no-shadow
          const qbData = v.details.filter((v) => v.forecastTimeString === qbTime);
          if (qbData && qbData.length > 0) {
            this.qbObserveObj[v.nameEn] = qbData[0];
          }
        });
      }

      // const qbData = this.ObserveTyphoonList[0].details.filter(v => v.forecastTimeString === qbTime);
      // console.log('qbData===>',qbData)
    },
    initTyphoon() {
      this.setCurrentDateTime('');
      this.setTimeLineDate('');
      this.setTimeSet(new Set());
      this.setStatus('pause');
      this.setIsPause(false);
      this.setIndex(-1);
      this.setTableListData([]);
      this.setIsMorePause('pause');
      this.setIsMorePlay(false);
      this.setInitForecastTyphoonObj();
      // this.setInitModeLegend();
      this.setInitRenderTyphoonObj();
      this.setInitTimeSetObj();
      this.setTimeRangeData({});
      this.typhoonName = '';
    },
    setQbTimeInfo(value) {
      this.setQbTime(value);
      this.setInitMove(false);
    },
    async getForecastLineInfo(qbTime) {
      // 查询天机
      // const tjTyphoonData = this.typhoonListData.filter(v => v.baseTimeString === qbTime);
      // 查询ifs
      const ifsTyphoonData = await getTyphoonList(`model=ecens&specific=${qbTime}`);
      // this.setForecastTyphoonListData({data:ifsTyphoonData,key: 'ecens'});
      // 查询eps
      const epsTyphoonData = await getTyphoonList(`model=ec52&specific=${qbTime}`);
      // this.setForecastTyphoonListData({data:epsTyphoonData,key: 'ec52'});
      // 查询gfs
      const gfsTyphoonData = await getTyphoonList(`model=gfs&specific=${qbTime}`);
      // this.setForecastTyphoonListData({data:gfsTyphoonData,key: 'gfs'});

      const data = {
        ecens: ifsTyphoonData,
        ec52: epsTyphoonData,
        gfs: gfsTyphoonData,
        sd3ens: this.typhoonListData,
      };
      const modelLegend = this.modelLegend;
      Object.keys(data).forEach((v) => {
        if (data[v].length === 0) {
          modelLegend[v].checked = false;
          modelLegend[v].windChecked = false;
          modelLegend[v].disabled = true;
        } else {
          // modelLegend[v].checked = true;
          // modelLegend[v].windChecked = true;
          modelLegend[v].disabled = false;
        }
      });
      this.setModelLegendAll(modelLegend);
      // this.setQbDataList(this.typhoonListData);
      await this.setForecastTyphoonListData(data);
      await this.selectTyphoonAllList(qbTime, this.forecastTyphoonObj);
    },
    async selectTyphoonAllList(value, forecastTyphoonObj) {
      // 遍历四个预报模式
      const timeSet = new Set();
      let timeRangeData = {};
      let timeRangeAllData = {};
      let lineLength = 0;
      let currentDateTime = '';
      const center = {};
      const lineLengthObj = {};
      const pointLineLengthObj = {};
      const fObj = forecastTyphoonObj;
      for (const k of Object.keys(fObj)) {
        const fData = fObj[k];
        const keyTimeSet = new Set();
        const timeLineList = {};
        if (fData) {
          const qbDataList = fData.qbData;
          if (qbDataList && qbDataList.length > 0) {
            const qbObj = qbDataList.filter((v) => v.key === value);
            if (qbObj && qbObj.length > 0) {
              const ids = qbObj[0].ids;
              // eslint-disable-next-line no-await-in-loop
              const data = await getTyphoonDetailList(ids);
              if (data && data.length > 0) {
                if (data[0].details.length > 0) {
                  currentDateTime = data[0].details[0].forecastTime;

                  if (k === 'sd3ens') {
                    // 获取第一个坐标点为地图中心点
                    center.lon = data[0].details[0].lon;
                    center.lat = data[0].details[0].lat;
                  }
                }
                for (const v of data) {
                  pointLineLengthObj[k + v.id] = v.details.length;
                  if (v.details.length > 0) {
                    const dateList = v.details;
                    if (dateList.length > 0) {
                      // eslint-disable-next-line no-loop-func,no-shadow
                      dateList.forEach((v, i) => {
                        const time = moment(v.forecastTime).format('YYYYMMDD');
                        const hour = moment(v.forecastTime).format('HH');
                        timeSet.add(time);
                        keyTimeSet.add(time);
                        const timeData = timeLineList[time];
                        if (timeData) {
                          // eslint-disable-next-line no-shadow
                          if (timeData.findIndex((v) => v.key === hour) === -1) {
                            lineLength++;
                            timeLineList[time].push({
                              key: hour,
                              value: v.forecastTimeString,
                              index: i,
                              lineIndex: 0,
                            });
                          }
                        } else {
                          lineLength++;
                          timeLineList[time] = [{
                            key: hour,
                            value: v.forecastTimeString,
                            index: i,
                            lineIndex: 0,
                          }];
                        }
                      });
                    }
                    // 绘制每条线，打开不带动画效果
                    // console.log('this.qbObserveObj===>',this.qbObserveObj,'k==>',k,'v.nameEn==>',v.nameEn)
                    let haveQb = false;
                    if (Object.keys(this.qbObserveObj).length > 0) {
                      if (this.qbObserveObj[v.nameEn]) {
                        v.details.unshift(this.qbObserveObj[v.nameEn]);
                        haveQb = true;
                      }
                    }
                    const renderParams = { data: v, key: k, center, haveQb };

                    if (this.modelLegend[k].checked) {
                      Bus.$emit('renderTyphoon', renderParams);
                    }
                    this.setRenderTyphoonObj({ id: k, obj: renderParams });
                  }
                  lineLengthObj[k + v.id] = v.details.length;
                }
                // this.setForecastTyphoonObj({key:k,obj:data});
              }
            }
          }
        }

        this.setTimeSetObj({ key: k, obj: keyTimeSet });
        timeRangeData[k] = timeLineList;
        timeRangeAllData = { ...timeRangeAllData, ...timeLineList };
      }

      this.setCurrentDateTime(currentDateTime);
      this.setBeginDateTime(currentDateTime);
      // 遍历timeLineList，对里面的数据进行排序
      // 需要合并观测和预报的时间列表数据，集成并集
      // this.buildTimeLineRangeData(this.observeTimeLine,timeRangeData);
      // this.buildTimeLineRangeData(timeLineList,timeLineRangeData);
      timeRangeData = { ...timeRangeData, ...this.observeTimeLine };
      // 找出距离当前小时最近的时间点
      const c_time = moment().format('YYYYMMDD');
      const c_hour = parseInt(moment().format('HH'));
      const c_timeObj = timeRangeAllData[c_time];
      let c_point = {};
      if (c_timeObj && c_timeObj.length > 0) {
        let diff = 10000;
        for (const v of c_timeObj) {
          const lineKey = parseInt(v.key);
          if (lineKey === c_hour) {
            c_point = v;
            break;
          } else if (lineKey > c_hour) {
            const diff_temp = lineKey - c_hour;
            if (diff_temp < diff) {
              c_point = v;
              diff = diff_temp;
            }
          }
        }
      }
      let maxLength = 0;
      if (Object.keys(lineLengthObj).length > 0) {
        maxLength = Math.max(...Object.values(lineLengthObj));
      }

      // 设置可选择时间区间
      let unionTimeSet = new Set();
      Object.keys(this.timeSetObj).forEach((k) => {
        if (this.modelLegend[k].checked) {
          unionTimeSet = new Set([...unionTimeSet, ...this.timeSetObj[k]]);
        }
      });
      this.setTimeRangeData(timeRangeData);
      this.setLinePointMaxLength(maxLength);
      this.setTimeLineData(timeRangeAllData);
      this.setTimeSet(unionTimeSet);
      this.setTimeLineLength(lineLength);
      this.setTimeLineMaxLength(lineLength);
      this.setTimeLineLengthObj(lineLengthObj);
      this.setPointLineObj(pointLineLengthObj);

      if (sessionStorage.getItem('nowTime')) {
        const dateString = sessionStorage.getItem('nowTime');
        const formattedDateString = moment(dateString).format('YYYYMMDDHH');

        const arr = [];

        const time = this.findClosest([...unionTimeSet], formattedDateString.slice(0, -2));
        this.setCurrentDateTime(time);

        const item = timeRangeAllData[time];
        item ? item.forEach((_item) => {
          arr.push(_item.value);
        }) : '';

        let times;
        if (Number(formattedDateString.slice(0, -2)) < Number([...unionTimeSet][0])) {
          times = this.timeLinePointHourList[0];
        } else if (Number(formattedDateString.slice(0, -2)) > Number([...unionTimeSet][[...unionTimeSet].length - 1])) {
          times = this.timeLinePointHourList[this.timeLinePointHourList.length - 1];
        } else {
          times = this.findClosest(this.timeLinePointHourList, formattedDateString.slice(-2));
        }

        // console.log('times', times, this.getTimeShowDate, this.timeLinePointHourList, unionTimeSet, Number(formattedDateString.slice(0, -2)), Object.values(unionTimeSet), [...unionTimeSet]);

        setTimeout(() => {
          Bus.$emit('tiggerTimeLineClick', times ? times.slice(-2) : '02');
        }, 1000);
      } else {
        this.setCurrentDateTime(c_time);
        if (Object.keys(c_point).length > 0) {
          this.setTimeLineClickTime(c_point.value);
          setTimeout(() => {
            Bus.$emit('tiggerTimeLineClick', c_point.value);
          }, 1000);
        }
      }
    },

    findClosest(arr, target) {
      let closest = arr[0];
      let minDiff = Math.abs(target - closest);

      for (let i = 1; i < arr.length; i++) {
        const current = arr[i];
        if (current === target) {
          return current;
        }
        const diff = Math.abs(target - current);
        if (diff < minDiff) {
          closest = current;
          minDiff = diff;
        }
      }

      return closest;
    },

    async initObserveTyphoonData(observeTyphoonData, qbTime, timeSet, timeLineList) {
      if (observeTyphoonData && observeTyphoonData.length > 0) {
        // 获取观测的详细信息
        let ids = '';
        const details = observeTyphoonData[0].details;
        if (details.length > 0) {
          details.forEach((v) => {
            ids = `${ids + v.id},`;
          });
        }
        if (ids !== '') {
          const detailsInfo = await getTyphoonDetailList(ids);
          if (detailsInfo && detailsInfo.length > 0 && detailsInfo[0].details.length > 0) {
            // 对日期进行排序
            // detailsInfo[0].details.sort((a,b) => parseInt(a.forecastTimeString) - parseInt(b.forecastTimeString));
            // detailsInfo.forEach(info => {
            //   this.setObserveTyphoonList(info);
            // })
            // this.findQbCurrentTime(qbTime);
            // Bus.$emit('renderObserveTyphoon');
            for (const info of detailsInfo) {
              this.setObserveTyphoonList(info);
              Bus.$emit('renderObserveTyphoon', info);

              const dateList = info.details;
              if (dateList.length > 0) {
                dateList.forEach((v, i) => {
                  const time = moment(v.forecastTime).format('YYYYMMDD');
                  const hour = moment(v.forecastTime).format('HH');
                  timeSet.add(time);
                  const timeData = timeLineList[time];
                  if (timeData) {
                    // eslint-disable-next-line no-shadow
                    if (timeData.findIndex((v) => v.key === hour) === -1) {
                      timeLineList[time].push({
                        key: hour,
                        value: v.forecastTimeString,
                        index: i,
                        lineIndex: 0,
                      });
                    }
                  } else {
                    timeLineList[time] = [{
                      key: hour,
                      value: v.forecastTimeString,
                      index: i,
                      lineIndex: 0,
                    }];
                  }
                });
              }
            }
            this.findQbCurrentTime(qbTime);
          }
        }
      }
    },

    buildTimeLineRangeData(timeLineObjData, timeLineRangeData) {
      Object.keys(timeLineObjData).forEach((v) => {
        const data = timeLineRangeData[v];
        const obj = timeLineObjData[v];
        if (data) {
          obj.forEach((k) => {
            if (data.findIndex((d) => d.key === k.key) === -1) {
              // 没找到，则加入
              data.push(k);
            }
          });
        } else {
          timeLineRangeData[v] = obj;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  height: 30px;
  //padding: 0 5px 0 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .label {
    font-size: 12px;
    color: #fff;
    width: 30px;
    //margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 26px;
      }

      .el-input__inner {
        color: #ffffff;
        border-radius: 4px;
        border-color: #ffffff;
      }
    }
  }
}
</style>
