import _ from 'lodash';
import moment from 'moment';
import { getViewPortHeight } from '@/utils';
import { productionMap } from '@/config/MapConfig';
import { TIANJI2_DA, TIANJI2_ND, TIANJI2_MERGE } from '@/config';
import {filterTimeList,determineFactor} from "@/config/Utils";

function isMobileFn() {
  const mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  return !!mobile;
}

const mobile = isMobileFn();

export default {
  namespaced: true,
  state: () => ({
    t2Mode: TIANJI2_MERGE,
    aliveData: {
      isAlive: false,
    },
    viewHeight: getViewPortHeight(),
    // 当前要素
    factor: localStorage.getItem('factor') || 'pratesfc',
    // 当前播放状态
    status: 'pause',
    // 是否压缩 默认是
    hd: localStorage.getItem('compress-layer') || 'normal',
    // hd: 'none',
    // timeType: localStorage.getItem('timeType') ? JSON.parse(localStorage.getItem('timeType')) : {
    //   type: 1,
    //   timeLength: 24,
    // },

    timeType:{
      type: 1,
      timeLength: 24,
    },
    timeTypeMap: {},
    // 当前风场是否展示
    windy: mobile ? 'none' : (localStorage.getItem('windy') || 'none'),
    // 当前slp图层是否展示
    isobar: mobile ? 'none' : (localStorage.getItem('isoline-slp') || 'none'),
    cityLayer: localStorage.getItem('city-layer') || 'none',
    // 当前 天地图（world）图层是否展示、
    worldLayer: mobile ? 'normal' : 'none',
    // 当前 影像底图（shadow）图层是否展示、
    shadowLayer: mobile ? 'none' : 'normal',
    // 地形图还是影像图
    layerType: 'default',
    // 当前起报时间
    currentBaseTime: '',
    // 当前要素对应的数据
    factorData: [],
    // 当前图层时间列表
    timeList: [],
    // 当前时间表对应的索引
    index: '',
    pixelValue: '',
    /**
     * @description 地图当前可视区域
     * @type {LatLngBounds}
     */
    bounds: null,
    // 风场数据
    windData: [],
    // 地图中心位置
    mapCenter: '',
    // 当前的模式
    model: 'early',
    // 城市图层是否展示
    city: localStorage.getItem('city-layer') || 'normal',
    // t3区域列表
    regionList: [],
    // t3当前区域
    region: {},
    // 高程
    elevation: '',
    // 是否加载中
    loading: false,
    // 移动端单点弹窗是否显示
    isPopupShow: false,
    // 当前要素名称
    factorName: '',
    // 当前单点弹窗展示值
    popupVal: '',
    // 当前单点弹窗展示单位
    popupUnit: '',
    // 时间间隔
    timeGap: '1小时',
    // 单点经纬度
    latlng: ''
  }),
  getters: {

    isT2Mode(state) {
      return state.t2Mode === TIANJI2_DA || state.t2Mode === TIANJI2_ND;
    },
    currentMode(state, getters) {
      return getters.isT2Mode ? undefined : productionMap[state.factor];
    },
    // 起报时间列表
    baseTime(state) {
      return state.factorData.map((item) => item.baseTimeModel);
    },
    // 要素数据根据baseTime生成hashmap
    factorDataMap(state) {
      return state.factorData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    windDataMap(state) {
      return state.windData.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },

    // 当前起报时间对应的详情数据
    currentData(state, getters) {
      return getters.factorDataMap[state.currentBaseTime] ?? {};
    },

    currentWindData(state, getters) {
      return getters.windDataMap[state.currentBaseTime] ?? {};
    },

    // 当前图层详情
    // TODO：根据图层优先级选择优先级最高的图层
    currentLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      // return filter[0] ?? {};
      return getters.currentData;
    },

    currentWindyId(state, getters) {
      const { ingestions = [] } = getters.currentLayerDetails;
      const filter = ingestions.filter((item) => item.forecastTime === getters.currentTime && item.elevation === state.elevation);
      return filter[0]?.id;
    },

    currentWindLayerDetails(state, getters) {
      // const { layers = [] } = getters.currentWindData;
      // const filter = layers.filter((item) => isContains(state.bounds, item));
      return getters.currentWindData;
    },

    // 当前图层对应的时间列表
    timeList(state, getters) {
      let { forecast = [] } = getters.currentLayerDetails;
      // if(localStorage.getItem('factor') !== 'precp') {
      //   forecast = forecast.reduce((target, item) => {
      //     for (let i = 1; i <= 24; i++) {
      //       target.push({
      //         forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
      //         forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
      //         randomPath: item.randomPath,
      //         // factorType: factorType
      //       });
      //     }
      //     return target;
      //   }, []);
      // }
      forecast = forecast.reduce((target, item) => {
        for (let i = 1; i <= 24; i++) {
          target.push({
            forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
            forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
            randomPath: item.randomPath,
            // factorType: factorType
          });
        }
        return target;
      }, []);
      // 筛选函数
      // 函数用于计算两个时间点之间的差值（小时）
      const filteredTimeArray3 = filterTimeList(forecast, 3);
      const filteredTimeArray6 = filterTimeList(forecast, 6);
      const filteredTimeArray24 = filterTimeList(forecast, 24);

      state.timeTypeMap = {
        1: forecast,
        3: filteredTimeArray3,
        6: filteredTimeArray6,
        // 12: filteredTimeArray12,
        24: filteredTimeArray24,
      }
      return _.uniqBy(state.timeTypeMap[state.timeType.type], 'forecastTime');
    },


    windTimeList(state, getters) {
      let { forecast = [] } = getters.currentWindLayerDetails;
      forecast = forecast.reduce((target, item) => {
        for (let i = 1; i <= 24; i++) {
          if (item.umin || item.umax || item.vmin || item.vmax) {
            target.push({
              forecastTime: moment(item.forecastTime).add(i, 'hour').format(),
              forecastTimeString: moment(item.forecastTime).add(i, 'hour').format('YYYYMMDDHH'),
              randomPath: item.randomPath,
              umin: item.umin[i - 1] * 16,
              umax: item.umax[i - 1] * 16,
              vmin: item.vmin[i - 1] * 16,
              vmax: item.vmax[i - 1] * 16,
            });
          }
        }
        return target;
      }, []);
      return _.uniqBy(forecast, 'forecastTime');
    },

    windTimeMap(state, getters) {
      return getters.windTimeList.reduce((target, item) => {
        target[item.forecastTime] = item;
        return target;
      }, {});
    },
    // 当前图层对应时间详情
    current(state, getters) {
      if (!getters.timeList.length || state.index > getters.timeList.length - 1 || state.index < 0) {
        state.index = 0;
        return getters.timeList[0] ?? {};
      }
      return getters.timeList[state.index] ?? {};
    },
    allDataIndex(state, getters) {
      //原有的时间列表和选中时间
      const oldList = state.timeTypeMap[state.timeType.type];
      const NowTime = state.timeTypeMap[state.timeType.type][state.index].forecastTime;

      //从现有的时间列表找最相近的时间
      const NewList = state.timeTypeMap[1];

      const targetDate = new Date(NowTime); // 转换目标时间为 Date 对象
      let minDifference = Infinity; // 初始化最小差值为正无穷
      let closestIndex = -1; // 初始化最相近时间的索引为 -1

      NewList.forEach((timeObj, index) => {
        const time = new Date(timeObj.forecastTime);
        const difference = Math.abs(time - targetDate); // 计算当前时间与目标时间的差值

        if (difference === 0) {
          closestIndex = index; // 如果差值为 0，说明找到了相同的时间，更新索引并跳出循环
          minDifference = 0; // 更新最小差值
          return;
        }

        if (difference < minDifference) {
          minDifference = difference; // 更新最小差值
          closestIndex = index; // 更新最相近时间的索引
        }
      });

      return closestIndex;
    },
    // 在 Vuex 的 getters 中定义一个接收参数的函数
    playCurrent: (state, getters) => (index) => {
      // 在函数体内使用参数 parameter
      if (!getters.timeList.length || index > getters.timeList.length - 1 || index < 0) {
        index = 0;
        return getters.timeList[0] ?? {};
      }
      return getters.timeList[index] ?? {};
    },

    windMeta(state, getters) {
      return getters.windTimeMap[getters.currentTime] ?? null;
    },
    // 当前选择时间
    currentTime(state, getters) {
      return getters.current.forecastTime;
    },

    elevationList(state, getters) {
      return getters.currentLayerDetails.elevation ?? [];
    },
  },
  mutations: {
    setMode(state, val) {
      state.t2Mode = val;
    },
    setTimeType(state, val) {
      if (!state.timeTypeMap[state.timeType.type] || !state.timeTypeMap[state.timeType.type][state.index]) {
        // localStorage.setItem('timeType', JSON.stringify(val));
        state.timeType = val;
        return;
      }
      //原有的时间列表和选中时间
      const oldList = state.timeTypeMap[state.timeType.type];
      const oldTime = state.timeTypeMap[state.timeType.type][state.index].forecastTime;
      //从现有的时间列表找最相近的时间
      const NewList = state.timeTypeMap[val.type];

      const targetDate = new Date(oldTime); // 转换目标时间为 Date 对象
      let minDifference = Infinity; // 初始化最小差值为正无穷
      let closestIndex = -1; // 初始化最相近时间的索引为 -1

      NewList.forEach((timeObj, index) => {
        const time = new Date(timeObj.forecastTime);
        const difference = Math.abs(time - targetDate); // 计算当前时间与目标时间的差值

        if (difference === 0) {
          closestIndex = index; // 如果差值为 0，说明找到了相同的时间，更新索引并跳出循环
          minDifference = 0; // 更新最小差值
          return;
        }

        if (difference < minDifference) {
          minDifference = difference; // 更新最小差值
          closestIndex = index; // 更新最相近时间的索引
        }
      });

      state.index = closestIndex;
      // console.log('state.index', state.index);
      // localStorage.setItem('timeType', JSON.stringify(val));
      state.timeType = val;
    },
    setViewHeight(state, value) {
      state.viewHeight = value;
    },
    setAlive(state, value) {
      state.aliveData = value;
    },
    setCenter(state, val) {
      state.mapCenter = val;
    },
    // 设置当前要素
    setFactor(state, value) {
      state.factor = value || 'pratesfc';
    },
    // 设置当前要素数据列表
    setFactorData(state, value) {
      state.factorData = value.map((item) => {
        const str = item.mode === 'early' ? 'DA' : 'ND';
        item.baseTimeModel = `${item.baseTime} ${str}`;
        return item;
      });

      const elevationList = this.getters['map/elevationList'];

      if (!elevationList.length) {
        state.elevation = '';
      } else if (!elevationList.includes(state.elevation)) {
        state.elevation = elevationList[elevationList.length - 1];
      }
    },
    // 设置当前播放状态
    setStatus(state, value) {
      state.status = value;
    },
    // 设置当前起报时间
    setCurrentBaseTime(state, value) {
      state.currentBaseTime = value;
      sessionStorage.setItem('baseTime', state.currentBaseTime.split(' ')[0]);

      const elevationList = this.getters['map/elevationList'];

      if (!elevationList.length) {
        state.elevation = '';
      } else if (!elevationList.includes(state.elevation)) {
        state.elevation = elevationList[0];
      }
      const timeList = this.getters['map/timeList'];
      const flagIndex = timeList.findIndex((item) => Date.parse(item.forecastTime) === Date.parse(sessionStorage.getItem('nowTime')));

      if (flagIndex !== -1) {
        state.index = flagIndex;
      }
      sessionStorage.setItem('nowTime', this.getters['map/current'].forecastTime);
    },
    // 设置是否高精度
    setHD(state, value) {
      state.hd = value;
    },
    // 设置当前风场是否展示
    setWindy(state, value) {
      state.windy = value;
    },
    // 设置当前slp图层是否展示
    setIsobar(state, value) {
      state.isobar = value;
    },
    // 设置当前的地形图
    setMapLayer(state, value) {
      state.worldLayer = value;
      state.shadowLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前的地形图
    setLayerType(state, value) {
      state.layerType = value;
    },
    setShadowLayer(state, value) {
      state.shadowLayer = value;
      state.worldLayer = value === 'normal' ? 'none' : 'normal';
    },
    // 设置当前起报时间对应的预报时间的索引
    setIndex(state, value) {
      state.index = value;
      sessionStorage.setItem('nowTime', this.getters['map/current'].forecastTime);
    },

    setPixelValue(state, value) {
      state.pixelValue = value;
    },

    // 设置当前可视区域
    setBounds(state, value) {
      state.bounds = value;
    },
    // 设置风场数据
    setWindData(state, value) {
      state.windData = value.map((item) => {
        const str = item.mode === 'early' ? 'DA' : 'ND';
        item.baseTimeModel = `${item.baseTime} ${str}`;
        return item;
      });
    },
    // 设置城市图层是否展示值
    setCity(state, value) {
      state.city = value;
    },

    setRegionList(state, value) {
      state.regionList = value;
    },

    setRegion(state, value) {
      state.region = value;
    },

    setElevation(state, value) {
      state.elevation = value;
    },

    setLoading(state, value) {
      state.loading = value
    },

    setPopupShow(state, value) {
      state.isPopupShow = value
    },

    setFactorName(state, value) {
      state.factorName = value
    },

    setPopupVal(state, value) {
      state.popupVal = value
    },

    setPopupUnit(state, value) {
      state.popupUnit = value
    },

    setTimeGap(state, value) {
      state.timeGap = value
    },

    setLatlng(state, value) {
      state.latlng = value
    },
  },
};
