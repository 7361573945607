import {getGradientColor, getIndex} from "@/config/Utils";

class ColorRangeMaker {
  getGradientColor(name, value) {
    return getGradientColor(name,value)
  }

  getIndex(value, allValue) {
    return getIndex(value, allValue)
  }

}

export default ColorRangeMaker;
