<template>
	<!-- 底部时间轴 -->
  <div class="timeLineBox">
    <div class="timeLineBox_tip">
      <div class="timeTip flex-col"><span class="txt4">{{ current_time }}</span></div>
      <div class="arrow"></div>
    </div>
    <div class="timeLine flex-col justify-between">
      <div class="section9 flex-col">
        <div class="time_line_data_box flex-col" ref="timeLineContent">
          <div class="drag_box flex-row"
               :style="`width: ${drag_div_width}px;left : ${drag_offset_left}px`">
            <!-- 当前时刻竖线 -->
            <div class="current_time_line" :style="`left:${current_width * 25 - 14}px`"></div>
            <template v-if="timeGap !== '24小时'">
              <div v-for="(data,key, index_) in time_line_data"
                   :style="`width: ${data.length * 25}px;`"
                   :class="index_ % 2 === 0 ? 'time_line_single_box_1' : 'time_line_single_box_2'">
                <div class="word3" v-if="data.length > 3">{{ key }}</div>
                <div class="word3" v-else></div>
                <!-- 小时 -->
                <div class="data_box flex-row">
                  <template v-for="(item,index) in data">

                    <template v-if="(index % 3 === 0 && timeGap==='1小时') || timeGap!=='1小时'">
                      <div class="time_point">{{ item }}</div>
                    </template>
                    <template v-else>
                      <div class="time_point">
                        <div class="circle"></div>
                      </div>
                    </template>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="date_line_single_box">
                <div v-for="(data,key) in time_line_data">
                  <div class="data_box flex-row">
                    <div class="flex-clumn" style="width:25px;font-size: 9px;text-align: center;">
                      <div>{{ getDate(key).substring(0,2) }}</div>
                      <div>{{ getDate(key).substring(3,5) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div class="play">
      <v-icon-word
        v-show="!isPlay"
        icon="play"
        desc="播放"
        spec
        @click.native="play(true)"
      />
      <v-icon-word
        v-show="isPlay"
        icon="pause"
        desc="暂停"
        spec
        @click.native="play(false)"
      />
      <div class="now" @click="toNow">
        现在
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { Toast } from 'vant'
import Bus from '@/bus/index';
import VIconWord from "@/components/Map/IconWord.vue";

export default {
  components: {
    VIconWord,
  },
  data() {
    return {
      time_line_data: [],
      activeIndex: 0,
      current_width: 0,
      rent_width_pos: 0,
      data_total_width: 0,
      current_time: moment().format('HH') + ':00',
      current_time_temp: moment().format('YYYYMMDDHH'),
      current_time_temp_pre: moment().format('YYYYMMDDHH'),
      current_time_index: 0,
      time_line_hour_data: [],
      drag_div_width: '100vw',
      drag_offset_left: 0,
      isPlay: false,
      start_x: 0,
      timer: ''
    }
  },
  computed: {
    ...mapState('map', ['currentBaseTime', 'timeGap']),
    ...mapState('map', ['factor']),
    ...mapState(['isMobile']),
    ...mapGetters('map', ['currentTime', 'timeList', 'elevationList']),
    isVertical() {
      return this.screenSize.width < 800;
    },
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
    timeGapNum() {
      return this.timeGap.length === 3 ? Number(this.timeGap.substring(0, 1)) : Number(this.timeGap.substring(0, 2))
    }
  },
  watch: {
    current_time_temp(val) {
      this.setTime(val)
    },
    timeList() {
      this.buildTimeLineData()
    },
  },
  created() {
    Bus.$on('buildTimeLineData', this.buildTimeLineData)
  },
  activated() {
    this.changeScrollLeft()
  },
  beforeUnmount() {
    clearTimeout(this.timer)
  },
  mounted(){
    //在mounted钩子函数绑定滚动条事件
    this.$refs.timeLineContent.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    Bus.$off("buildTimeLineData", this.buildTimeLineData);
  },
  methods: {
    ...mapMutations('satellite', ['setSatelliteData', 'setScreenSize']),
    ...mapMutations('map', ['setStatus', 'setIndex', 'setWindy', 'setCurrentTime', 'setCity']),
    getDate(date) {
      return `${date.substring(5,7)}.${date.substring(8,10)}`
    },
    buildTimeLineData() {
      const date_ = {}
      const hour_data = []
      const current_time = moment().format('YYYYMMDDHH')
      let index = 1
      let count = 0
      this.timeList.forEach((item, inde) => {
        const day_time_str = moment(moment(item.forecastTimeString, 'YYYYMMDDHH').toDate())
        // console.log(day_time_str, 'day_time_str')
        const time_ymd = day_time_str.format('YYYY-MM-DD')
        const time_d = day_time_str.format('HH')
        if (this.timeGap === '1小时') {
          hour_data.push({
            day: day_time_str,
            hour: time_d
          })
        }
        if (this.timeGap === '3小时') {
          if (Number(time_d) % 3 === 0) {
            hour_data.push({
              day: day_time_str,
              hour: time_d
            })
          }
        }
        if (this.timeGap === '6小时') {
          if (Number(time_d) % 6 === 0) {
            hour_data.push({
              day: day_time_str,
              hour: time_d
            })
          }
        }
        if (this.timeGap === '24小时') {
          if (time_d == '00') {
            hour_data.push({
              day: day_time_str,
              hour: time_d
            })
          }
        }
        if (date_[time_ymd]) {
          if (this.timeGap === '1小时') date_[time_ymd].push(time_d)
          if (this.timeGap === '3小时') {
            if (Number(time_d) % 3 === 0) {
              date_[time_ymd].push(time_d)
            }
          }
          if (this.timeGap === '6小时') {
            if (Number(time_d) % 6 === 0) {
              date_[time_ymd].push(time_d)
            }
          }
          // if (this.timeGap === '24小时') {
          //   if (time_d == '00') {
          //     date_[time_ymd].push(time_d)
          //   }
          // }
        } else {
          date_[time_ymd] = [time_d]
        }
        if (current_time === day_time_str.format('YYYYMMDDHH')) {
          count = index
        } else {
          if (count === 0) {
            if (this.timeGap === '1小时') index += 1
            if (this.timeGap === '3小时') {
              if (Number(time_d) % 3 === 0) {
                index += 1
              }
            }
            if (this.timeGap === '6小时') {
              if (Number(time_d) % 6 === 0) {
                index += 1
              }
            }
            if (this.timeGap === '24小时') {
              if (time_d == '00') {
                index += 1
              }
            }
          }
        }
      })

      this.time_line_data = date_
      this.time_line_hour_data = hour_data
      this.current_width = count
      this.drag_offset_left = document.documentElement.clientWidth / 2
      this.drag_div_width = hour_data.length * 25 + document.documentElement.clientWidth / 2
      this.data_total_width = hour_data.length * 25
      this.$nextTick(() => {
        if (this.timeGap === '6小时') {
          for(let item in this.time_line_data) {
            if(this.time_line_data[item].length <= 1) {
              this.time_line_data[item] = []
            }
            if(this.time_line_data[item][0] === '09') {
              this.time_line_data[item].splice(0, 1)
            }
          }
        }
        if (this.timeGap === '24小时') {
          const arr = Object.keys(this.time_line_data)
          delete this.time_line_data[arr[0]]
          delete this.time_line_data[arr.length - 1]
        }
        const pos = (count * 25 - 12.5)
        this.$refs.timeLineContent.scrollLeft = pos
        this.current_width_pos = pos
      });
    },
    dragTimeLine(el) {
      const start_x = el.clientX
      const offset_left = this.drag_offset_left
      document.onmousemove = e => {
        const offset_ = offset_left + e.clientX - start_x
        if (offset_ < document.documentElement.clientWidth / 2 &&
            offset_ > (document.documentElement.clientWidth / 2 - this.time_line_hour_data.length * 25)) {
          this.drag_offset_left = offset_
          this.setMoveCurTime(offset_)
        }
      }
    },
    // 设置滑动中的时间
    setMoveCurTime(offset_) {
      // 找到所在的时间
      const time_box_offset = document.documentElement.clientWidth / 2 - offset_
      const index = Math.round((time_box_offset + 12.5) / 25)
      const move_cur_time = this.time_line_hour_data[index - 1]
      this.current_time = move_cur_time.hour + ':00'
      this.current_time_temp = move_cur_time.day
    },
    // 清除鼠标事件
    clearEvent() {
      document.onmousemove = null
      document.onmouseup = null
    },
    goToFrame(index) {
      Bus.$emit('clearLayer', 'wind');
      Bus.$emit('clearLayer', 'isobar');
      this.setIndex(index);
    },
    setTime(cur_time) {
      const time = moment(cur_time).format('YYYY-MM-DDTHH:mm:ss+08:00')

      if (time in this.timeMap) {
        this.goToFrame(this.timeMap[time]);
      }
    },
    play(isPlay) {
      if (isPlay) {
        // 播放
        this.timer = setInterval(() => {
          if(this.current_width_pos < 0) this.current_width_pos = 0
          if (this.current_width_pos >= 0 && this.current_width_pos < this.data_total_width) {
            this.current_width_pos = this.current_width_pos + 25
            this.$refs.timeLineContent.scrollLeft = this.current_width_pos
            this.handleScroll()
          } else {
            clearInterval(this.timer)
          }
        }, 1000)
      } else {
        clearInterval(this.timer)
      }
      this.isPlay = isPlay
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split("T")[0].split("-");
      const time = base.split("T")[1].split(":");
      const now = new Date();

      date[1] = `0${now.getMonth() + 1}`.slice(-2);
      date[2] = `0${now.getDate()}`.slice(-2);

      time[0] = `0${Number(now.getHours()) + 1}`.slice(-2);

      return `${date.join("-")}T${time.join(":")}`;
    },
    toNow() {
      const now = this.getNowHasBaseTime();
      // const nowTimestamp = Date.parse(now);
      const flagIndex = this.timeList.findIndex(
        (item) => Date.parse(item.forecastTime) === Date.parse(now)
      );
      if (flagIndex === -1) {
        this.$refs.timeLineContent.scrollLeft = 1000000
        this.handleScroll()
        return;
      }
      this.$refs.timeLineContent.scrollLeft = this.current_width * 25 - 12.5
      this.handleScroll()
    },
    start(e) {
      this.start_x = e.changedTouches[0].clientX
    },
    moveTimeLine(e) {
      const offset_left = this.drag_offset_left
      const offset_ = offset_left + e.changedTouches[0].clientX - this.start_x
      if (offset_ < document.documentElement.clientWidth / 2 &&
          offset_ > (document.documentElement.clientWidth / 2 - this.time_line_hour_data.length * 25)) {
        this.drag_offset_left = offset_
        this.setMoveCurTime(offset_)
      }
    },
    handleScroll() {
      let timer = null
      if (timer) return

      timer = setInterval(()=>{
        if (this.$refs.timeLineContent && this.$refs.timeLineContent.scrollLeft){
          const l = this.$refs.timeLineContent.scrollLeft
          let index = Math.round((l + 12.5) / 25) - 1
          if (index >= this.time_line_hour_data.length - 1) {
            index = this.time_line_hour_data.length - 1
          }
          const move_cur_time = this.time_line_hour_data[index]
          this.current_time = this.timeGap === '24小时' ? moment(move_cur_time.day).format('MM.DD') : move_cur_time.hour + ':00'
          this.current_time_temp = move_cur_time.day
          this.current_width_pos = l
          clearInterval(timer)
        }
      }, 300)
    },
    changeScrollLeft() {
      this.$nextTick(() => {
        this.$refs.timeLineContent.scrollLeft = this.current_width_pos
      })
    },
    formatTime(value) {
		  return moment(value).format('YYYY-MM-DD HH:mm:ss');
		},
    buildTime(value) {
      if (value < 10) {
        return '0'+ value
      }
      return value
    }
  }
}

</script>

<style scoped lang="scss">
.timeLineBox {
  width: 100%;
  position: absolute;
  bottom: 30px;
  z-index: 400;

  .timeLineBox_tip {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;

    .arrow {
      margin-top: -1px;
      width: 0;
      height: 0;
      border-radius: 4px;
      //border-top: 12px solid rgba(50, 50, 50, 0.5);
      border-top: 12px solid #027aff;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
    }
  }

  .timeTip{
    width: 50px;
    height: 20px;
    background: #027aff;
    border-radius: 15px;
    backdrop-filter: blur(1px);
    color: white;
    .txt4 {
      width: 32px;
      height: 12px;
      font-size: 12px;
      text-align: left;
      white-space: nowrap;
      line-height: 12px;
      margin: 4px 0 0 10px;
      font-weight: bold;
    }
  }

  .timeLine {
    background-color: #ffffff;
    .section9 {
      width: 100%;
      height: 50px;
      .main8 {
        width: 65px;
        height: 9px;
        margin-top: 23px;
      }
      .main9 {
        z-index: 5;
        position: relative;
        width: 50%;
        height: 20px;
        background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngacc26d55ce64629f11e0a1929f7c45bf71a91baaaa054dbefc11a605b5db2469) 100% no-repeat;
        background-size: 100% 100%;
      }
      .word3 {
        height: 12px;
        color: #FFFFFF;
        font-size: 10px;
        text-align: center;
        white-space: nowrap;
        line-height: 12px;
        margin: 5px 0 0 5px;
      }
      .section10 {
        background-color: rgba(87, 127, 255, 1);
        z-index: 64;
        position: absolute;
        left: -1px;
        top: 0;
        width: 2px;
        height: 20px;
      }
      .time_line_data_box {
        z-index: 8;
        position: relative;
        font-size: 13px;
        height: 50px;
        padding-bottom: 10px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        background-color: #222;
        opacity: 0.8;
        overflow-y: hidden;
        overflow-x: scroll;
        .data_box {
          height: 21px;
        }

        .day_box {
          border-right: 2px solid ;
        }

        .time_line_single_box_1 {
          background-color: #333333;
        }
        .time_line_single_box_2 {
          background-color: #000;
          opacity: 0.8;;
        }
        .date_line_single_box {
          padding-top: 10px;
          display: flex;
          flex-direction: row;
        }

        .drag_box {
          position: relative;
        }

        .current_time_line {
          position: relative;
          height: 40px;
          width: 2px;
          background-color: rgba(86, 131, 252, 1);
          z-index: 9;
          div {
            width: 30px;
            position: absolute;
            top: 18px;
            left: -10.5px;
            font-size: 10px;
            color: #fff;
            opacity: 1;
            z-index: 9;
          }
        }

        .time_point {
          width: 20px;
          height: 20px;
          font-size: 12px;
          text-align: center;
          padding: 0 5px 0 5px;
          .circle {
            position: relative;
            width: 3px;
            height: 3px;
            background-color: #fff;
            border-radius: 50px;
            top: 23%;
            left: 23%;
          }
        }

        .time_point_active {
          width: 20px;
          height: 20px;
          background-color: blue;
          border-radius: 50px;
          color: white;
          text-align: center;
        }
      }

      .section11::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.play {
  z-index: 66;
  position: absolute;
  left: 0px;
  top: 34.5px;
  width: 90px;
  height: 40px;
  color: rgb(87, 127, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999999;
  background-color: #333;

  .now {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    font-size: 10px;
    color: #fff;
    height: 24px;
    width: 40px;
    background: #027aff;
    margin-right: 8px;
  }
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.justify-start {
  display: flex;
  justify-content: flex-start;
}
.justify-center {
  display: flex;
  justify-content: center;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}
.justify-evenly {
  display: flex;
  justify-content: space-evenly;
}
.justify-around {
  display: flex;
  justify-content: space-around;
}
.justify-between {
  display: flex;
  justify-content: space-between;
}
.align-start {
  display: flex;
  align-items: flex-start;
}
.align-center {
  display: flex;
  align-items: center;
}
.align-end {
  display: flex;
  align-items: flex-end;
}
</style>
