<template>
  <div style="height: 30px; width: 100%; margin-left: 20px; margin-top: 22px">
    <div class="timeline">
      <div
        class="target"
        :style="{
          left: `${indexFlag * (100 / (timeType.timeLength - 1)) + 0.2}%`,
        }"
      >
        <div class="box" />

        <div
          v-if="date"
          class="datetime"
          :class="{ last_tip: isLast || indexFlag === timeType.timeLength - 1 }"
        >
          {{ date }}
        </div>
      </div>
      <div class="time_wrap">
        <div
          v-for="i in timeType.timeLength"
          :key="i"
          class="time_item"
          :class="{
            active: i === indexFlag + 1,
            animation: i <= indexFlag,
          }"
          :style="canClick(i, timeType.type)"
        >
          <div class="clickDiv" @click="timeClick(i)" :style="canClick(i, timeType.type)"/>
          <div
            class="circle"
            :class="{
              animation: i <= indexFlag,
            }"
            :style="canClick(i, timeType.type)"
          />
          <div v-if="i % 2 || timeType.type > 1" class="word satellite" :style="canClick(i, timeType.type, 'word')">
            {{ (i - 1) | getTime(timeType.type) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";

export default {
  filters: {
    getTime(value, type) {
      if(type === 3){
        return `${2+value*type < 10 ? `0${2+value*type}` : 2+value*type}:00`;
      }else if(type === 6){
        return `${2+value*type < 10 ? `0${2+value*type}` : 2+value*type}:00`;
      }
      return `${value * type < 10 ? `0${value * type}` : value * type}:00`;
    },
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isClick: false,
      isLast: false,
    };
  },
  computed: {
    ...mapState(["currentData"]),
    ...mapState("map", ["status", "currentBaseTime"]),
    ...mapGetters("map", ["currentTime","timeList"]),
    ...mapGetters("satellite", ["currentSatelliteTime","currentSatelliteBaseTime"]),
    indexFlag() {
      return this.index;
    },
    timeType() {
      if (this.modelType === "t1") {
        return this.$store.state["timeType"];
      } else if (this.modelType === "t2") {
        return this.$store.state["map"]["timeType"];
      } else {
        return {
          type: 1,
          timeLength: 24,
        };
      }
    },
    date() {
      if (this.modelType === "t2" && this.currentTime) {
        return moment(this.currentTime).format("YYYY-MM-DD HH:00");
      }
      if (this.modelType === "t1" && this.currentData && this.currentData.timestamp) {
        return moment(this.currentData.timestamp).format("YYYY-MM-DD HH:00");
      }
      if (this.modelType === "satellite" && this.currentSatelliteTime) {
        return moment(this.currentSatelliteTime).format("YYYY-MM-DD HH:00");
      }
      return "";
    },
    currentStartTime() {
      let time = ''
      if (this.modelType === "satellite") {
        time = moment(this.currentSatelliteBaseTime).format("YYYY-MM-DD HH:00")
        return time
      } else {
        time = (this.currentBaseTime ? this.currentBaseTime.split(' ')[0] : '') || sessionStorage.getItem('baseTime')
        if (this.modelType === 't1' && this.currentData && this.currentData.imagePath){
          const t1BaseTime = this.currentData.imagePath.split(":")[3]
          // 拆分字符串
          let year = t1BaseTime.substring(0, 4);
          let month = t1BaseTime.substring(4, 6);
          let day = t1BaseTime.substring(6, 8);
          let hour = t1BaseTime.substring(8, 10);
          return `${year}-${month}-${day} ${hour}:00`;
        }
        return moment(time).format("YYYY-MM-DD HH:00");
      }
    },
    lastTime(){
      // const baseTime = this.timeList[this.timeList.length-1].forecastTime
      const baseTime = (this.timeList.length>0 ? this.timeList[this.timeList.length-1].forecastTime:"") || sessionStorage.getItem('baseTime')
      return moment(baseTime).format("YYYY-MM-DD HH:00");
    }
  },
  methods: {
    canClick(i, type, typ) {
      let inx;
      if(this.timeType.type>1){
        inx = i - 1
      }else {
        inx = i - 2
      }
      if (this.currentStartTime.split(' ')[0] === this.date.split(' ')[0]) {
        let hour = this.$options.filters.getTime(inx, type)
        if (hour < this.currentStartTime.split(' ')[1]) {
          if (typ === 'word') {
            return 'cursor: not-allowed; color: grey;'
          }
          return 'cursor: not-allowed;';
        }
      }else if(this.date.split(" ")[0] === this.lastTime.split(" ")[0]){
        let hour = this.$options.filters.getTime(inx, type)
        hour=Number(hour.split(":")[0])
        const lastHour=Number(this.lastTime.split(' ')[1].split(":")[0])
        if(hour>lastHour){
          return 'cursor: not-allowed; color: grey;'
        }
      }
    },
    timeClick(i) {
      this.isClick = true;
      if (i === this.timeType.timeLength) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.$emit("change", i);
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  height: 1px;
  background: #6b738b;
  box-sizing: border-box;
  display: flex;
  color: rgba($color: #fff, $alpha: 1);
  font-size: 12px;
  position: relative;

  &.satellite {
    background: #fff;
  }

  .slider {
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    color: rgba($color: #f00, $alpha: 0.5);
    font-size: 20px;
  }

  .target {
    position: absolute;
    top: -7px;
    padding: 4px;
    z-index: 2;
    transform: translateX(-65%);
    border-radius: 50%;
    background: $text-operate;

    .box {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $theme-color;
    }

    .datetime {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      background: #fff;
      color: $theme-color;
      width: 118px;
      line-height: 24px;
      border-radius: 4px;
      text-align: center;

      &::before {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-top: 3px solid #fff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }

    .last_tip {
      left: -210%;

      &::before {
        left: 76%;
      }
    }
  }

  .time_wrap {
    width: 100%;
    display: flex;

    .time_item {
      flex: 1;
      cursor: pointer;
      position: relative;

      &:last-child {
        cursor: pointer;
        width: 2px;
        flex: 0;

        .word {
          transform: translateX(-50%);
        }
      }

      &.animation {
        background: #bac3db;
      }

      .clickDiv {
        position: absolute;
        top: -10px;
        left: -4px;
        cursor: pointer;
        width: 10px;
        height: 35px;
        z-index: 9999;
      }

      .circle {
        width: 1px;
        height: 7px;
        background-color: #6b738b;
        position: absolute;
        top: -6px;
        cursor: pointer;

        &.satellite,
        &.animation {
          background: #fff;
        }
      }

      .word {
        position: absolute;
        top: 4px;
        transform: translateX(-48%);
        text-align: center;
      }
    }
  }
}
</style>

<style>
.current_a {
  background-color: #ffffff;
}
</style>
