var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"system_title",class:{
    mobile_title: _vm.isMobile,
    position: _vm.position,
    drag: _vm.drag,
    center: _vm.center,
  }},[_c('img',{staticClass:"logo",staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/images/logo.png"),"alt":""},on:{"click":function($event){return _vm.$emit('change')}}}),_vm._v(" "),_c('div',{staticClass:"title_box",class:{
      border: _vm.border,
    }},[_c('span',[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]),_vm._v(" "),(!_vm.isMobile && _vm.time)?_c('v-time',{attrs:{"time":_vm.currentData.timestamp,"utc":_vm.utc}}):_vm._e(),_vm._v(" "),_vm._t("search")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }