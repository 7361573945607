<template>
  <div
    class="tj_search"
    :style="{ top: isMobile ? '50px' : '' }"
  >
    <div class="search_wrap">
      <div class="search_box">
        <el-input
          v-model.trim="input"
          class="input-with-select "
          placeholder="搜索位置"
          clearable
          @focus="focusHandle"
          @change="search"
          @clear="cleanIcon"
        >
          <svg-icon
            slot="prefix"
            class="icon-search"
            icon-class="search"
          />
        </el-input>
      </div>
      <div v-show="showResult">
        <ul
          v-if="resultData && resultData.length"
          class="result"
        >
          <li
            v-for="item in resultData"
            :key="item.id"
            @click="setLocate(item)"
          >
            <h3>
              <i
                class="el-icon-location-outline icon-locate"
                style="margin-right: 4px;"
              />{{ item.keyWord }}
            </h3>
            <p>经度：{{ item.lon ?? (item.lon).toFixed(2) }} / 纬度：{{ item.lat ?? (item.lat).toFixed(2) }}</p>
          </li>
        </ul>
        <ul
          v-else
          class="result"
        >
          <li>
            <p style="line-height: 30px;">
              暂无数据
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getLocation } from '@/api';
import { lonLatReg } from '@/utils/reg';

export default {
  name: 'VSearch',
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      isFocus: false,
      loading: false,
      showResult: false,
      showNoData: false,
      resultData: [],
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['region']),
  },
  methods: {
    focusHandle() {
      this.isFocus = true;
      this.showResult = !!(this.resultData && this.resultData.length);
    },
    setLocate(item) {
      this.$emit('locate', item);
      this.showResult = false;
    },
    search(val) {
      val = val.replace(/\s+/g, '');
      if (!val) {
        // 如果为空，则清除以前的定位点
        this.$emit('cleanIcon');
        this.showResult = false;
        this.resultData = [];
        return;
      }
      if (lonLatReg.test(val)) {
        const [lon, lat] = val.replace(/,|，/g, ',').replace(/\s+/g, '').split(',');
        this.resultData = [
          {
            id: val,
            lat: Number(lat),
            lon: Number(lon),
            keyWord: val,
          },
        ];
        this.showResult = true;
        return;
      }
      this.loading = true;
      getLocation(val)
        .then((res) => {
          const { location } = res;
          const arr = [];
          if (location) {
            if (this.t3) {
              const { lat, lon } = location;
              // lon 经度的值比较
              // lat 纬度进行比较
              const {
                latMax, latMin, lonMax, lonMin,
              } = this.region;
              if (lat > latMin && lat < latMax && lon > lonMin && lon < lonMax) {
                arr.push(location);
              }
            } else {
              arr.push(location);
            }
          }
          this.resultData = arr;
          this.showResult = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => { this.loading = false; });
    },

    cleanIcon() {
      this.$emit('cleanIcon');
    }
  },
};
</script>

<style>

  .el-input__inner {
    color: #fff;
  }
</style>

<style lang="scss" scoped>
.tj_search {
  padding-left: 10px;

  .icon-search {
    color: #ffffff;
  }

  .search_wrap {
    border-radius: 6px;
    background-color: rgba(26, 26, 26, 0.55);
    backdrop-filter: blur(1px);
    color: #fff;
    position: relative;
    height: 38px;
    width: 185px;

    .result_width {
      width: 180%;
    }

    .result {
      width: 150%;
      position: absolute;
      top: 43px;
      padding: 0;
      margin: 0;
      background-color: rgba($text-operate, 1);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
      border-radius: 6px;

      &:hover {
        background-color: rgba($text-operate, 0.92);
      }

      li {
        list-style: none;
        border-bottom: 1px solid #ccc;
        padding: 5px 20px 5px 30px;
        color: $text-menu;

        .icon-locate {
          color: $text-menu;
        }

        &:last-child {
          border: none;
        }

        &.process {
          line-height: 40px;
          font-size: 14px;
        }

        h3 {
          font-size: 14px;
          color: $text-menu;
          line-height: 24px;
          margin: 0;
        }

        p {
          margin: 0;
          font-size: 12px;
          color: #666;
          line-height: 16px;
        }
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border: none;
      font-size: 12px;
      height: 38px;
      padding-left: 36px;
      color: #fff;
    }

    .el-input__inner::placeholder {
      color: rgba($color: #fff, $alpha: 0.7);
    }

    .el-input__prefix {
      display: flex;
      align-items: center;
      padding: 0 6px;
    }

    .el-input__suffix {
      .el-icon-circle-close:before {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

}
</style>
