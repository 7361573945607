<template>
  <div>
    <!-- 客户端下载 -->
    <el-dialog
      class="more-dialog"
      title="客户端下载"
      width="30%"
      :visible.sync="customDownloadVisible"
    >
      <div class="flex-row justify-between">
        <div class="flex-col div-center">
          <a href="https://www.tjweather.com/arxiv/client/win/tjweather_client.exe">
            <div class="flex-col div-padding">
              <svg-icon class="svg-class" icon-class="windows" />
            </div>
          </a>
          <div class="flex-col">Windows</div>
        </div>
        <div class="flex-col div-center">
          <a href="https://www.tjweather.com/arxiv/client/mac/tjweather_client.dmg">
            <div class="flex-col div-padding">
              <svg-icon class="svg-class" icon-class="mac" />
            </div>
          </a>
          <div class="flex-col">Mac</div>
        </div>
      </div>
    </el-dialog>

    <!-- APP下载 -->
    <el-dialog title="APP下载" width="30%" :visible.sync="appDownloadVisible">
      <div class="flex-row justify-between">
        <div class="flex-col div-center">
          <div class="flex-col qrcode">
            <img :src="require('../../assets/images/ios.png')" alt="" class="img">
          </div>
          <div class="flex-col">IOS</div>
          <a :href="iosUrl" class="a-link-class">下载到本地</a>
        </div>
        <div class="flex-col div-center">
          <div class="flex-col qrcode">
            <img :src="require('../../assets/images/android.png')" alt="" class="img">
          </div>
          <div class="flex-col">Android</div>
          <a :href="androidUrl" class="a-link-class">下载到本地</a>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'DownLoadSelect',
  data() {
    return {
      customDownloadVisible: false,
      appDownloadVisible: false,
      androidUrl: 'https://47.111.102.8/arxiv/app/android/tj_1.0.8_202208101.apk',
      iosUrl: 'https://testflight.apple.com/join/OJ1ZDouC',
    };
  },
  methods: {
    changeDvisible(visible) {
      this.customDownloadVisible = visible;
    },
    changeAppVisible(visible) {
      this.appDownloadVisible = visible;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-col {
  display: flex;
  flex-direction: column;
}

.more-dialog {
  ::v-deep {
    .el-dialog {
      display: block;
      height: auto;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-between {
  display: flex;
  justify-content: space-evenly;
}

.div-center {
  align-items: center;
}

.div-padding {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  background-color: rgba(2, 122, 255, 1);
  border-radius: 39px;
  cursor: pointer;
}

.svg-class {
  width: 30px;
  height: 30px;
  margin: 25px 0 0 23px;
}

.a-link-class {
  text-decoration: none;
  font-size: 12px;
  overflow-wrap: break-word;
  color: rgba(2, 122, 255, 1);
  margin-top: 5px;
}

.qrcode {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: rgba(212, 216, 222, 1);

  .img {
    margin: 4px 0 0 4px;
    width: 82px;
    height: 82px;
  }
}
</style>
