import Vue from 'vue';
import 'element-ui/lib/theme-chalk/base.css';
// collapse 展开折叠
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
import {
  DatePicker,
  Message,
  Select,
  Option,
  Button,
  Switch,
  Input,
  Collapse,
  CollapseItem,
  CheckboxGroup,
  Checkbox,
  Loading,
  Popover,
  Tooltip,
  MessageBox,
  Dialog, ButtonGroup,
} from 'element-ui';

const components = [
  DatePicker,
  Message,
  Select,
  Option,
  Button,
  Switch,
  Input,
  Collapse,
  CollapseItem,
  CollapseTransition,
  CheckboxGroup,
  Checkbox,
  Popover,
  Tooltip,
  Dialog,
  ButtonGroup,
];

components.forEach((item) => {
  Vue.component(item.name, item);
});
Vue.prototype.$ELEMENT = { zIndex: 100001 };
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$message = Message;
Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;
