<template>
  <div class="city_select">
    <el-switch
      v-model="value"
      active-color="#05f"
    />
    <span class="text">压缩</span>
  </div>
</template>

<script>

export default {
  props: {
    hd: {
      type: String,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.hd === 'normal';
      },
      set(value) {
        this.$emit('change', value ? 'normal' : 'none');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.city_select {
  height: 34px;
  display: flex;
  align-items: center;

  span {
    font-size: 12px;
    margin-left: 10px;
  }
}
</style>
