<template>
  <div style="height: 30px;width: 100%;margin-left: 20px;margin-top: 22px">
    <div class="timeline">
      <div
        class="target"
        :style="{
          left: `${index * (100 / 23) + 0.2}%`,
        }"
      >
        <div class="box" />

        <div
          v-if="date"
          class="datetime"
          :class="{ last_tip: isLast || index === 23 }"
        >
          {{ date }}
        </div>
      </div>
      <div class="time_wrap">
        <div
          v-for="i in 24"
          :key="i"
          class="time_item"
          :class="{
            active: i === index - 1,
            animation: i <= index,
          }"
        >
          <div
            class="clickDiv"
            @click="timeClick(i - 1)"
          />
          <div
            class="circle"
            :class="{
              animation: i <= index,
            }"
          />
          <div
            v-if="i % 2"
            class="word satellite"
            :class="{
              canClick: timeLinePointList.findIndex(v => parseInt(v) === (i - 1)) > -1,
            }"
          >
            {{ i - 1 | getTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters, mapMutations } from 'vuex';
import Bus from '@/bus/index';

export default {
  filters: {
    getTime(value) {
      return `${value < 10 ? `0${value}` : value}:00`;
    },
  },
  props: {
    color: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isClick: false,
      isLast: false,
      index: 0,
      showTip: false,
      date: '',
    };
  },
  computed: {
    ...mapState('typhoon', ['timeLineData', 'currentDateTime',
      'currentTyphoonId', 'typhoonLayerData', 'typhoonObj']),
    ...mapGetters('typhoon', ['timeLineList', 'timeLinePointHourList']),
    ...mapGetters('typhoon', ['currentPoint']),
    timeLine: {
      get() {
        // console.log('this.timeLineList==>',this.timeLineList)
        return this.timeLineList;
      },
    },
    timeLinePointList: {
      get() {
        console.log('this.timeLinePointHourList==>',this.timeLinePointHourList)
        return this.timeLinePointHourList;
      },
    },
    timeLineLength: {
      get() {
        return this.timeLineList ? this.timeLineList.length : 0;
      },
    },
  },
  created() {
    Bus.$on('tiggerTimeLineClick', this.tiggerTimeLineClick);
    Bus.$on('tiggerLinePointClick', this.tiggerLinePointClick);
    Bus.$on('initTimeLine', this.init);
  },
  beforeDestroy() {
    Bus.$off('tiggerTimeLineClick', this.tiggerTimeLineClick);
    Bus.$off('tiggerLinePointClick', this.tiggerLinePointClick);
    Bus.$off('initTimeLine', this.init);
  },
  methods: {
    ...mapMutations('typhoon', ['setIndex', 'setTimeLineDate', 'setTimeLineIndex',
      'setTimeLineClickTime', 'setCurrentDateTime']),
    init() {
      const minTime = Math.min(...Object.values(this.timeLinePointList));
      this.timeClick(minTime);
    },
    preInit() {
      const maxTime = Math.max(...Object.values(this.timeLinePointList));
      this.timeClick(maxTime);
    },
    timeClick(i) {
      this.isClick = this.timeLinePointList.findIndex((v) => parseInt(v) === i) > -1;
      if (!this.isClick) {
        return;
      }
      if (i === 24) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.index = i;
      const clickTime = `${this.currentDateTime}${i < 10 ? `0${i}` : i}`;
      this.date = moment(clickTime, 'YYYYMMDDHH').format('YYYY-MM-DD HH时');
      console.log('clickTime==>',clickTime)
      this.setTimeLineClickTime(clickTime);
      Bus.$emit('timeLinePointClick', { clickTime, ispop: false });
    },
    // 播放按钮时间轴点击事件
    tiggerTimeLineClick(time) {
      // 根据时间获取索引
      if (time.length === 2) {
        this.timeClick(parseInt(time));
        return;
      }
      const hour = moment(time, 'YYYYMMDDHH').format('HH');
      this.timeClick(parseInt(hour));
    },
    tiggerLinePointClick(item) {
      const time = moment(item.time, 'YYYYMMDDHH').format('YYYYMMDD');
      const hour = moment(item.time, 'YYYYMMDDHH').format('HH');
      this.setCurrentDateTime(time);
      this.setTimeLineClickTime(item.time);
      const index = this.timeLinePointList.findIndex((v) => v === hour);
      if (index === -1) {
        return;
      }
      const i = parseInt(this.timeLinePointList[index]);
      if (i === 24) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.index = i;
      this.date = moment(item.time, 'YYYYMMDDHH').format('YYYY-MM-DD HH时');
      Bus.$emit('timeLinePointClick', { clickTime: item.time, ispop: true, key: item.key });
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  height: 1px;
  background: #6B738B;
  box-sizing: border-box;
  display: flex;
  color: rgba($color: #fff, $alpha: 1);
  font-size: 12px;
  position: relative;

  &.satellite {
    background: #fff;
  }

  .slider {
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    color: rgba($color: #f00, $alpha: 0.5);
    font-size: 20px;
  }

  .target {
    position: absolute;
    top: -7px;
    padding: 4px;
    z-index: 2;
    transform: translateX(-65%);
    border-radius: 50%;
    background: $text-operate;

    .box {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $theme-color;
    }

    .datetime {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      background: #fff;
      color: $theme-color;
      width: 118px;
      line-height: 24px;
      border-radius: 4px;
      text-align: center;

      &::before  {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-top: 3px solid #fff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }

    .last_tip {
      left: -210%;

      &::before {
        left: 76%;
      }
    }
  }

  .time_wrap {
    width: 100%;
    display: flex;

    .time_item {
      flex: 1;
      cursor: pointer;
      position: relative;

      &:last-child {
        cursor: pointer;
        width: 2px;
        flex: 0;

        .word {
          transform: translateX(-50%);
        }
      }

      &.animation {
        background: #BAC3DB;
      }

      .clickDiv {
        position: absolute;
        top: -10px;
        left: -4px;
        cursor: pointer;
        width: 10px;
        height: 35px;
        z-index: 9999;
      }

      .circle {
        width: 1px;
        height: 7px;
        background-color: #6B738B;
        position: absolute;
        top: -6px;
        cursor: pointer;

        &.satellite,
        &.animation {
          background: #fff;
        }

      }

      .word {
        position: absolute;
        top: 4px;
        transform: translateX(-48%);
        text-align: center;
        color: #333333;
      }

      .canClick {
        color: #FFFFFF;
      }
    }
  }
}
</style>

<style>
.current_a {
  background-color: #ffffff;
}
</style>
