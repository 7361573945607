<template>
  <div class="basetime_select">
    <div class="label">
      起报
    </div>
    <div class="select_wrap">
      <el-select
        v-model="value"
        placeholder="起报时间"
      >
        <el-option
          v-for="item in baseTime"
          :key="item"
          :value="item"
          :label="item | formatTime"
        />
      </el-select>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  filters: {
    formatTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  computed: {
    ...mapState('satellite', ['currentBaseTime']),
    ...mapGetters('satellite', ['baseTime']),
    value: {
      get() {
        console.log(this.currentBaseTime)
        return this.currentBaseTime;
      },
      set(value) {
        this.setCurrentBaseTime(value);
      },
    },
  },

  watch: {
    baseTime(value) {
      this.setCurrentBaseTime(value[0]);
    },
  },
  methods: {
    ...mapMutations('satellite', ['setCurrentBaseTime']),
  },
};
</script>

<style lang="scss" scoped>
.basetime_select {
  height: 30px;
  //padding: 0 5px 0 15px;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .label {
    font-size: 13px;
    font-family: -apple-system,system-ui,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',sans-serif;
    text-shadow: 0px 0px 4px black;
    margin-right: 5px;
    font-size: 12px;
    color: #fff;
    width: 30px;
    //margin-right: 10px;
    cursor: move;
  }

  .select_wrap {
    width: 164px;

    ::v-deep {
      .el-input__icon {
        line-height: 26px;
      }
      .el-input__inner {
        // color: #303030;
        // border-radius: 4px;
        // border: none;
        // background: #FFFFFF;
        // opacity: 0.77;
      }
    }
  }
}
</style>
