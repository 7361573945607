const sendPoint = (binding) => {
  let args;
  const field = ['category', 'action', 'opt_label', 'opt_value'];
  if (typeof binding === 'object') {
    const obj = binding;
    args = field.map((key) => obj[key]);
    args = args.filter((item) => item !== undefined);
  }
  window._hmt.push(['_trackEvent', ...args]);
  // if (process.env.NODE_ENV === 'production') {
  //   window._hmt.push(['_trackEvent', ...args]);
  // }
};

export { sendPoint };
