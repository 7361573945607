<template>
  <div class="isobar_select">
    <el-switch
      v-model="value"
      active-color="#05f"
    />
    <span class="text">等压线</span>
  </div>
</template>

<script>

export default {
  props: {
    isobar: {
      type: String,
      required: true,
    },
  },
  computed: {
    value: {
      get() {
        return this.isobar === 'normal';
      },
      set(value) {
        this.$emit('change', value ? 'normal' : 'none');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.isobar_select {
  height: 34px;
  display: flex;
  align-items: center;
  //width: 120px;

  span {
    font-size: 12px;
    margin-left: 10px;
  }
}
</style>
