var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"popover-wrap",class:{
      mobile: _vm.isMobile,
      satelite: _vm.satelite,
      satellite: _vm.is3D,
    }},[(_vm.modelType !== 'satellite')?_c('div',{staticClass:"info-wrap setting",class:{ earth: _vm.is3D, satellite: _vm.is3D },staticStyle:{"border-radius":"6px 6px 0 0"},style:(_vm.$route.name !== 'satellite' && _vm.$route.name !== 'earth'
          ? ''
          : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'),attrs:{"slot":"reference"},on:{"click":function($event){return _vm.menuClick(_vm.activeText)}},slot:"reference"},[_c('i',[_c('svg-icon',{attrs:{"icon-class":_vm.modelType === 'satellite' ? '2D' : _vm.activeIcon}})],1),_vm._v(" "),_vm._l((_vm.formatText(_vm.activeText)),function(text){return [_c('span',{key:text},[_vm._v(_vm._s(_vm.modelType === "satellite" ? "二维" : text))])]})],2):_vm._e(),_vm._v(" "),_c('el-popover',{attrs:{"placement":"left","width":"181","trigger":"click","popper-class":"layer-popover"}},[_c('div',{staticClass:"layer-box shadow"},_vm._l((_vm.map_type),function(item){return _c('div',{key:item.name,staticClass:"item",class:{
            active: _vm.getLayerType === item.name,
          },on:{"click":function($event){return _vm.mapLayerChange(item.name)}}},[_c('img',{attrs:{"src":require('../../assets/images/' + item.icon + '.png'),"alt":""}}),_vm._v(" "),_c('div',[_vm._v(_vm._s(item.text))])])}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modelType !== 'satellite' && _vm.modelType !== 'earth'),expression:"modelType !== 'satellite' && modelType !== 'earth'"}],staticClass:"info-wrap setting",class:{ earth: _vm.is3D },staticStyle:{"height":"55px"},style:(_vm.$route.name !== 'satellite' && _vm.$route.name !== 'earth'
            ? ''
            : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'),attrs:{"slot":"reference"},slot:"reference"},[_c('i',[_c('svg-icon',{attrs:{"icon-class":_vm.getLayerType === 'graph' ? 'control-shadow' : 'map'}})],1),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.activeMapText))])])]),_vm._v(" "),_c('el-popover',{attrs:{"placement":"left","width":"100","trigger":"click","popper-class":"layer-popover"}},[_c('div',{staticClass:"layer-box"},_vm._l((_vm.getLayerData),function(item){return _c('div',{key:item.text,staticClass:"item"},[_c('el-checkbox',{style:({
              width: _vm.isMobile ? '30%' : '25%',
            }),attrs:{"label":item.text},on:{"change":(e) => _vm.layerChange(e, item.name)},model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v("\n            "+_vm._s(item.text)+"\n          ")])],1)}),0),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.modelType !== 'satellite' && _vm.modelType !== 'earth'),expression:"modelType !== 'satellite' && modelType !== 'earth'"}],staticClass:"info-wrap setting",class:{ earth: _vm.is3D },staticStyle:{"border-radius":"0 0 6px 6px","height":"55px"},style:(_vm.$route.name !== 'satellite' && _vm.$route.name !== 'earth'
            ? ''
            : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'),attrs:{"slot":"reference"},slot:"reference"},[_c('i',[_c('svg-icon',{attrs:{"icon-class":"layer1"}})],1),_vm._v(" "),_c('span',[_vm._v("图层")])])]),_vm._v(" "),_c('div',{staticClass:"info-wrap model",class:{ earth: _vm.is3D },style:(_vm.$route.name !== 'satellite' && _vm.$route.name !== 'earth'
          ? ''
          : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'),on:{"click":function($event){return _vm.feedBack()}}},[_c('i',[_c('svg-icon',{attrs:{"icon-class":"consult"}})],1),_vm._v(" "),_c('span',[_vm._v("反馈")])])],1),_vm._v(" "),(_vm.showCard)?_c('feed-card',{on:{"close":function($event){_vm.showCard = false}}}):_vm._e(),_vm._v(" "),_c('v-down-load-select',{ref:"downselect"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }