<template>
  <div>
    <van-action-sheet v-model:show="popupShow" title="起报时间" class="qb-popup" :zIndex="99999">
      <van-tree-select
        :items="yearMonth"
        :active-id.sync="activeId"
        :main-active-index.sync="activeIndex"
        @click-item="clickItem"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from "moment";
import Bus from '@/bus/index';

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeId: 0,
      activeIndex: 0,
    }
  },
  computed: {
    ...mapState(['isMobile' , 'currentData', "t1Mode","factor"]),
    ...mapState({ t1CurrentBaseTime: 'currentBaseTime', t1BaseTime: 'baseTime' }),
    ...mapGetters("map", ["currentTime", "timeList"]),
    ...mapState('map', ['currentBaseTime']),
    ...mapGetters('map', ['t2Mode', 'baseTime']),
    ...mapGetters(["factorDataTimeMap"]),
    ...mapState('satellite', { sateCurrentBaseTime: 'currentBaseTime' }),
    ...mapGetters('satellite', { sateBaseTime: 'baseTime' }),
    popupShow: {
      get() {
        return this.show
      },
      set(val) {
        this.$emit('update:show', val)
      }
    },
    isT1() {
      return localStorage.getItem('router-url') === 'weather';
    },
    isSatellite() {
      return this.$route.name === 'satellite'
    },
    yearMonth() {
      if (this.isT1) {
        const flag = this.factor==="precp";
        let arr = []
        this.t1BaseTime.filter(item=>{
          arr.push(item.baseTime.substring(0,7))
        })
        const arr1 = Array.from(new Set(arr))
        const yearMonthList = []
        arr1.map(item => {
          yearMonthList.push({
            text: item,
            children: []
          })
        })
        yearMonthList.map(item=>{
          this.t1BaseTime.map((ite,inde) => {
            if (ite.baseTime.substring(0,7) === item.text) {
              item.children.push({
                text: flag?moment(ite.baseTime).format('YYYY/MM/DD HH:mm'):moment(ite.baseTime).format('YYYY/MM/DD HH时'),
                id: inde,
                val: ite.baseTimeModel
              })
            }
          })
        })
        yearMonthList.filter(item => item.text = `${item.text.substring(0,4)}年${item.text.substring(5,7)}月`)
        return yearMonthList
      } else {
        const list = this.isSatellite ? this.sateBaseTime : this.baseTime
        let arr = []
        list.filter(item=>{
          arr.push(item.substring(0,7))
        })
        const arr1 = Array.from(new Set(arr))
        const yearMonthList = []
        arr1.map(item => {
          yearMonthList.push({
            text: item,
            children: []
          })
        })
        yearMonthList.map(item=>{
          list.map((ite,inde) => {
            if (ite.substring(0,7) === item.text) {
              item.children.push({
                text: moment(ite.split(' ')[0]).format('YYYY/MM/DD HH时'),
                id: inde,
              })
            }
          })
        })
        yearMonthList.filter(item => item.text = `${item.text.substring(0,4)}年${item.text.substring(5,7)}月`)
        return yearMonthList
      }
    },
    deafultDate() {
      if (this.isT1) {
        const flag = this.factor==="precp";
        return flag?moment(this.t1CurrentBaseTime.baseTime).format('YYYY/MM/DD HH:mm'):moment(this.t1CurrentBaseTime.baseTime).format('YYYY/MM/DD HH时');
      } else {
        const time = this.isSatellite ? this.sateCurrentBaseTime : this.currentBaseTime
        return moment(time.split(' ')[0]).format("YYYY/MM/DD HH时");
      }
    },
    baseTimeMap() {
      return this.t1BaseTime.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },
  },
  watch: {
    t1CurrentBaseTime(val) {
      if (val) this.getDeafultBaseTime()
    },
    sateCurrentBaseTime(val) {
      if (val) this.getDeafultBaseTime()
    },
    currentBaseTime(val) {
      if (val) this.getDeafultBaseTime()
    }
  },
  mounted() {
    this.getDeafultBaseTime()
  },
  methods: {
    ...mapMutations("map", ["setMode"]),
    ...mapMutations('map', ['setCurrentBaseTime']),
    ...mapMutations({t1setCurrentBaseTime: 'setCurrentBaseTime'}),
    ...mapMutations('satellite', {setSateCurrentBaseTime: 'setCurrentBaseTime'}),
    clickItem(val) {
      if (this.isT1) {
        const time = this.baseTimeMap[val.val];
        this.t1setCurrentBaseTime(time);
        Bus.$emit('getFactorcast', time, true);
        this.$emit('changeQbSelectName', val.text);
      } else {
        const time = this.isSatellite ? this.sateBaseTime : this.baseTime
        time.filter(item => {
          if (moment(item.split(' ')[0]).format('YYYY/MM/DD HH时') === val.text) {
            this.isSatellite ? this.setSateCurrentBaseTime(item) : this.setCurrentBaseTime(item);
          }
        })
        this.$emit('changeQbSelectName', val.text);
      }
      this.popupShow = false;
      Bus.$emit("closeDetailShow");
    },
    getDeafultBaseTime() {
      if (this.isT1) {
          this.yearMonth.map((item, index) => {
            if (moment(this.t1CurrentBaseTime.baseTime).format('YYYY年MM月') === item.text) {
              this.activeIndex = index
              item.children.map((ite) => {
                if (moment(this.t1CurrentBaseTime.baseTime).format('YYYY/MM/DD HH时') === ite.text) {
                  this.activeId = ite.id
                }
              })
            }
          })
        } else {
          const time = this.isSatellite ? this.sateCurrentBaseTime : this.currentBaseTime
          this.yearMonth.map((item, index) => {
            if (moment(time.split(' ')[0]).format('YYYY年MM月') === item.text) {
              this.activeIndex = index
              item.children.map((ite) => {
                if (moment(time.split(' ')[0]).format('YYYY/MM/DD HH时') === ite.text) {
                  this.activeId = ite.id
                }
              })
            }
          })
        }
      this.$emit('changeQbSelectName', this.deafultDate)
    }
  }
};
</script>

<style lang="scss" scoped>
.qb-popup {
  background: $bg-popup;
  height: 90%;
  color: #fff;
  .mode-top {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    .title {
      flex: 1;
      text-align: center;
      font-size: 16px;
    }
  }
  .title {
    margin: 0 10px 10px;
    font-size: 12px;
  }
}
::v-deep {
  .van-tree-select {
    height: 100% !important;
    font-size: 14px;

    .van-sidebar-item {
      background-color: rgb(85, 85, 85);
      color: #FFFFFF;
    }

    .van-sidebar-item--select {
      background-color: rgba(66, 66, 66, 0.75);
    }

    .van-sidebar {
      background-color: rgb(85, 85, 85);
    }

    .van-tree-select__content {
      background-color: rgba(66, 66, 66, 0.75);
      color: #FFFFFF;
    }

    .van-tree-select__item--active {
      color: #1989fa;
    }

    .van-sidebar-item--select::before {
      background-color: #1989fa;
    }
  }
}
</style>
