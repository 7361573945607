import axios from 'axios';

export const httpCity = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

export const getJsonData = (url) => httpCity({
  url,
});
